.doves-members {
  width: 264px;
  justify-self: center;

  @screen sm {
    justify-self: unset;
  }
  img {
    height: 350px;
  }
}

.doves-members-grid {
  .middle-one {
    @screen lg {
      justify-self: center;
    }
  }
  .last-one {
    @screen lg {
      justify-self: end;
    }
  }
}

.speaker-circle {
  width: 264px;
  height: 264px;
  object-fit: cover;
}
