.jdcrops-review-hero {
  background-image: url(../../assets/images/jdcorps/jdcorps-review-2020.png);
  background-repeat: no-repeat;
  background-position: 105% 105%;
  background-size: contain;

  @screen lg {
    background-size: auto;
  }
}

.jdcorps-review-content {
  blockquote {
    @apply py-6 text-lg font-bold text-primary italic;
    @apply border-l-0 pl-0;
    @apply border-b border-primary relative;

    @screen lg {
      @apply text-xl;
    }
  }

  blockquote::before {
    content: '';
    background-image: url(../../assets/images/jdcorps/blockquotes.svg);
    background-repeat: no-repeat;
    position: absolute;
    top: -1.5rem;
    width: 100%;
    height: 100%;
  }
}
