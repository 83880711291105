.pa-hero {


  .assembly-logo {
    max-height: 70px;

    @screen lg {
      max-height: 105px;
    }
  }

  img {
    object-position: 30% 30%;

    @screen sm {
      object-position: center;
    }
  }

  .assembly-header-cta {
    width: 16.5rem;
  }
}

.assembly-register-box {
  @apply absolute flex flex-col justify-center items-start rounded;
  @apply px-6 py-6;
  width: 90%;
  right: 1rem;
  top: -8rem;
  background: rgb(49,93,156);
  background: linear-gradient(142deg, rgba(49,93,156,1) 50%, rgba(40,82,142,1) 50%);
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.15);

  @media screen and (min-width: 400px) {
    width: 80%;
  }
  @media screen and (min-width: 500px) {
    width: 60%;
    right: 2rem;
  }

  @screen sm {
    width: 50%;
    top:-4rem;
    @apply px-8 py-10;
    right: 2rem;
  }

  @screen md {
    width: 40%;
  }

  @screen lg {
    width: 30%;
    top: -13rem;
    @apply px-8 py-12;
  }
}
