.jewish-languages {
  .image-rotate {
    transform: rotate(-5.26deg);
  }

  .navigation-buttons {
    background-color: #EAEAE7;
    @apply rounded-sm;
    @apply px-10;
    @apply py-3;
  }

  .navigation-title {
    color: #295BA5;
    @apply font-600;
    @apply font-suisse-intl;
    @apply text-xs;

    @screen lg {
      @apply text-lg
    }
  }

  .video-size {
    overflow: hidden;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    width: 100%;

    iframe,
    video {
      border: none;
      overflow: hidden;
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }

  .hero-image {
    @apply w-48;

    @screen md {
      width: 333px;
      height: 459px;
    }

    @screen lg {
      width: 433px;
      height: 604px;
    }
  }

  .entry-title.-reverse-entry {
    @screen md {
      @apply justify-end;
    }
  }

  .grid-entry {
    .entry-video-odd {
      @apply hidden;
    }

    .entry-summary {
      @apply col-span-12;

      @screen md {
        @apply col-span-6 col-start-7;
      }
    }
  }

  .grid-entry.-reverse-entry {
    .entry-video-even {
      @screen md {
        @apply hidden;
      }
    }

    .entry-video-odd {
      @apply hidden;

      @screen md {
        @apply block;
      }
    }

    .entry-summary {
      @apply col-span-12;

      @screen md {
        @apply col-span-6 col-start-1;
      }
    }
  }
}