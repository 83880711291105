.hero-scroll-down {
  @apply text-secondary text-xs font-medium;

  display: block;
  width: 50px;
  margin: 30px auto 0;
  text-align: center;
  text-transform: uppercase;

  @screen md {
    position: absolute;
    bottom: 30px;
    left: 50%;
    margin-top: 0;
    margin-left: -25px;
  }

  @screen lg {
    bottom: 30px;
  }

  &::before {
    content: 'Scroll Down';
    display: block;
    margin-bottom: 1rem;
  }

  &::after {
    content: '';
    display: block;
    height: 18px;
    animation: bounce 3s ease infinite normal;
    background-image: url('../images/icons/scrolldown.svg');
    background-repeat: no-repeat;
    background-position: center;
  }
}

// inner pages
.inner-hero {
  @apply bg-primary text-white;

  display: flex;
  position: relative;
}

.inner-hero-container {
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  min-height: 340px;
  padding-top: 88px;
  padding-bottom: 32px;
  text-align: center;

  @screen sm {
    min-height: 400px;
    padding-top: 104px;
  }

  @screen md {
    @apply py-28;
  }

  @screen xl {
    @apply py-32;
  }

  &.-long {
    @screen sm {
      min-height: 700px;
    }
  }
}

.inner-hero-img {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 700px;
  opacity: 0.4;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.remove-hero-background-color {
  background-color: unset;

  .inner-hero-img {
    opacity: 0.8;
  }
}

.hero-logo-wrapper {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  justify-content: center;
}

.hero-logo {
  max-width: 200px;
  padding: 1rem 0.5rem;
  transform: translateY(50%);
  border: 1px solid theme('colors.primary');
  border-radius: 2px;
  background-color: theme('colors.white');
}

.hero-tabs {
  @apply px-gutter-1/2;

  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  @screen md {
    @apply px-gutter;
  }

  ul {
    display: flex;
    justify-content: space-between;
    overflow-x: auto;
  }

  li {
    flex: 1;
  }

  a {
    @apply text-white text-base;

    display: inline-block;
    width: 100%;
    padding: 5px 1rem;
    transition: border-color $trns, opacity $trns;
    border-bottom: 5px solid transparent;
    opacity: 0.8;
    text-transform: uppercase;
    white-space: nowrap;

    @screen md {
      padding: 5px 0.5rem;
      border-bottom-width: 10px;
    }

    @screen lg {
      @apply text-lg tracking-wide;
      padding: 5px 1rem;
    }

    &:hover {
      border-color: theme('colors.white');
      opacity: 1;
    }

    &.active {
      @apply font-bold;

      border-color: theme('colors.white');
      opacity: 1;
    }
  }
}

.charidy-hero {
  min-height: 300px;

  @screen md {
    min-height: 260px;
  }
  @screen lg {
    min-height: 360px;
  }
}

.charidy-header {
  @screen md {
    min-height: 140px;
  }

  @screen lg {
    min-height: 150px;
  }
}
.special-video-class {
  .embedded-video {
    video {
      max-width: 460px;

      @screen lg {
        display: flex;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        height: auto;
      }
    }
  }

  .embedded-video {
    padding-bottom: 69%;
  }
  .special-video-container {
    display: flex;
    justify-content: center;
  }

}


.social-share-items-dark  {
  @apply mt-2 flex gap-4 p-1.5 pl-4 items-center mx-auto;
  @apply text-white font-primary font-semi-bold uppercase text-sm text-opacity-70;
  background: rgba(255, 255, 255, 0.10) ;
  border-radius: 64px;
  width: fit-content;
}

.event-full-background-image {
  height: 50vh;
}