.offices-section-wrapper {
  @apply bg-primary text-white;

  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  padding-top: 3rem;
  padding-bottom: 3rem;
  overflow: hidden;
  background-image: url("../images/worldLow.svg");
  background-repeat: no-repeat;
  background-position: center;
  background-size: auto 55%;

  @screen sm {
    background-position: center 100px;
    background-size: auto 85%;
  }

  @screen md {
    padding-top: 3.75rem;
    padding-bottom: 4rem;
  }

  @screen lg {
    background-image: none;
    padding-bottom: 2.5rem;
  }

  .container-half.-right {
    @screen lg {
      @apply pl-gutter;
    }

    @screen xl {
      padding-left: 2.5rem;
    }
  }

  .btn {
    width: 100%;

    @screen sm {
      width: auto;
    }
  }
}

.offices-section {
  @apply px-gutter-1/2;

  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  max-width: theme("screens.sm");
  margin: 0 auto;

  @screen md {
    @apply px-gutter;
    max-width: theme("screens.md");
  }

  @screen lg {
    padding: 0;
    background-image: none;
  }
}

.offices-map {
  @screen lg {
    position: absolute;
    top: -100px;
    left: 50%;
    width: 1009px; // svg map width
    height: 651px; // svg map height
    margin-left: -430px;
    background-image: url("../images/worldLow.svg");
    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;
  }
}

.office-mark {
  @apply bg-white text-primary text-sm font-bold leading-none;

  display: none;
  position: absolute;
  width: 12px;
  height: 12px;
  transition: background-color $trns;
  border-radius: 50%;
  cursor: pointer;

  @screen lg {
    display: block;
  }

  &:hover {
    @apply bg-secondary;
  }

  &:focus {
    outline: none;
  }

  &.active {
    @apply bg-secondary;

    &::before,
    &::after {
      z-index: 999;
      opacity: 1;
    }
  }

  &::before,
  &::after {
    position: absolute;
    transition: opacity $trns;
    opacity: 0;
    pointer-events: none;
  }

  &::before {
    @apply rounded-4 bg-white;

    content: attr(data-city);
    display: flex;
    top: -36px;
    left: 50%;
    align-items: center;
    height: 28px;
    padding: 0 1rem;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  &::after {
    content: "";
    top: -8px;
    left: 50%;
    width: 0;
    height: 0;
    margin-left: -5px;
    border-top: 5px solid theme("colors.white");
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
}

.offices-follow-links {
  display: flex;

  a {
    transition: opacity $trns;
    opacity: 1;

    &:hover {
      @apply opacity-icon;
    }

    + a {
      margin-left: 1rem;
    }
  }
}
