.contact-form-wrapper {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding-top: 3rem;
  padding-bottom: 3rem;
  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;

  &.-dark-theme {
    @apply bg-blue;
    background-image: url("../images/contact-bg-dark.svg");

    .form-label {
      @apply text-white;
    }

    .custom-control-label-checkbox {
      @apply text-white;

      &::before {
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E%3Cpath d='M16 0a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h14zm0 2H2v14h14V2z'/%3E%3C/svg%3E");
      }

      &::after {
        background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E%3Cpath d='M16 0a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h14zM7 14l9-9-1.41-1.42L7 11.17 3.41 7.59 2 9l5 5z'/%3E%3C/svg%3E");
      }
    }
  }

  &.-light-theme {
    @apply bg-gray-300;
    background-image: url("../images/contact-bg-light.svg");
  }

  @screen md {
    padding-top: 3.5rem;
    padding-bottom: 4rem;
  }

  .container-half.-left {
    @screen lg {
      @apply pr-gutter;
    }
  }

  .form {
    @screen lg {
      margin: initial;
    }
  }

  .btn[type="submit"] {
    margin-top: 2rem;

    @screen sm {
      margin-top: 0;
    }
  }
}
