





































































































.stream-countdown {
  @apply py-8;

  @screen md {
    @apply py-0;
    padding-bottom: 56.25%;
    position: relative;
    height: 0;
    width: 100%;

    & > div {
      left: 0;
      top: 0;
      height: 100%;
      width: 100%;
      position: absolute;
    }
  }
}

.stream-countdown-tick,
.stream-countdown-separator {
  @apply text-white text-center;
  @apply opacity-70;
  font-size: 2.5rem;

  @screen sm {
    font-size: 4.5rem;
  }

  @screen md {
    font-size: 6.5rem;
  }

  @screen lg {
    font-size: 7.5rem;
  }
}
.stream-countdown-unit {
  @apply text-base uppercase;
  @apply opacity-50;
  @apply hidden;

  @screen sm {
    @apply block;
  }

  @screen md {
    @apply text-xl;
  }
}
