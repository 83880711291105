.wjc-plenary-assembly-candidate-hero {
  &.inner-hero {
    align-items: center;
    padding-top: 8rem;
  }

  min-height: 450px;

  @screen xl {
    max-height: 450px;
  }
  .assembly-logo {
    width: 160px;

    @screen sm {
      width: 220px;
    }

    @screen lg {
      width: 264px;
    }
  }

  &.-bio {
    min-height: unset;
    max-height: unset;
    height: auto;

    .inner-hero-container {
      @apply pb-4;
    }
  }
}

.wjc-plenary-assembly-candidate-topics {
  background: #00162a;
  ul {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    button {
      @apply w-full;
      @apply text-base text-left text-white;
      @apply pl-6 py-3;
      @apply flex justify-between items-center outline-none;

      svg {
        width: 10%;
        @apply pr-3;
      }

      &:hover {
        @apply underline;
      }
    }
  }

  li {
    @apply border-l-4 border-transparent outline-none;

    svg {
      @apply opacity-0;
    }
  }

  li.active {
    @apply border-l-4 border-white outline-none;

    svg {
      @apply opacity-100;
    }

    button {
      @apply text-white font-bold no-underline;
    }
  }
}

.wjc-plenary-assembly-candidate-aside-title {
  @apply text-white absolute w-full -top-16;
  background: #00162a;
}

.wjc-plenary-assembly-candidate-link {
  @apply p-gutter-1/2;

  display: block;
  transform: translateY(0);
  transition: transform $trns, box-shadow $trns;

  &:hover {
    @apply shadow-lg-hover bg-white;
    transform: translateY(-1rem);

    p {
      @apply text-primary;
    }

    img {
      filter: grayscale(0);
    }
  }
  img {
    transition: filter $trns;
    filter: grayscale(1);
    margin-bottom: 0.5rem;
  }
}

.wjc-plenary-assembly-candidate-bio-logo {
  svg {
    width: 170px;
  }
}

.wjc-plenary-assembly-candidate-bio {
  @screen sm {
    min-height: 15rem;
  }
}

.has-state-fix {
  margin: 0 !important;
  padding: 0 !important;
}
