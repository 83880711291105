.webtalks-page {
  h2,
  h3,
  p,
  .upcoming--body {
    color: #333;
  }

  .inner-hero {
    min-height: 400px;
  }
}
