.uae-reflecting {
  .uae-story {
    img {
      @apply w-full;
    }

    @screen md {
      @apply grid grid-cols-2 gap-8;

      &.-reverse {
        .uae-story-image {
          @apply col-start-2 row-start-1;
        }

        .uae-story-description {
          @apply col-start-1 row-start-1;
        }
      }
    }

    & + .uae-story {
      @apply mt-16;
    }
  }

  .pretty-content {
    @apply text-base leading-relaxed text-gray-dark;

    @screen lg {
      @apply text-lg;
    }
  }
}
