.embedded-video {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  width: 100%;

  iframe,
  video {
    border: none;
    overflow: hidden;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
