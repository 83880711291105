.affiliate-box {
  @apply w-full p-gutter-1/2;

  @screen sm {
    @apply w-1/2;
  }

  @screen lg {
    @apply w-1/3;
  }

  .affiliate-box-content {
    @apply flex flex-wrap px-gutter-1/2 bg-white shadow-lg text-primary;

    height: 100%;
    padding-bottom: 2rem;
    transition: all $trns;

    @screen md {
      @apply px-gutter;
      padding-bottom: 3rem;
    }

    &:hover {
      @apply shadow-lg-hover;
      transform: translateY(-20px);
    }

    h4 {
      @apply my-8 text-lg font-bold uppercase;

      width: 100%;
      text-align: center;

      @screen md {
        @apply text-xl;
      }
    }

    .affiliate-logo {
      @apply flex items-center justify-center border-b border-primary;

      width: 100%;
      height: 121px;
      padding: 1.5rem 0;

      @screen sm {
        height: 161px;
      }

      @screen md {
        height: 221px;
      }

      img {
        max-height: 100%;
      }
    }
  }
}

.affiliate-org-box {
  @apply flex flex-col gap-y-4 items-center cursor-pointer;
  transition: all $trns;
  &:hover {
    transform: translateY(-10px);
    .affiliate-org-img {
      filter: drop-shadow(0px 2px 12px rgba(0, 0, 0, 0.16));
    }
    .affiliate-org-name {
      color: #295ba5 !important;
    }
  }
}

.affiliate-org-img {
  @apply bg-white p-8;
  width: 75%;
  @screen sm {
    width: 100%;
    padding: 16px;
  }
}

.affiliate-org-name {
  @apply text-sm text-black font-suisse-intl font-medium text-center;
  width: 75%;
  @screen sm {
    width: 100%;
  }
}

.community-section {
  a h5,
  a h3 {
    @apply font-bold no-underline;
    color: #08284f;

    &:hover {
      text-decoration: underline;
    }
  }
}
.affiliate-logo-dimensions {
  @apply rounded;

  width: 360px;
  height: 300px;
  border: 1px solid #d3d3db;

  @screen sm {
    width: 260px;
    height: 200px;
  }
}

.leader-logo {
  @apply rounded;

  border: 1px solid #d3d3db;
  width: 135px;
  height: 177px;
}

.big-margin {
  border: 30px solid #f8f8fa;
  background: #f8f8fa;
}

.communities-video {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  width: 100%;

  iframe,
  video {
    border: 16px solid #021934;
    overflow: hidden;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}
