$w-sm: 263px;
$w-sm-active: 273px;
$h-sm: 444px;
$h-sm-active: 469px;
$pos-x-sm: 5px;
$pos-y-sm: 12px;

$w-md: 344px;
$w-md-active: 354px;
$h-md: 543px;
$h-md-active: 591px;
$pos-x-md: 5px;
$pos-y-md: 24px;

$w-lg: 297px;
$w-lg-active: 307px;
$h-lg: 524px;
$h-lg-active: 572px;
$pos-x-lg: 5px;
$pos-y-lg: 24px;

$w-xl: 360px;
$w-xl-active: 380px;
$h-xl: 610px;
$h-xl-active: 700px;
$pos-x-xl: 10px;
$pos-y-xl: 45px;
$pos-y-hover-xl: 24px;

.events-section {
  position: relative;
  z-index: 2;

  @screen sm {
    background-image: url('../images/events-bg.svg');
    background-repeat: no-repeat;
    background-position: center 105px;
    background-size: 100% auto;
  }

  @screen md {
    background-position: center 70px;
  }

  @screen lg {
    background-position: center 85px;
  }

  @screen xl {
    background-position: center 100px;
  }
}

.events-wrapper {
  .swiper-nav-btn {
    @screen lg {
      margin-top: -1px;
    }

    @screen xxl {
      &:focus {
        @apply shadow-btn-primary;
      }

      svg {
        @apply fill-primary;
      }
    }
  }

  .swiper-container {
    @screen sm {
      height: $h-sm-active;
      padding-top: $pos-y-sm;
    }

    @screen md {
      height: $h-md-active;
      padding-top: $pos-y-md;
    }

    @screen lg {
      height: $h-lg-active;
      padding-top: $pos-y-lg;
    }

    @screen xl {
      height: $h-xl-active + $pos-y-hover-xl;
      padding-top: $pos-y-xl + $pos-y-hover-xl;
    }
  }
}

.events-slide {
  box-sizing: border-box;

  .events-box {
    @apply rounded bg-primary text-white;

    display: flex;
    position: relative;
    flex-direction: column;
    padding-bottom: 2rem;
    transition: transform $trns, box-shadow $trns;
    text-align: center;
    transition: all 0.5s linear;

    &:hover {
      @screen xl {
        transform: translateY(-$pos-y-hover-xl);
      }
    }

    img {
      @apply rounded-t;
      width: 100%;
    }

    .content {
      @apply rounded-b bg-primary;

      display: flex;
      position: relative;
      flex: 1;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      z-index: 1;
      width: 100%;
      margin-top: 0px;

      &::before {
        content: '';
        position: absolute;
        top: -130px;
        right: 0;
        left: 0;
        height: 160px;
        background: linear-gradient(
          180deg,
          theme('colors.primary-a0') 0%,
          theme('colors.primary') 100px,
          theme('colors.primary') 100%
        );
      }

      @screen sm {
        position: absolute;
        bottom: 30px;
        left: 0;
        transition: bottom 0.5s linear;
      }

      @screen xl {
        bottom: 44px;
      }
    }

    @screen sm {
      width: $w-sm;
      height: $h-sm;
    }

    @screen md {
      width: $w-md;
      height: $h-md;
    }

    @screen lg {
      width: $w-lg;
      height: $h-lg;
    }

    @screen xl {
      width: $w-xl;
      height: $h-xl;
    }

    h4 {
      @apply text-lg font-bold;

      position: relative;
      z-index: 1;
      margin-bottom: auto;
      padding: 0 1rem;

      @screen sm {
        width: $w-sm;
        margin: 0 auto 0.5rem;
      }

      @screen md {
        width: $w-md;
      }

      @screen lg {
        width: $w-lg;
      }

      @screen xl {
        @apply text-xl;
        padding: 0 2rem;
        width: $w-xl;
      }
    }

    .date {
      @apply text-xs font-medium;

      display: block;
      position: relative;
      z-index: 1;
      margin-top: 0.5rem;
      margin-bottom: 2.25rem;
      opacity: 0.8;

      @screen lg {
        @apply text-sm;
      }

      @screen xl {
        margin-top: 1rem;
      }
    }
  }

  &.swiper-slide-active {
    .events-box {
      @screen sm {
        width: $w-sm-active;
        height: $h-sm-active;
        transform: translate(-$pos-x-sm, -$pos-y-sm);
      }

      @screen md {
        width: $w-md-active;
        height: $h-md-active;
        transform: translate(-$pos-x-md, -$pos-y-md);
      }

      @screen lg {
        width: $w-lg-active;
        height: $h-lg-active;
        transform: translate(-$pos-x-lg, -$pos-y-lg);
      }

      @screen xl {
        width: $w-xl-active;
        height: $h-xl-active;
        transform: translate(-$pos-x-xl, -$pos-y-xl);
      }

      &:hover {
        @screen xl {
          transform: translate(-$pos-x-xl, -$pos-y-xl + (-$pos-y-hover-xl));
        }
      }

      .content {
        @screen sm {
          bottom: 43px;
        }

        @screen md {
          bottom: 54px;
        }

        @screen xl {
          bottom: 89px;
        }
      }
    }
  }
}

.event-material-document {
  &:hover {
    .btn-link::after {
      right: 100%;
    }
  }
}
