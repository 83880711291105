.more-ways {
  .inner-hero-img {
    position: absolute;
    bottom: 0;
    opacity: 1;
  }

  .inner-hero-container {
    min-height: 280px;
    padding-bottom: 0;
  }

  .more-ways-subtitle {
    @apply text-white text-lg text-left tracking-wide;
    @apply font-semi-medium mt-4;
  }

  .more-ways-title {
    @apply text-white text-opacity-80 text-xl;
    @apply  text-left mb-4;;

    @screen sm {
      @apply text-2xl;
    }

    @screen lg {
      @apply text-3xl;
    }
  }
}

.more-ways-item {
  position: relative;
  top: 0;
  transition: top ease 0.2s;

  &:hover {
    top: -1rem;
  }
}
