.podcast-text {
  color: #85858A;
}

.podcast-time {
  color: #919194;

}

.podcast-show-sm-img {
  min-width: 80px;
  min-height: 80px;
}

.episode-list-item {
  background-color: #F8F8FA;

  img {
    max-width: 118px;
    max-height: 118px;
  }
}

.see-all-btn {
  background-color: #F8F8FA;
  color: #295BA5;
}


.podcasts-list {
  h1 {
    font-size: 40px;
  }
}