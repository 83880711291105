.language-menu {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 32px;

  &.open {
    .dropdown-trigger {
      @apply text-secondary;

      &::after {
        border-top-color: theme("colors.secondary");
      }
    }
  }

  .dropdown-trigger {
    @apply text-white text-sm font-medium leading-none;

    display: block;
    height: 100%;
    padding-right: 1.125rem;
    transition: color $trns;
    text-transform: uppercase;

    &:hover,
    &:focus {
      @apply text-secondary;
      outline: none;

      &::after {
        border-top-color: theme("colors.secondary");
      }
    }
  }
}
