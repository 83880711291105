.halle-body {
  @apply text-lg leading-loose;

  li {
    @apply mb-6 pl-8;
    @apply relative;

    &::before {
      content: '';
      @apply bg-blue;
      @apply w-2 h-2 inline-block;
      @apply rounded-full;
      @apply absolute left-0;
      @apply mt-3;
    }
  }
}

.halle-video {
  @apply relative;
  @apply inline-block overflow-hidden;

  svg {
    @apply absolute;
    width: 4rem;
    height: 4rem;
    top: 50%;
    left: 50%;
    margin-left: -2rem;
    margin-top: -2rem;
    z-index: 10;
  }

  img {
    transition: transform 200ms;
  }

  &:hover {
    img {
      transform: scale(1.1);
    }

    svg {
      transform: scale(1);
    }
  }
}

.halle-rich-text {
  p {
    @apply mb-4;
  }
}

.halle-separator {
  border-top: 1px solid #CCD3DC;

  @screen md {
    width: 80%;
    margin: 0 auto;
  }
}

.halle-stream {
  @apply relative;
  top: -2rem;

  @screen md {
    top: -8rem;
  }
}