.featured-campaign {
  @apply bg-dark;

  display: flex;
  position: relative;
  align-items: center;
  justify-content: center;
  height: 100%;
  min-height: 260px;
  text-align: center;

  @screen sm {
    min-height: 410px;
  }

  @screen md {
    min-height: 535px;
  }

  @screen lg {
    min-height: 340px;
  }

  @screen xl {
    min-height: 420px;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: .5;
    object-fit: cover;
  }

  .content {
    position: relative;
    z-index: 1;
    padding: 1rem;

    @screen sm {
      padding: 2rem;
    }
  }

  .title {
    @apply text-white;

    margin-bottom: 1rem;
    text-transform: uppercase;

    @screen sm {
      margin-bottom: 3.25rem;
    }

    a:hover {
      text-decoration: underline;
    }
  }
}

.campaign-box {
  @apply bg-white shadow-md;

  border-radius: .125rem;

  @screen md {
    display: flex;
    flex-direction: column;
    height: 100%;
  }

  img {
    border-top-left-radius: 2px;
    border-top-right-radius: 2px;
  }

  .content {
    padding: 1.5rem 1rem;

    @screen sm {
      padding: 2rem 1.5rem;
    }

    @screen md {
      display: flex;
      flex: 1;
      flex-direction: column;
    }
  }

  .title {
    @apply font-bold;

    @screen md {
      margin-bottom: auto;
    }
  }

  .btn {
    margin-top: 1rem;
    margin-bottom: 1rem;

    @screen md {
      margin-top: 2rem;
    }
  }

  svg {
    fill: theme("colors.gray-dark");
  }
}

.campaigns-menu-link {
  @apply text-primary text-base;

  display: inline-block;
  position: relative;
  margin-bottom: -1px;
  padding: .5rem 1.5rem;
  transition: opacity $trns;
  opacity: .7;
  text-transform: uppercase;

  &:hover {
    opacity: 1;

    &::after {
      @apply bg-blue;
    }
  }

  &.active {
    @apply font-bold;
    opacity: 1;

    &::after {
      @apply bg-blue;
    }
  }

  &::after {
    @apply bg-transparent;

    content: "";
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 3px;
    transition: background-color $trns;
  }
}

.campaign-hero-img::before {
  padding-top: 75%;
}
