.women_in_contemporary_judaism {
  .stream-section {
    @apply relative;
    top: -2rem;

    @screen md {
      top: -4rem;
    }

    @screen lg {
      top: -7rem;
    }
  }

  .stream-section-content {
    @apply mx-auto mt-12 mb-6;
  }

  .pretty-content {
    @apply text-lg text-gray-dark leading-relaxed;

    figure {
      @extend .embedded-video;
    }
  }

  .subscribe-box {
    @apply p-1 pl-6 flex flex-col;

    @screen sm {
      @apply bg-white flex-row overflow-hidden rounded-full;
    }

    input {
      @apply bg-white rounded-full;
      @apply text-black text-base outline-none flex-grow;
      @apply px-6 py-3 mb-3;

      @screen sm {
        @apply rounded-none;
        @apply p-0;
        @apply m-0;
      }
    }
  }

  .speakers {
    .swiper-wrapper {
      @apply items-stretch;
    }
    .swiper-slide {
      @apply h-auto;
    }
    .swiper-nav-btn {
      top: 30%;
    }
  }

  .speaker-bio-details {
    @apply bg-primary-dark;
    @apply pt-16 pb-24 mb-8;
    @apply text-white;
    @apply font-medium;
  }
}
