@media screen and (min-width: 400px) {
  .calendar-banner {
    background-image: url('../../assets/images/years-banner.svg');
    background-repeat: no-repeat;
    background-position: right;
  }
}

.row-gray {
  background-color: #f0f0f0;
}

.dark-cyan-blue {
  color: #2d3748;
}
