





























































































































































































































































































































@import 'driver.js/dist/driver.min.css';
@import '../styles/hex/variables';

.driver-fix-stacking {
  z-index: 1 !important;
}
div#driver-highlighted-element-stage {
  box-shadow: 0 0 0 2px #ffffff;
  background-color: transparent !important;
  border-radius: 4px;
}
div#driver-page-overlay {
  opacity: 0.35 !important;
}

div#driver-popover-item {
  min-width: 400px;
  max-width: 400px;
  background-color: rgba(255, 255, 255, 0.85);
  font-family: $font-family;
  border-radius: 4px;

  .driver-popover-tip {
    &.bottom {
      border-color: rgba(255, 255, 255, 0.85) transparent transparent;
    }
    &.top {
      border-color: transparent transparent rgba(255, 255, 255, 0.85);
    }
    &.left {
      border-color: transparent rgba(255, 255, 255, 0.85) transparent
        transparent;
    }
    &.right {
      border-color: transparent transparent transparent
        rgba(255, 255, 255, 0.85);
    }
  }

  .driver-popover-title {
    display: none;
  }

  .driver-popover-description {
    #statics-editor {
      textarea {
        display: block;
        padding: 8px;
        background: white;
        border-radius: 2px;
        color: #505d68;
        width: 100%;
        min-height: auto;
        height: 160px;
        border: none;
        box-shadow: 0 0 3px rgba($color: $gray-500, $alpha: 0.6);
        font-family: $font-family;
        font-size: 14px;
        line-height: 1.5;
        transition: box-shadow 200ms;
        margin: 0 0 16px 0;
        resize: none;

        &:focus {
          outline: none;
          box-shadow: 0 0 0 2px rgba($color: $gray-500, $alpha: 0.4);
        }

        &[disabled] {
          background: $gray-100;
          cursor: not-allowed;
        }
      }
    }
  }

  .statics-editor--actions {
    display: flex;
    text-align: center;

    button {
      font-size: 14px;
      font-weight: normal;
      font-family: $font-family;
      letter-spacing: normal;
      text-transform: none;
      height: unset;
      width: unset;
      line-height: 1;
      margin: 0;
    }

    .btn-save {
      color: $white;
      background: $primary;
      border-radius: 4px;
      padding: 10px 40px;
      border: none;
      opacity: 0.8;
      transition: opacity 200ms;

      &:hover {
        opacity: 1;
      }

      &:focus {
        opacity: 1;
        box-shadow: 0 0 0 2px rgba($color: $primary, $alpha: 0.4);
      }

      &[disabled] {
        background: $gray-500;
        opacity: 1;
        cursor: not-allowed;
      }
    }

    .btn-close {
      background: none;
      border: none;
      color: $gray-500;
      padding: 10px 5px;
      margin-left: 10px;
      border-radius: 4px;

      &:hover {
        text-decoration: underline;
      }

      &:focus {
        box-shadow: 0 0 0 2px rgba($color: $gray-500, $alpha: 0.4);
      }

      &[disabled] {
        opacity: 0.6;
        cursor: not-allowed;

        &:hover {
          text-decoration: none;
        }
      }
    }
  }
}

.static-string.highlight-active {
  cursor: pointer;
  transition: outline, background 200ms;
  outline: 1px dashed $yellow !important;

  &:not(.driver-highlighted-element):hover {
    background-color: rgba($color: $yellow, $alpha: 0.2);
  }

  &.driver-highlighted-element {
    outline: 1px solid transparent !important;
  }
}
