@import 'variables';

@tailwind base;
@tailwind components;

// Libs
@import 'swiper/css/swiper.css';

// Libs override
@import 'libs/libs';

@import 'common/fonts';
@import 'common/type';
@import 'common/global';

@import 'components/accordion';
@import 'components/buttons';
@import 'components/deep-link';
@import 'components/documents';
@import 'components/dropdown';
@import 'components/embedded_videos';
@import 'components/forms';
@import 'components/related-news';
@import 'components/tabs';
@import 'components/cookie-notice';
@import 'components/newsletter';
@import 'components/triangle';
@import 'components/list';
@import 'components/nextgen_swiper';

/*! purgecss start ignore */
@import 'editor/blocks';
/*! purgecss end ignore */

@import 'layouts/header';
@import 'layouts/footer';
@import 'layouts/hero';
@import 'layouts/general';
@import 'layouts/home';
@import 'layouts/article';

@import 'layouts/about';
@import 'layouts/communities';
@import 'layouts/events';
@import 'layouts/news';
@import 'layouts/videos';
@import 'layouts/issues';
@import 'layouts/campaigns';
@import 'layouts/facts';
@import 'layouts/publications';

@tailwind utilities;

@import 'covid19';
@import 'jdcorps';
@import 'fellowship';
@import 'theodor-herzel-award';
@import 'podcast_shows';
@import 'static-pages/index';
@import 'static-pages/jews_from_arab_lands';
@import 'static-pages/women_in_contemporary_judaism';
@import 'static-pages/ihra';
@import 'static-pages/new_ihra_page';
@import 'static-pages/wjc_nominations';
@import 'static-pages/pledge_against_antisemitism';
@import 'static-pages/unhrc';
@import 'static-pages/plenary_assembly';
@import 'static-pages/secca';
@import 'static-pages/global-review';
@import 'static-pages/table-facts';
@import 'static-pages/durban';
@import 'static-pages/unoct';
@import 'static-pages/jewish_holidays';
@import 'static-pages/jdcorps_year_review';
@import 'static-pages/youth_assembly';
@import 'static-pages/ab80';
@import 'static-pages/anniversary';
@import 'static-pages/more_ways_to_give';
@import 'static-pages/uae';
@import 'static-pages/shared_styles';
@import 'static-pages/islamic_revolutionary.scss';
@import 'static-pages/islamic-revolutionary-page-map.scss';
@import 'static-pages/ihra_misconceptions';
@import 'static-pages/other_ways_to_donate';
@import 'static-pages/israel_101';
@import 'static-pages/diplomacy_summit';
@import 'static-pages/hezbollah_101';
@import 'static-pages/irgc_101';
@import 'static-pages/jewish_youth_assembly';
@import 'static-pages/combat_antisemitism';
@import 'static-pages/next-gen-impact';
@import 'static-pages/doves_conference';
@import 'static-pages/hamas_101';
@import 'static-pages/mena';
@import 'static-pages/podcasts';
@import 'static-pages/kristallnacht';
@import 'static-pages/conspiracy_myths';
@import 'static-pages/jewish_and_proud';
@import 'static-pages/holocaust_memorial';
@import 'static-pages/israel_more_than_a_country';
@import 'static-pages/ihra_campaign';
@import 'static-pages/caoc';
@import 'static-pages/elevate';
@import 'static-pages/75th_anniversary_Israel';
@import 'static-pages/jewish_languages';
@import 'static-pages/leadership_bridge';
@import 'static-pages/bosnia_delegation';
@import 'static-pages/lawfare_page';
@import 'static-pages/we_remember_journeys';
@import 'static-pages/next_gen_inc';
@import 'static-pages/fighting_hate';
@import 'static-pages/ec_zagreb';
@import 'static-pages/stands_with_israel';
@import 'static-pages/ec_jerusalem';
@import 'static-pages/hezbollah_guide';
@import 'static-pages/elevate_representatives';
@import 'static-pages/hezbollah_new_page';
@import 'static-pages/life_on_campus_during_war';
@import 'static-pages/life_on_campus_during_war_new';
@import 'static-pages/hamas_101_new_page';
@import 'static-pages/diplomacy_2024';
@import 'static-pages/elevate_honors';
@import 'static-pages/plenary_assembly_candidate';

.jdcorps-site {
  @import 'jdcorps/header';
}
