.hezbollah-page {
  .hazbollah-top-part-section {
    @apply relative pt-40 pb-10 overflow-hidden;
    @apply bg-cover bg-center;
  }

  .hezbollah-page-form {
    @apply bg-white rounded p-6;

    .forms-form {
      background: unset;
      padding: 0;
    }

    .btn-primary {
      background-color: black;
      position: absolute;
      right: 53px;
      bottom: 42px;
    }

    .paragraph-block {
      @apply text-black leading-loose;
    }

    input[type='submit'] {
      @apply px-10;
    }

    input[type='checkbox'] {
      @apply mr-2;
      accent-color: black;

      &:hover {
        accent-color: black;
      }
    }
  }

  .hezbollah-page-header-title-typography {
    @apply text-white;

    font-weight: 800;
    font-size: 56px;
    letter-spacing: -1px;
    line-height: 76px;
  }

  .hezbollah-page-table-content {
    table {
      font-family: arial, sans-serif;
      border-collapse: collapse;
      width: 100%;
      border: 1px solid #dddddd;
    }

    td,
    th {
      text-align: left;
      padding: 8px;
    }

    tr {
      border-bottom: 1px solid #dddddd;
      border-left: none;
    }

    tr:nth-child(7) {
      background-color: #f5e8ea;
    }
  }

  .hezbollah-page-table-first-row {
    background-color: #f9fafb;
    font-color: #667085;
  }

  .hezbollah-line-height {
    .header-block {
      line-height: 62px;
    }
  }

  .sticky-sidebar-links {
    @apply sticky;

    height: min-content;
    top: 8rem;
  }

  .hezbollah-page-spacing {
    margin-bottom: 0px;

    @screen lg {
      margin-bottom: 108px;
    }
  }

  .hezbollah-card {
    @apply h-64 p-3 bg-cover bg-center w-full;
    @apply flex items-center justify-center text-center;

    .hezbollah-card-link {
      @apply text-white uppercase;
      @apply flex flex-col;
    }
  }

  .hezbollah-military-map {

    @screen lg {
      background-image: url("../images/mapGreen.svg");
      background-repeat: round;
    }
  }

  .hezbollah-page-center-map-footer-button {
    @apply relative mb-8;

    @screen lg {
      @apply absolute;
      top: 61%;
    }
  }

  .hezbollah-map-title {
    @apply font-medium text-3xl mb-6;

    @screen lg {
      @apply text-4xxl w-1/2 flex mx-auto;
    }
  }

  .irgc-page-map {
    padding-top: 10rem;
  }

  .hezbollah-content {
    ol,
    ul {
      margin-bottom: 1em;
      padding-left: 2.5em;
    }

    ol {
      list-style: decimal;
    }

    ul {
      list-style: disc;
    }

    p {
      margin-bottom: 1em;
    }

    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      @apply text-primary;
      margin-bottom: 0.5em;
    }

    a {
      @apply text-primary font-bold;

      text-decoration: underline;
    }

    img {
      @apply w-full;
    }
  }

  .sidebar-link-container {
    @apply h-64 p-3 bg-cover bg-center;
    @apply flex items-center justify-center;

    .sidebar-link {
      @apply text-white font-bold uppercase;
      @apply flex flex-col items-center;

      line-height: 50px;

      &:hover {
        background-color: initial;
      }
    }
  }

  .link-small-text {
    @apply font-bold;

    font-size: 20px;
  }

  .link-large-text {
    @apply text-2xl ;

    font-weight: 800;

    @screen lg {
      @apply text-3xl;
    }
  }

  .table-facts-aside-nav {
    li {
      &.active {
        border-color:#999900;
      }
    }
  }

  .items-top-bottom {
    p {
      @apply text-base;
      line-height: 4px;
      @apply pretty-content;
      @apply mb-4;
      @screen lg {
        @apply text-lg;
      }
    }

    h1, h2 {
      @apply text-xl;
      @screen lg {
        @apply text-3xl;
      }
      @apply font-bold;
      @apply mt-2;
      @apply pb-4;
    }
  }
}