.about-content-tabs {
  display: flex;
  overflow-x: auto;

  a {
    @apply text-primary text-sm;

    display: inline-block;
    padding: 0 0.5rem;
    transition: border-color $trns, opacity $trns;
    border-bottom: 3px solid transparent;
    line-height: 3;
    text-transform: uppercase;
    white-space: nowrap;
    cursor: pointer;

    @screen xl {
      @apply text-base;
      padding: 0 1.25rem;
    }

    &:hover {
      border-color: theme('colors.primary');
    }

    &.active {
      @apply font-bold;
      border-color: theme('colors.primary');
    }
  }
}

.leadership-list {
  @apply -mx-gutter-1/2;

  display: flex;
  flex-wrap: wrap;

  > div {
    width: 50%;
    margin-bottom: 2.25rem;

    @screen sm {
      width: 33.333333333333333%;
    }

    @screen md {
      width: 25%;
    }

    @screen lg {
      width: 20%;
    }
  }
}

.leadership-link {
  @apply p-gutter-1/2 text-gray-dark text-sm;

  display: block;
  transform: translateY(0);
  transition: transform $trns, box-shadow $trns;

  &:hover {
    @screen lg {
      @apply shadow-lg-hover;
      transform: translateY(-1rem);

      img {
        filter: grayscale(0);
      }
    }
  }

  @screen sm {
    @apply text-base;
  }

  @screen lg {
    @apply text-lg;
  }

  img {
    margin-bottom: 1.25rem;
    transition: filter $trns;

    @screen lg {
      filter: grayscale(1);
    }
  }
}

.about-bio-photo {
  @media screen and (max-width: 365px) {
    @apply w-full;
  }
}
