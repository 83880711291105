.description-styles {
  font-weight: 400;
  font-family: 'Suisse Works';
}

.hero-text-width {
  @screen md {
    width: 558px;
  }
}

.section-title {
  @apply text-xl;

  @screen sm {
    @apply text-2xl;
  }

  @screen lg {
    font-size: 40px;
    line-height: 52px;
  }
}

.video-size {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
  width: 100%;

  iframe,
  video {
    border: none;
    overflow: hidden;
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    position: absolute;
  }
}

.hero-video-size {
  @apply w-full;

  iframe,
  video {
    @apply w-full;

    min-height: 360px;
  }
}

// Map styles

.israel-label {
  @apply bg-white text-primary p-2 font-600 text-base;
  transition: background-color $trns;
}

.israel-section-wrap {
  @apply absolute flex flex-row items-center;

  svg {
    line {
      transition: stroke $trns;
    }
    circle {
      transition: fill $trns;
    }
  }

  &:hover {
    svg {
      line {
        stroke: #fee500;
      }
      circle {
        fill: #fee500;
      }
    }

    .israel-label {
      @apply bg-yellow;
    }
  }
}

// North styles

.north {
  position: relative;

  bottom: 30rem;
  left: -2.5rem;

  @screen md {
    bottom: 38.5rem;
    left: unset;
  }

  @screen lg {
    bottom: 38rem;
    left: -1.8rem;
  }
}

// Tel Aviv styles

.tel-aviv {
  position: relative;
  bottom: 24rem;
  left: -1rem;
  @screen sm {
    bottom: 24rem;
    left: -2.3rem;
  }
  @screen md {
    bottom: 31rem;
    left: -0.8rem;
  }
}

// Jerusalem styles

.jerusalem {
  position: relative;
  bottom: 20rem;
  left: 4.3rem;

  @screen md {
    bottom: 26.8rem;
    left: 6rem;
  }
}

// South styles
.south {
  position: relative;
  bottom: 10rem;
  left: 4.3rem;

  @screen md {
    bottom: 13rem;
    left: 6rem;
  }
}
