.irgc-page-form {
  @apply bg-white rounded;

  @screen md {
    max-width: 342px;
    height: 382px;
    @apply p-4;
  }

  width: auto;
  height: 382px;
  @apply p-4;

  .forms-form {
    padding: 0;
    margin: 0;
    @apply text-sm;
  }

  .form-control:not(textarea) {
    @apply h-10;
  }

  .form-label {
    @apply text-sm font-medium text-black;
  }

  .btn-primary {
    background-color: #58000b;
    position: relative;

    @screen md {
      left: 154px;
      bottom: 89px;
    }

    left: 130px;
    bottom: 89px;
  }

  .paragraph-block {
    @apply text-black text-base font-medium opacity-70 pb-6;
  }

  input[type='submit'] {
    @screen xl {
      width: 154px;
      height: 40px;
    }

    width: 130px;
    height: 38px;
  }

  input[type='checkbox'] {
    @apply mr-2;
    accent-color: #58000b;

    &:hover {
      accent-color: #58000b;
    }
  }
}

.irgc-page-center-map-footer-button {
  @apply relative mb-8;

  @screen lg {
    @apply absolute;
    top: 17%;
  }
}

.irgc-page-header-title-typography {
  font-weight: 800;
  font-size: 56px;
  letter-spacing: -1px;
  line-height: 76px;
}

.irgc-page-table-content {
  table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
    border: 1px solid #dddddd;
  }

  td,
  th {
    text-align: left;
    padding: 8px;
  }

  tr {
    border-bottom: 1px solid #dddddd;
    border-left: none;
  }

  tr:nth-child(7) {
    background-color: #f5e8ea;
  }
}

.irgc-page-table-first-row {
  background-color: #f9fafb;
  font-color: #667085;
}

.irgc-map-title {
  @apply font-medium text-3xl;

  margin-bottom: -50px;

  @screen lg {
    @apply text-4xxl w-1/2 mx-auto;

    margin-bottom: -75px;
  }
  margin-top: unset;
}

.irgc-line-height {
  .header-block {
    line-height: 62px;
  }
}

.sticky-sidebar-links {
  @apply sticky;

  height: min-content;
  top: 8rem;
}

.irgc-page-spacing {
  margin-bottom: 0px;

  @screen lg {
    margin-bottom: 108px;
  }
}

.irgc-military-map {
  @screen lg {
    background-image: url('../images/mapRed.svg');
    background-repeat: round;
  }
}

.irgc-content {
  ol,
  ul {
    margin-bottom: 1em;
    padding-left: 2.5em;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  p {
    margin-bottom: 1em;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-primary;
    margin-bottom: 0.5em;
  }

  a {
    @apply text-primary font-bold;

    text-decoration: underline;
  }

  img {
    @apply w-full;
  }
}

.irgc-back-btn {
  @apply text-base px-4 mb-8;

  text-transform: initial;
  height: 38px;

  .black-arrow {
    @apply hidden;
  }

  .white-arrow {
    @apply block;
  }

  &:hover {
    @apply text-black;

    .white-arrow {
      @apply hidden;
    }

    .black-arrow {
      @apply block;
    }
  }
}

.irgc-map-contents-style {
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
}
