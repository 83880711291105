.stands-with-israel-navigation {
  @apply relative;

  .mobile-dropdown-navigation-content {
    @apply hidden absolute z-10;
    background-color: #1C2B59;
    transform-origin: top center;
    animation: slideDown 300ms ease-in-out forwards;

    @keyframes slideDown {
      0% {
        opacity: 0;
        transform: translateY(-60px);
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .mobile-dropdown-navigation-content.show {
    @apply flex flex-col w-full;
  }
}
