.newsletter-section {
  @apply bg-dark;
  position: relative;
  text-align: center;

  @screen md {
    text-align: left;
  }

  .triangle-bg {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
}

.newsletter-form {
  text-align: center;

  @screen sm {
    @apply border-light rounded-7 bg-light;

    display: flex;
    max-width: 360px;
    height: 54px;
    margin: 0 auto;
    transition: border-color $trns, background-color $trns, box-shadow $trns;
    border-width: 3px;
  }

  @screen md {
    margin: 0;
  }

  @screen lg {
    max-width: 440px;
  }

  &:focus-within {
    @screen sm {
      @apply border-white bg-white shadow-input-white;
    }
  }

  .newsletter-email {
    @apply rounded-7 bg-light text-gray-dark text-base font-medium;

    width: 100%;
    min-width: 0;
    height: 48px;
    margin-bottom: 1rem;
    padding: 0 1.125rem;
    transition: background-color $trns, box-shadow $trns;
    outline: none;

    @screen sm {
      height: 100%;
      margin-bottom: 0;
      padding-right: .5rem;
      padding-left: 1.375rem;
    }

    @screen lg {
      padding-left: 1.75rem;
    }

    &:focus {
      @apply bg-white shadow-input-white;

      @screen sm {
        @apply shadow-none;
      }
    }

    &::placeholder {
      @apply text-primary-a5;
      opacity: 1;
    }
  }
}

.newsletter-sidebar {
  @apply pt-8 pb-14 px-6 rounded-sm bg-primary shadow-lg text-white text-center;

  background-image: url("../images/newsletter-sidebar-bg.svg");
  background-repeat: no-repeat;
  background-position: right bottom;

  .newsletter-form {
    display: block;
    width: 100%;
    height: auto;
    border: 0;
    border-radius: 0;
    background-color: transparent;

    &:focus-within {
      @screen sm {
        @apply border-none shadow-none;
      }
    }

    .newsletter-email {
      @screen sm {
        height: 54px;
        margin-bottom: 2.5rem;
        padding-right: 1.375rem;
      }

      @screen lg {
        padding-right: 1.75rem;
      }
    }
  }
}
