.custom-swiper-container {
  position: relative;
}

.swiper-slide {
  box-sizing: border-box;

  img {
    width: 100%;
  }
}

.swiper-nav-btn {
  width: 24px;
  height: 24px;
  margin-top: -12px;
  transition: box-shadow $trns, opacity $trns;
  border-radius: 50%;
  opacity: 1;
  background-image: none;

  &::after {
    content: none;
  }

  &:focus {
    @apply shadow-btn-secondary;
    outline: none;
  }

  &.swiper-button-disabled {
    pointer-events: auto;

    &:hover {
      opacity: 0.35;
    }

    &:focus {
      box-shadow: none;
    }
  }

  &.-primary-theme {
    &:focus {
      @apply shadow-btn-primary;
    }

    svg {
      @apply fill-primary;
    }
  }

  &.-big {
    width: 4rem;
    height: 4rem;
    margin-top: -2rem;
  }

  &.-fixed {
    top: 112px;

    @screen sm {
      top: 87px;
    }

    @screen md {
      top: 115px;
    }

    @screen lg {
      top: 97px;
    }

    @screen xl {
      top: 90px;
    }
  }
}

.swiper-button-prev {
  left: 12px;

  &.-edge {
    left: -2rem;
  }

  @screen xxl {
    left: -72px;
  }

  &.-white-arrows {
    svg {
      @apply fill-white;
    }
  }
}

.swiper-button-next {
  right: 12px;

  &.-edge {
    right: -2rem;
  }

  @screen xxl {
    right: -72px;
  }

  &.-white-arrows {
    svg {
      @apply fill-white;
    }
  }
}

.swiper-pagination {
  display: flex;
  justify-content: center;
}

.swiper-container-horizontal
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 5px;
}

.swiper-pagination-bullet {
  @apply rounded bg-gray-light;

  display: block;
  width: 32px;
  height: 2px;
  transition: background-color $trns;
  opacity: 1;

  &:hover {
    @apply bg-secondary;
  }

  &:focus {
    outline: none;
  }

  &.swiper-pagination-bullet-active {
    @apply bg-secondary;

    &:focus {
      outline: none;
    }
  }
}

//CSS for swiper with blue circle bullets - used for testimonials swiper

.js-testimonial-swiper-container
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 5px;
  @apply rounded bg-white border border-oval-gray;

  display: block;
  width: 8px;
  height: 8px;
  transition: background-color $trns;
  opacity: 1;

  &:hover {
    @apply bg-blue-dark;
  }

  &:focus {
    outline: none;
  }

  &.swiper-pagination-bullet-active {
    @apply bg-blue-dark border border-blue-dark;

    &:focus {
      outline: none;
    }
  }
}

.js-testimonial-durban-swiper-container
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 8px;
  @apply rounded-full bg-white border border-oval-gray;

  display: block;
  width: 12px;
  height: 12px;
  transition: background-color $trns;
  opacity: 1;

  &:hover {
    @apply bg-blue-dark;
  }

  &:focus {
    outline: none;
  }

  &.swiper-pagination-bullet-active {
    @apply bg-blue-dark border border-blue-dark;

    &:focus {
      outline: none;
    }
  }
}

//CSS for swiper with blue lines pagination - used for leadership bridge page

.js-testimonial-words-elevate-swiper-container {
  .swiper-pagination {
    justify-content: start;
    bottom: 0px;
  }
}

.js-testimonial-words-elevate-swiper-container
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  border-radius: 0px;
  background-color: #c8c8d6;

  display: block;
  width: 54px;
  height: 8px;
  transition: background-color $trns;
  opacity: 1;

  &:hover {
    background-color: #295ba5;
  }

  &:focus {
    outline: none;
  }

  &.swiper-pagination-bullet-active {
    // @apply bg-blue-dark border border-blue-dark;
    background-color: #295ba5;

    &:focus {
      outline: none;
    }
  }
}

// CSS for swiper with blue circle bullets - Used in the news at header-display section
.swiper-news-header-display-container
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 5px;
  @apply rounded-full bg-white border border-oval-gray;

  display: block;
  width: 16px;
  height: 16px;
  transition: background-color $trns;
  opacity: 1;

  &:hover {
    outline: #2b7de2 solid 4px;
  }

  &:focus {
    outline: none;
  }

  &.swiper-pagination-bullet-active {
    outline: #2b7de2 solid 4px;

    &:focus {
      outline: none;
    }
  }
}

.swiper-news-header-display-container {
  .swiper-pagination-bullets {
    bottom: 40px !important;

    &.large-bottom-placement {
      bottom: 150px !important;

      @screen md {
        bottom: 100px !important;
      }
    }
  }
}

.js-ec-zagreb-image-container
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 8px;
  @apply rounded-full bg-white border border-oval-gray;

  display: block;
  width: 12px;
  height: 12px;
  transition: background-color $trns;
  opacity: 1;

  &:hover {
    @apply bg-blue-dark;
  }

  &:focus {
    outline: none;
  }

  &.swiper-pagination-bullet-active {
    @apply bg-blue-dark border border-blue-dark;

    &:focus {
      outline: none;
    }
  }
}

.js-jdAcademy-swiper-container
  > .swiper-pagination-bullets
  .swiper-pagination-bullet {
  margin: 0 8px;
  @apply rounded-full bg-white border border-oval-gray;

  display: block;
  width: 12px;
  height: 12px;
  transition: background-color $trns;
  opacity: 1;

  &:hover {
    @apply bg-blue-dark;
  }

  &:focus {
    outline: none;
  }

  &.swiper-pagination-bullet-active {
    @apply bg-blue-dark border border-blue-dark;

    &:focus {
      outline: none;
    }
  }
}
