.publications-header {
  @apply py-8 bg-primary text-white;

  position: relative;

  @screen md {
    min-height: 360px;
    padding-top: 10rem;
    padding-bottom: 5rem;
  }
}


.publications-search-form {
  @apply pl-6;

  max-height: 72px;

  button {
    @apply h-full px-10 py-3 rounded-r-md; 

    min-height: 72px;

    span {
      @apply text-xl font-primary font-medium;
    }
  } 

  input::placeholder {
    @apply font-medium text-xl;
  }
}

.publications-grid { 
  .publictaions-col {
    @apply rounded-lg;
    
    min-height: 264px;
  }
}

.publications-dropdown-content {
  @apply hidden absolute z-10 bg-white;

  transform-origin: top center;
  animation: slideDown 300ms ease-in-out forwards;

  @keyframes slideDown {
    0% {
      opacity: 0;
      transform: translateY(-60px);
    }

    100% {
      opacity: 1;
      transform: translateY(0);
    }
  }
}

.publications-dropdown-content.show {
  @apply flex flex-col w-full p-4;
}

.dropdown-toggler {
  @apply cursor-pointer w-full flex items-center pr-4;
  @apply mt-2 border-gray-light border rounded-md text-sm; 

  min-height: 40px;
  max-height: 42px;


  &::after {
    right: 7px;
    border-top: 5px solid theme('colors.white-a8');

    @screen xl {
      right: 14px;
      border-top-color: theme('colors.primary-a5');
      opacity: 0;
    }
  }
}

.dropdown-search-container {
  @apply py-1 px-2 border border-gray-light rounded-sm;

  min-height: 24px;
}

.dropdown-item {
  @apply text-sm p-5 font-medium font-primary my-2;
  @apply text-primary opacity-80 rounded-md flex items-center;

  max-height: 32px;

  &:hover {
    @apply text-white opacity-100 cursor-pointer;

    background-color: #0F5DA9;
  }
}

.publications-searchable-dropdown {

  .btn-arrow {
    @apply ml-auto;

    &.rotated {
      transform: rotate(180deg);
    }
  }
}

.download-pdf {
  position: absolute;
  @apply top-0 left-0 w-full h-full;
  @apply opacity-0;
  @apply transition-opacity;
  @apply flex items-center justify-center;
  @apply bg-black bg-opacity-40;
  @apply text-white;

  svg {
    @apply transform scale-0;
    @apply transition-transform;
  }

  &:hover {
    @apply opacity-100;;
    svg {
      @apply scale-100;
    }
  }
}
