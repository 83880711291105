.ec-jerusalem-bg-color {
  background-color: #04223F;
}

.ec-jerusalem-bg-image  {
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 95%;

}

.ec-jerusalem-bg-star-image {
  background-repeat: no-repeat;
  background-position-x: 95%;
}

.ec-jerusalem-docs {
  background-color: #F2F5F8;
}

.download-pdf-docs {
  position: absolute;
  @apply top-0 left-0 w-full h-full;
  @apply opacity-0;
  @apply transition-opacity;
  @apply flex items-center justify-center;
  @apply bg-black bg-opacity-40;
  @apply text-white;

  svg {
    @apply transform scale-0;
    @apply transition-transform;
  }

  &:hover {
    @apply opacity-100;;
    svg {
      @apply scale-100;
    }
  }
}

.doc-description-text {
  @apply font-suisse-intl text-base font-semi-medium leading-6;

  color: rgba(8, 40, 79, 0.70);
}
