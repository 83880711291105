.navigation {
  @apply bg-primary;

  display: flex;
  position: fixed;
  top: 105px;
  right: 0;
  left: 100%;
  height: calc(100% - 131px);
  padding: 1rem;
  transition: left $trns;
  text-align: center;

  &.-jdcorps {
    top: 50px;
    height: calc(100% - 50px);
    padding: 1.5rem;
  }

  @screen sm {
    top: 115px;
    height: calc(100% - 96px);
    padding: 1.5rem;

    &.-jdcorps {
      top: 60px;
      height: calc(100% - 60px);
      padding: 2.5rem;
    }
  }

  @screen md {
    top: 120px;
    height: calc(100% - 120px);

    &.-jdcorps {
      padding: 1.5rem;
    }
  }

  @screen lg {
    top: 88px;
    height: calc(100% - 104px);
  }

  @screen xl {
    @apply bg-transparent;

    position: static;
    height: auto;
    padding: 0;
  }

  &.-jdcorps {
    @apply px-2;
  }
}

.menu {
  flex: 1;

  @screen xl {
    display: flex;
    align-items: center;
  }
}

.nav-item {
  padding: 0.5rem 0;

  @screen md {
    padding: 1rem;
  }

  @screen xl {
    padding: 0;
    padding-right: 6px;
    padding-left: 6px;
  }

  @screen xl {
    padding-right: 10px;
    padding-left: 10px;
  }
}

.nav-link {
  @apply text-white font-bold leading-loose;

  display: inline-block;
  text-transform: uppercase;
  white-space: nowrap;

  @screen xl {
    @apply text-xs;
    padding-top: 1px; // vertical center
  }

  @screen xl {
    font-size: 13px;
  }

  &:hover::after,
  &.active::after {
    right: 0;
  }

  &::after {
    right: 100%;
  }
}

//dropdown menu

.nav-item.-more {
  @apply relative rounded-t;
  @apply transition ease-in-out delay-100;
  @apply cursor-pointer;
  @apply justify-center flex flex-col items-center;

  @screen xl {
    @apply block;
  }

  .btn-link::after {
    @screen md {
      display: none;
    }
  }

  svg {
    @apply hidden;

    @screen md {
      @apply block;
      stroke: white;
      transform: rotate(180deg);
      transition: all 0.4s ease;
    }
  }

  &:hover {
    @screen xl {
      @apply bg-white;

      .nav-link {
        color: #02080f;
      }
    }

    svg {
      stroke: currentColor;
      transform: rotateZ(360deg);
    }

    + .nav-submenu,
    .nav-submenu {
      @screen md {
        @apply block;
      }
      @screen xl {
        @apply visible;
        opacity: 1;
      }
    }
  }
}

.nav-submenu {
  @apply hidden;
  transition: all ease-in-out 100ms;
  padding-top: 0rem;
  overflow: hidden;
  // position: absolute;
  top: 3rem;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  text-align: center;

  @screen md {
  }

  @screen xl {
    @apply block invisible;
    @apply absolute z-10 right-0 top-auto left-auto;
    @apply rounded-l rounded-br;
    @apply bg-white;
    box-shadow: 0px 4px 4px 2px rgba(0, 0, 0, 0.15);
    text-align: left;
  }
}

.nav-sub-link {
  @apply block;
  @apply uppercase text-white px-4 py-2;
  @apply transition ease-in-out delay-100;
  overflow: hidden;
  @apply text-sm;

  @screen xl {
    color: #02080f;
    @apply px-4 py-3 text-left;

    &:hover {
      background-color: #e7ecf0;
    }

    @screen xl {
      font-size: 13px;
    }
  }
}
