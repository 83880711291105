.fighting-hate {
  .embedded-video {
    padding-bottom: 59%;
  }

  .swiper-button-prev,
  .swiper-button-next {
    color: white;
    background: rgba(255, 255, 255, 0.1);
    &:hover {
      @apply text-yellow-FF0;
      background: rgba(255, 255, 255, 0.3);
    }
  }
}

.disabled_swiper_button {
  opacity: 0;
  cursor: auto;
  pointer-events: none;
}

.instagram-widget {
  .img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    min-width: initial !important;
    width: 264px !important;
    height: 365px !important;
  }
  max-width: 264px;
}
