.next-gen-swiper {
  .swiper-wrapper {
    max-height: 520px;
  }

  .swiper-slide {
    img {
      min-height: 260px;

      @screen lg {
        min-height: 520px;
      }
    }
  }
}

.next-gen-header {
  top: -150px;

  @screen sm {
    top: -200px;
  }

  @screen md {
    top: -250px;
  }

  @screen lg {
    top: -300px;
  }
}

.advisory-column {
  img {
    max-width: 264px;
    max-height: 350px;
  }
}

.advisory-column-staff {
  img {
    width: 206px;
    height: 275px;
  }

  @media screen and (max-width: 576px) {
    img {
      width: 264px;
      height: 300px;
    }
  }
}

.next-gen-pagination {
  .pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    @apply text-lg;

    .next-page {
      @apply ml-4;
    }

    .previous-page {
      @apply mr-4;
    }

    div {
      @apply flex ;

      li {
        @apply py-1 px-3 ml-1;
      }

      li.active,
      li.focus,
      li.hover {
        @apply text-primary;
      }
    }
  }
}
