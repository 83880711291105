.our-president-news-swiper-nav-btn {
  top: 0;
  margin-top: calc(33.332758620689655% - 12px);

  @screen sm {
    top: 87px; // 50% of the image
    margin-top: -12px;
  }

  @screen md {
    top: 115px;
  }

  @screen lg {
    top: 97px;
  }

  @screen xl {
    top: 90px;
  }

  @screen xxl {
    &:focus {
      @apply shadow-btn-primary;
    }

    svg {
      @apply fill-primary;
    }
  }
}

.our-president-news-box {
  @apply block;

  &:hover {
    .title {
      @apply underline;
    }
  }

  .date {
    @apply text-gray text-sm;
    @apply mt-4 block;
  }

  .title {
    @apply text-primary;
    @apply mt-2;
  }
}

.news-author-img {
  max-height: 68px;
  min-width: 68px;
  max-width: 68px;
}

.live-blog {
  .live-label {
    color: rgba(204, 0, 0, 1);
  }

  .timeline-point {
    left: -16px;
    top: 7px;

    @screen lg {
      left: -8px;
      top: 3px;
    }
  }

  .kaminari-public-pagination {
    .pagination {
      @apply flex items-center justify-center;

      .active {
        a {
          @apply text-primary;
        }
      }

      a {
        @apply px-2 text-blue;

        &:hover {
          @apply text-primary;
        }
      }
    }
  }
}
