.hezbollah-guide {
  .table-facts-aside-nav {
    li > a {
      @apply text-black text-opacity-70 font-semi-medium;

      &:hover {
        @apply text-black text-opacity-100 no-underline font-bold;
      }
    }

    li.active {
      @apply border-solid border-l-4;
      border-color: #999900;

      >a {
        @apply text-black text-opacity-100 font-bold;
      }
    }

    li {
      &:hover {
        @apply text-black text-opacity-100;
      }
    }
  }

  &.arrow-hover-active-state.table-facts {
    svg {
      @apply hidden pl-0 pr-0;
    }
  }
}

.hezbollah-guide-body {
  p {
    @apply text-base text-black font-semi-medium leading-8;

    @screen md {
      @apply text-lg;
    }
  }

  img {
    @apply my-8 w-full;
  }

  blockquote {
    @apply font-semi-medium leading-casual my-8 text-black text-xl not-italic pl-6 border-l-4 border-solid border-black;

    @screen md {
      @apply text-2xl;
    }
  }

  ol, ul {
    @apply my-8;

    li {
      @apply text-black font-semi-medium text-base leading-8;

      @screen md {
        @apply text-lg;
      }
    }
  }
}
