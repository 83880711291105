.wjc-nominations-hero {
  &.inner-hero {
    align-items: center;
  }

  min-height: 500px;

  @screen xl {
    max-height: 700px;
  }
  .assembly-logo {
    width: 160px;

    @screen sm {
      width: 220px;
    }

    @screen lg {
      width: 264px;
    }
  }

  &.-bio {
    min-height: unset;
    max-height: unset;
    height: auto;

    .inner-hero-container {
      @apply pb-10;
    }
  }
}

.wjc-nominations-topics {
  ul {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

    button {
      @apply w-full;
      @apply text-base text-left text-primary opacity-70 uppercase;
      @apply pl-6 py-3;
      @apply flex justify-between items-center outline-none;

      svg {
        width: 10%;
        @apply pr-3;
      }

      &:hover {
        @apply underline;
      }
    }
  }

  li {
    @apply border-l-4 border-transparent outline-none;

    svg {
      @apply opacity-0;
    }
  }

  li.active {
    @apply border-l-4 border-blue outline-none;

    svg {
      @apply opacity-100;
    }

    button {
      @apply text-primary text-opacity-100 font-bold no-underline;
    }
  }
}

.wjc-nominations-aside-title {
  @apply absolute w-full bg-white -top-16;
}

.wjc-nominations-link {
  @apply p-gutter-1/2;

  display: block;
  transform: translateY(0);
  transition: transform $trns, box-shadow $trns;

  &:hover {
    @apply shadow-lg-hover;
    transform: translateY(-1rem);

    img {
      filter: grayscale(0);
    }
  }
  img {
    transition: filter $trns;
    filter: grayscale(1);
    margin-bottom: 0.5rem;
  }
}

.nominations-bio-logo {
  svg {
    width: 170px;
  }
}

.wjc-nominations-bio {
  @screen sm {
    min-height: 15rem;
  }
}
