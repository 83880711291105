.hamas-article-body {
  figure {
    padding-bottom: 56.25%;
    position: relative;
  }

  iframe {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
  }
}


