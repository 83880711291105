@use "sass:math";

.jdcorps-resource {
  height: 0;
  overflow: hidden;
  padding-top: math.div(320px, 460px) * 100%;
  background: white;
  position: relative;

  a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;

    img:not(.play-button) {
      height: 100%;
      width: 100%;
      object-fit: contain;
    }
  }

  .play-button {
    position: absolute;
    top: 50%;
    left: 50%;
    margin-left: -32px;
    margin-top: -32px;
    cursor: pointer;
    opacity: 0.75;
  }

  &:hover {
    .play-button {
      opacity: 1;
    }
  }
}

.jdcorps-program-session {
  display: grid;

  @screen md {
    grid-template-columns: 7.5rem 1fr 14rem;
  }
}

.jdcorps-leadership-linked-item {
  transform: translateY(0);
  transition: transform $trns, box-shadow $trns;

  &:hover {
    @screen md {
      @apply shadow-lg-hover;
      transform: translateY(-1rem);
    }
  }
}

.statements-category-filter {
  li {
    @apply text-primary text-sm uppercase rounded-full border border-primary px-3 py-1;
    @apply transition-all ease-out;
    @apply cursor-pointer mr-4 mb-4;

    @screen md {
      @apply text-base;
    }

    &:hover {
      @apply bg-primary text-white;
    }

    &.active {
      @apply bg-primary text-white;
    }
  }
}

.jdcorps-diplomacy-cols {
  @apply flex flex-col;

  @screen lg {
    display: grid;
    grid-template-columns: 75% 25%;
  }
}

.jdcorps-diplomacy-search-form {
  width: 264px;

  @screen lg {
    width: auto;
  }

  .search-bottom {
    bottom: 12px;
  }

  .form-background {
    background-color: #E5E5E5;
  }

  input::placeholder {
    color: #08284F;
    opacity: 0.5;
  }
}