@import '../components/header-social-nav';
@import '../components/language-menu';
@import '../components/navigation';
@import '../components/search-form';

.header-height-space {
  margin-top: 90px;

  @screen sm {
    margin-top: 95px;
  }

  @screen md {
    margin-top: 120px;
  }

  @screen lg {
    margin-top: 100px;
  }

  @screen xl {
    margin-top: 120px;
  }
}

.header {
  @apply bg-primary shadow-lg;

  position: fixed;
  z-index: 50;
  top: 0;
  left: 0;
  width: 100%;
  transition: background $trns;

  @screen md {
    @apply bg-transparent shadow-none;
  }
}

.position-option {
  background-position: 55% 50px;

  @screen md {
    background-position: 70% 50px;
  }
}

.header-text-shadow {
  text-shadow: 1px 1px 8px #021934;
}

.topbar-newsletter-link {
  display: flex;
  align-items: center;
  margin-right: 1rem;
  transition: opacity $trns;
  opacity: 1;

  &:hover {
    @apply opacity-icon;
  }

  @screen sm {
    margin-right: 1.5rem;
  }
}

.support-our-work-link {
  @apply text-yellow;

  margin-right: 1rem;
  border-right: 1px solid theme('colors.white');
  padding: 0 1.5rem;
  transition: color $trns;

  &:hover {
    span {
      @apply text-white-hover;
    }
  }

  @screen sm {
    margin-right: 1.5rem;
  }
}

.navigation-wrapper {
  padding-top: 18px;
  padding-bottom: 18px;

  @screen md {
    padding-top: 18px;

    transition: background $trns;
    background: linear-gradient(
      180deg,
      theme('colors.black-a3') 47.75%,
      theme('colors.black-a0') 100%
    );
  }
}

.logo {
  display: block;
  flex-shrink: 0;
  width: 70px;
  transition: opacity $trns;
  opacity: 1;

  @screen sm {
    width: 80px;
  }

  @screen md {
    width: 150px;
  }

  @screen lg {
    width: 80px;
  }

  @screen xl {
    width: 150px;
  }

  &:hover {
    @apply opacity-icon;
  }
}

.toggle-mobile-nav {
  position: relative;
  width: 18px;
  height: 12px;
  margin-left: 1.125rem;

  @screen md {
    width: 36px;
    height: 24px;
    margin-left: 2rem;
  }

  @screen xl {
    display: none;
  }

  &:focus {
    outline: none;
  }

  span {
    display: block;
    position: absolute;
    left: 0;
    width: 100%;
    height: 2px;

    transform: rotate(0deg);
    transition: $trns;

    opacity: 1;
    background-color: theme('colors.white');

    @screen md {
      height: 4px;
    }

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 5px;

      @screen md {
        top: 10px;
      }
    }

    &:nth-child(4) {
      top: 10px;

      @screen md {
        top: 20px;
      }
    }
  }

  &.-side {
    @screen xl {
      display: block;
    }

    span {
      background-color: #28528e;
    }
  }
}

// mobile menu opened
.mobile-menu-opened {
  @apply bg-primary;

  .navigation-wrapper {
    background: transparent;
  }

  .navigation {
    left: 0;
  }

  [data-holiday-banner] ~ .navigation-wrapper {
    .navigation {
      transform: translateY(60px);
    }
  }

  .toggle-mobile-nav span {
    &:nth-child(1) {
      top: 10px;
      left: 50%;
      width: 0%;
    }

    &:nth-child(2) {
      transform: rotate(45deg);
    }

    &:nth-child(3) {
      transform: rotate(-45deg);
    }

    &:nth-child(4) {
      top: 10px;
      left: 50%;
      width: 0%;
    }
  }
}

.navigation {
  @screen xl {
    transition: opacity $trns;
    transition-delay: $trns-dur-search;
    opacity: 1;
  }
}
