.irgc-101-form {
  @apply bg-white rounded px-8 pt-6 pb-4;
  @screen md {
    width: 500px;
  }
  @screen lg {
    width: unset;
  }
  @screen xl {
    @apply pb-1;
  }

  .forms-form {
    background: unset;
    padding: 0;
  }

  fieldset:nth-child(3) {
    display: inline-block;
    width: 50%;
  }

  fieldset:nth-child(3) + div {
    display: inline-flex;
    width: 50%;
    justify-content: flex-end;
  }

  .btn-primary {
    background-color: #295ba5;
  }

  .paragraph-block {
    @apply text-black text-opacity-70 leading-6 font-medium;
  }

  input[type='text'] {
    @apply h-10 text-base font-medium pt-0;
  }

  input[type='email'] {
    @apply mb-3 h-10 text-base font-medium pt-0;
  }

  input[type='submit'] {
    @apply px-14 mt-0 h-10 text-base;
  }

  .forms-field-label {
    @apply text-sm font-medium text-black mb-0;
  }

  .items-baseline {
    align-items: center !important;
  }

  input[type='checkbox'] {
    @apply mr-2;
    height: 18px;
    width: 18px;
    accent-color: #295ba5;

    &:hover {
      accent-color: #295ba5;
    }
  }
}
