.grid-stories {
  .story {
    img {
      @apply w-full;
    }

    @screen md {
      @apply grid grid-cols-2 gap-8;

      &.-reverse {
        .story-image {
          @apply col-start-2 row-start-1;
        }

        .story-description {
          @apply col-start-1 row-start-1;
        }
      }
    }

    & + .story {
      @apply mt-16;
    }
  }

  .pretty-content {
    @apply text-base leading-relaxed text-gray-dark;

    @screen lg {
      @apply text-lg;
    }
  }
}

.social-share-image {
  .hidden-share-text {
    display: none;
    @apply uppercase text-white text-base;
  }
  &:hover {
    transition: all ease-in-out 150ms;

    img {
      filter: brightness(0.5);
    }

    .hidden-share-text {
      display: block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      @apply p-3 rounded-sm;
      @apply bg-blue text-white;

      &:hover {
        @apply bg-blue-hover;
      }
    }
  }
}

.wjc-download-file {
  &:hover {
    .bounce {
      -moz-animation: bounce 2s infinite;
      -webkit-animation: bounce 2s infinite;
      animation: bounce 2s infinite;
    }

    @keyframes bounce {
      0%,
      20%,
      50%,
      80%,
      100% {
        transform: translateY(0);
      }
      40% {
        transform: translateY(-15px);
      }
      60% {
        transform: translateY(-8px);
      }
    }
  }
}
