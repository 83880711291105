.article-content {
  @apply text-gray-dark;
  word-break: break-word;

  img,
  svg,
  video,
  canvas,
  audio,
  iframe,
  embed,
  object {
    display: inline-block; // overwrite new tailwind default
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-primary;
    margin-bottom: 0.5em;
  }

  p {
    margin-bottom: 1em;

    @screen md {
      margin-bottom: 1.5em;
    }

    @screen lg {
      margin-bottom: 2em;
    }
  }

  hr {
    @apply my-4 border-t border-gray-light;
  }

  a {
    @apply font-bold;

    display: contents;
    color: #0257D5;
    text-decoration: underline;

    &:hover {
      text-decoration: none;
    }
  }

  ol,
  ul {
    margin-bottom: 2em;
    padding-left: 2.5em;
  }

  ol {
    list-style: decimal;
  }

  ul {
    list-style: disc;
  }

  blockquote {
    position: relative;
    margin: 1em 2rem;
    padding: 0.75rem 0 0.75rem 1rem;

    &::before {
      @apply bg-gray-light;

      content: '';
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: 0.25rem;
    }

    p {
      margin-bottom: 0;

      + p {
        margin-top: 1em;

        @screen md {
          margin-bottom: 1.5em;
        }

        @screen lg {
          margin-bottom: 2em;
        }
      }
    }
  }

  iframe {
    max-width: 100%;
  }

  figure {
    margin-bottom: 1.5rem;

    @screen md {
      margin-bottom: 2.5rem;
    }
  }

  figure {
    img {
      margin-bottom: 0;
    }
  }

  figcaption {
    @apply font-bold;
    margin-top: 1.125rem;
  }

  table,
  tr,
  td {
    @apply border border-gray-light;
  }

  table {
    max-width: 100%;
    border-collapse: collapse;
  }

  td {
    padding: 0.25em 0.5em;
  }

  b,
  strong {
    @apply font-bold;
  }

  big {
    font-size: 125%;
  }

  small {
    font-size: 80%;
  }

  em {
    font-style: italic;
  }

  cite {
    font-style: italic;
  }

  u {
    text-decoration: underline;
  }

  ins {
    text-decoration: underline;
  }

  s {
    text-decoration: line-through;
  }

  del {
    text-decoration: line-through;
  }

  sub,
  sup {
    top: 0;
    margin-bottom: 0;
    font-size: 75%;
    line-height: 1em;
  }

  sub {
    vertical-align: sub;
  }

  sup {
    vertical-align: top;
  }

  q {
    &::before {
      content: open-quote;
    }

    &::after {
      content: close-quote;
    }
  }

  address {
    margin-bottom: 1em;
    font-style: italic;
  }

  tt {
    font-family: monospace;
  }

  pre {
    @apply border border-gray-light bg-light rounded text-sm;

    max-height: 600px;
    margin: 1em 0;
    padding: 0.75rem 0.5rem;
    overflow: auto;
  }

  code {
    @apply border border-gray-light bg-light rounded;

    display: inline-block;
    padding: 0 0.25em;
  }

  var {
    font-style: italic;
  }
}

.article-share {
  padding: 1rem 0;
  border: 2px solid theme('colors.primary');
  border-right: 0;
  border-left: 0;

  span {
    @apply text-xs;

    @screen sm {
      @apply text-sm;
    }
  }

  a {
    margin: 0 0.5rem;
  }

  svg {
    width: 24px;
    height: auto;

    @screen sm {
      width: 32px;
    }
  }
}
