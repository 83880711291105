<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div class="modal-container">
          <button
            class="modal-close-button font-sans font-bold"
            @click="$emit('close')"
          >
          </button>
          <slot />
        </div>
      </div>
    </div>
  </transition>
</template>

<style scoped>
  .modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
  }

  .modal-wrapper {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
  }

  .modal-container {
    display: inline-block;
    position: relative;
    max-width: 1200px;
    max-height: 100%;
    overflow-y: auto;
    margin: 0px auto;
    background-color: #fff;
    border-radius: 2px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, .33);
    transition: all .3s ease;
  }

  .modal-default-button {
    float: right;
  }

  .modal-close-button {
    background: url('../../images/icons/close-icon.svg');
    background-size: cover;
    background-color: #FDDF01;
    background-repeat: no-repeat;
    background-position: 0 -3px;
    display: flex;
    position: absolute;
    top: 10px;
    right: 10px;
    padding: 16px 24px;
    border-radius: 1.75rem;
    font-size: 1rem;
  }

  .modal-close-button:hover {
    background-color: rgba(217, 192, 1, 1)
  }

  .modal-close-button:focus {
    box-shadow: 0 0 0 0.2rem rgb(253 223 1 / 50%);
  }

  .modal-enter {
    opacity: 0;
  }

  .modal-leave-active {
    opacity: 0;
  }

  .modal-enter .modal-container,
  .modal-leave-active .modal-container {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
  }
</style>
