.tabs {
  display: flex;
  overflow-x: auto;

  @screen lg {
    justify-content: center;
  }

  @screen xl {
    justify-content: flex-start;
  }

  &:hover {
    .active {
      &:not(:hover) {
        border-color: transparent;
      }
    }
  }

  li {
    flex: 1;

    @screen lg {
      flex: 0 1 auto;
    }
  }

  a {
    @apply text-primary text-sm;

    display: inline-block;
    width: 100%;
    padding: .25rem 1rem;
    transition: border-color $trns;
    border-bottom: 4px solid transparent;
    text-transform: uppercase;
    text-align: center;
    white-space: nowrap;

    @screen sm {
      @apply text-base;
      padding: .625rem 1rem;
    }

    @screen md {
      @apply text-lg;
    }

    @screen lg {
      padding: .625rem 5.75rem;
    }

    &.active,
    &:hover {
      border-color: theme("colors.primary");
    }
  }

  &.even-tabs {
    li {
      @screen lg {
        flex: 1;
      }
    }

    a {
      @screen lg {
        padding: .625rem 1rem;
      }
    }
  }

  &.uneven-tabs {
    @apply justify-start;

    li {
      @apply flex-grow-0;
    }
    a {
      @screen lg {
        padding: .625rem 1rem;
      }
    }
  }
}
