.hezbollah-hero-title {
  @screen xl {
    font-size: 120px;
  }

  @screen lg {
    font-size: 100px;
  }

  @screen md {
    font-size: 80px;
  }

  font-size: 60px;
}

.hezbollah-form-submit {
  p {
    @apply text-black text-opacity-70 font-semi-medium text-base;
  }

  .forms-form {
    padding: 0px;
    margin-bottom: 0px;
  }

  .forms-field-wrap {
    position: relative;
  }

  .forms-form input[type='submit'] {
    margin-top: 0px;
    background-color: #295ba5;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    position: absolute;
    right: 0;
    bottom: 24px;
  }

  .forms-form .forms-field-checkbox {
    margin-top: 1rem;
  }
}

.hezbollah-form-submit .forms-form input[type=submit] {
  position: relative;

  @screen md {
    position: absolute;
  }
}

.background-section-image-bg {
  background-image: url('../images/hezbollah-bg-section.jpg');
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}

.expansion-section-image-bg {
  background-image: url('../images/hezbollah-bg-section-3.png');
  background-position: top;
  background-repeat: no-repeat;
  background-size: contain;
}

.targeting-section-image-bg {
  background-image: url('../images/hezbollah-bg-map.svg');
  background-position-y: 6%;
  background-size: contain;
  background-repeat: no-repeat;
}

.grid-entry {
  .entry-video-odd {
    @apply hidden;
  }

  .entry-summary {
    @apply col-span-12;

    @screen md {
      @apply col-span-6 col-start-7;
    }

    @screen lg {
      @apply col-span-7 col-start-7;
    }

    @screen xl {
      @apply col-span-6 col-start-6;
    }
  }
}

.hezbollah-background-section {
  .grid-entry.-reverse-entry {
    .entry-video-even {
      display: block !important;
    }

    .entry-video-odd {
      @apply hidden;
    }

    .entry-summary {
      @apply col-span-12;

      @screen md {
        @apply col-span-6 col-start-7;
      }

      @screen lg {
        @apply col-span-7 col-start-7;
      }

      @screen xl {
        @apply col-span-5 col-start-8;
      }
    }
  }

  .grid-entry {
    .entry-video-even {
      @screen md {
        @apply hidden;
      }
    }

    .entry-video-odd {
      @apply hidden;

      @screen md {
        @apply block;
      }
    }

    .entry-summary {
      @screen md {
        @apply col-span-6 col-start-1;
      }
      @screen lg {
        @apply col-span-6 col-start-1;
      }

      @screen xl {
        @apply col-span-5 col-start-1;
      }
    }
  }
}

.hezbollah-targeting-section {
  .grid-entry {
    background: linear-gradient(#fff, #fff) left/2px 100% no-repeat;
    grid-gap: 16px;
    margin: 5px;

    @screen lg {
      background: linear-gradient(#fff, #fff) center/2px 100% no-repeat;
    }

    .entry-summary {
      @apply col-span-2 relative;
      background: transparent;
      position: relative;
      padding-left: 3rem;
      padding-bottom: 1rem;

      @screen lg {
        @apply col-span-1 col-start-2;
        padding-left: 98px;
        padding-bottom: 0;
      }
    }

    .entry-summary::after {
      content: '';
      background-image: url('../../assets/images/youth_assembly/white-dot.svg');
      width: 16px;
      height: 16px;
      left: -7px;
      top: -1.5%;
      position: absolute;

      @screen lg {
        right: 100%;
        left: auto;
      }
    }
  }

  .grid-entry.-reverse-entry {
    .entry-summary {
      @screen lg {
        @apply col-span-1 col-start-1 pr-24 pl-0;
      }
    }

    @screen lg {
      .entry-summary::after {
        content: '';
        background-image: url('../../assets/images/youth_assembly/white-dot.svg');
        width: 16px;
        height: 16px;
        right: -16px;
        top: -1%;
        position: absolute;
      }
    }
  }
}
