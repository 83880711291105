.caoc-video {
  .embedded-video {
    @screen md {
      padding-bottom: 67%;
    }
  }
}

.advisory-member-circle {
  width: 68px;
  height: 68px;
  object-fit: cover;
}

.lead-member-circle {
  width: 100px;
  height: 100px;
  @screen md {
    width: 166px;
    height: 166px;
  }
  object-fit: cover;
}

.resource-section {
  .caoc-date,
  .caoc-annotation {
    @apply text-white text-opacity-60;
  }

  h5 {
    @apply text-white;
  }

  .swiper-button-prev,
  .swiper-button-next {
    svg {
      @apply fill-white;
    }
  }
}

.other-actions-section {
  .caoc-date,
  .caoc-annotation {
    @apply text-blue-0C2B46 text-opacity-60;
  }

  .swiper-button-prev,
  .swiper-button-next {
    svg {
      fill: #0c2b46;
    }
  }
}

.actions-section {
  .swiper-button-prev,
  .swiper-button-next {
    svg {
      fill: white;
    }
  }
}
