.communities {
  @apply bg-primary;

  padding-top: 3rem;
  padding-bottom: 3rem;
  background-image: url('../images/world-map.svg');
  background-repeat: no-repeat;
  background-position: 40% 160px;
  background-size: 250% auto;

  @screen sm {
    padding-top: 4rem;
    background-size: 190% auto;
  }

  @screen md {
    background-position: center;
    background-size: contain;
  }

  @screen lg {
    padding-top: 6.5rem;
    padding-bottom: 2rem;
    background-image: none;
  }

  .hero-scroll-down {
    position: static;
    margin: auto auto 0;
  }
}

.region-dropdowns {
  display: flex;
  position: relative;
  z-index: 1;
  flex-direction: column;
  align-items: center;

  @screen lg {
    flex-direction: row;
    justify-content: space-between;
  }
}

.region-dropdown {
  &.open {
    .dropdown-trigger {
      @apply rounded-b-none bg-white text-primary;

      &:focus {
        box-shadow: none;
      }

      &::after {
        border-top-color: theme('colors.primary-a5');
        opacity: 1;
      }
    }
  }
}

.region-dropdown-toggle {
  @apply rounded-sm text-white-a8 text-xl font-medium;

  width: 280px;
  height: 42px;
  padding-right: 1.5rem;
  padding-left: 0.75rem;
  transition: all $trns;
  text-align: left;
  white-space: nowrap;

  @screen lg {
    @apply text-lg;

    width: 100%;
    height: 35px;
  }

  @screen xl {
    height: 42px;
    padding-right: 2.5rem;
  }

  &:hover {
    @screen xl {
      @apply bg-white text-primary;

      &::after {
        opacity: 1;
      }
    }
  }

  &:focus {
    @apply shadow-input-white outline-none;
  }

  &::after {
    right: 7px;
    border-top: 5px solid theme('colors.white-a8');

    @screen xl {
      right: 14px;
      border-top-color: theme('colors.primary-a5');
      opacity: 0;
    }
  }
}

.searchable-input-container {
  @apply flex items-center text-lg font-normal;

  padding: 0.75rem 1.25rem 0.75rem 1rem;

  .searchable-input {
    @apply bg-transparent ml-2;

    box-shadow: none;

    &:focus {
      outline: none;
    }

    &::placeholder {
      overflow: visible;
    }
  }
}

.drag-map {
  cursor: grabbing;
  user-select: none;

  .map-container {
    transition: none;
  }
}

.map-container {
  max-width: theme('screens.xxl');
  margin: auto;
  transform: translate(0, 0) scale(1, 1);
  transform-origin: center;
  // transform-style: preserve-3d;
  // backface-visibility: hidden;
  transition: all 0.5s ease-in-out;
  filter: blur(0);
  // will-change: translate, scale3d;
  // -webkit-font-smoothing: subpixel-antialiased;
}

.map-svg {
  display: block;
  width: 100%;
  height: auto;
  transform: translateZ(0);

  .land {
    @apply fill-blue stroke-white-a5;

    transition: fill $trns;
    stroke-width: 0.5;
    cursor: pointer;
    pointer-events: none;

    @screen lg {
      pointer-events: auto;
    }

    &:hover,
    &.-active,
    &.has-body.-active,
    &.has-body:hover {
      @apply fill-white-a8;
    }

    &.non-affiliated {
      @apply fill-primary;
    }

    &.non-affiliated:not(.has-body) {
      cursor: not-allowed;
    }
  }
}

.country-popover {
  @apply bg-white text-primary shadow-lg;
  @apply fixed z-50;

  width: 350px;
  top: 0;
  left: 0;
  padding: 1.25rem 1.875rem;
  overflow: scroll;

  @screen xl {
    width: 400px;
  }

  &.-right {
    left: auto;
    right: 0;
  }

  &::before {
    content: "";
    position: absolute;
    top: 10px;
    border-top: 8px solid transparent;
    border-bottom: 8px solid transparent;
    left: -9px;
    border-right: 10px solid theme("colors.white");
  }

  &.-right::before,
  &[x-placement='left']::before {
    left: unset;
    right: -18px;
    border-left: 10px solid theme("colors.white");
    border-right: 8px solid transparent;
  }
}

.country-popover-content {
  @apply text-base;

  @screen xl {
    @apply text-lg;
  }

  padding-top: .875rem;
  padding-bottom: .625rem;
  overflow: hidden;
}

.zoom-btns {
  @apply rounded bg-white-a8;
  position: relative;

  &::before {
    @apply bg-primary-a2;

    content: '';
    position: absolute;
    top: 2rem;
    left: 0.25rem;
    width: 24px;
    height: 1px;
  }
}

.zoom-btn {
  display: block;
  position: relative;
  width: 2rem;
  height: 2rem;
  margin-bottom: 1px;
  padding: 0;

  &:hover {
    @apply bg-white;
  }

  &:focus {
    @apply shadow-input-white;
  }

  &::before,
  &::after {
    @apply bg-primary;

    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &::before {
    width: 14px;
    height: 2px;
    margin-top: -1px;
    margin-left: -7px;
  }
}

.zoom-btn-in {
  @apply rounded-t rounded-b-none;

  &::after {
    width: 2px;
    height: 14px;
    margin-top: -7px;
    margin-left: -1px;
  }
}

.zoom-btn-out {
  @apply rounded-t-none rounded-b;
}

.country-container {
  height: 400px;

  @screen md {
    height: 600px;
  }

  @screen lg {
    height: 800px;
  }
}

.about-title {
  h2 {
    @apply font-medium;
    @apply text-2xl;
    @apply uppercase;
  }
}

.community-style {
  .modal-container {
    @apply w-full;

    background: transparent!important;
  }

  .modal-close-button {
    @apply ml-auto mb-3 mr-5;

    z-index: 999999;
    position: relative!important;
  }
}