h1,
h2,
h3,
h4,
h5,
h6 {
  @apply font-medium;

  a {
    color: inherit;
  }
}

h1 {
  @apply text-2xl;

  @screen sm {
    @apply text-3xl;
  }

  @screen lg {
    @apply text-4xl;
  }
}

h2 {
  @apply text-3xl;
}

h3 {
  @apply text-xl;

  @screen sm {
    @apply text-2xl;
  }
}

h4 {
  @apply text-lg;

  @screen sm {
    @apply text-xl;
  }
}

h5 {
  @apply text-lg;
}

h6 {
  @apply text-sm;
}

b,
strong {
  @apply font-bold;
}
