.rotate-rows {
  > div:nth-child(even) {
    flex-direction: row-reverse !important;
  }
}

.video-frame {
  position: relative;
  width: 100%;
  max-width: 100%;
  padding-bottom: 56.25%;
  overflow: hidden;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
