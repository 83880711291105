.container-neg-margin {
  margin-top: -6rem;

  @screen lg {
    margin-top: -11rem;
  }
}

.section-neg-margin {
  margin-top: -50px;
}

.author-image-style {
  width: 48px;
  height: 48px;
}

.learn-more-image-container {
  height: 602px;
}

.hover-effect {
  transition-duration: 0.6s;

  &:hover {
    @apply shadow-lg-hover;
    transform: translateY(-20px);
    transition-duration: 0.6s;
  }
}