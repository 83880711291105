
.elevate-representatives {
  .elevate-representatives-header {
    @apply font-600 text-white text-2xl font-suisse-intl;

    @screen lg {
      font-size: 72px;
    }
  }

  .elevate-representatives-subtitle  {
    @apply  font-450 text-white text-lg font-suisse-works leading-8 text-opacity-80 mt-10;

    p {
      @apply mt-4;
    }
  }
}
.representatives-bg-image {
  background-size: 45%;
  background-repeat: no-repeat;
  background-position-x: 100%;
  background-position-y: 100px;
}