





































































































  .hover-element {
    @apply opacity-0 absolute top-0 right-0 w-full;
    @apply transform transition-all duration-150;
    background-image: linear-gradient(0deg, rgba(41, 91, 165, 0.50) 0%, rgba(41, 91, 165, 0.50) 100%);
    min-height: 239px;

    .yellow-arrow {
      @apply absolute top-0 right-0;
    }

    &:hover {
      @apply opacity-100;
    }
  }
.youtube-video-width {
  max-width: 362px;

  iframe{
    width: 100%;
    height: 239px;
  }
}

.representative-img {
  min-height: 239px;
}
.iframe-size {
  iframe {
    widows: 100%;
    min-height: 239px;
  }
  
  .youtube-iframe {
    width: 100%;
    height: 239px;
  }
}

