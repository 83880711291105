.hamas-101-new-page {
  .hamas-hero-title {
    @screen xl {
      font-size: 120px;
    }

    @screen lg {
      font-size: 100px;
    }

    @screen md {
      font-size: 80px;
    }

    font-size: 60px;
  }

  .october-7th-video-size {
    .embedded-video {
      @screen lg {
        padding-bottom: 160%;
      }
    }
  }

  .hamas-form-submit {
    p {
      @apply text-black text-opacity-70 font-semi-medium text-base;
    }

    .forms-form {
      padding: 0px;
      margin-bottom: 0px;
    }

    .forms-field-wrap {
      position: relative;
    }

    .forms-form input[type='submit'] {
      margin-top: 0px;
      background-color: #295ba5;
      padding-left: 2.5rem;
      padding-right: 2.5rem;
      position: absolute;
      right: 0;
      bottom: 24px;
    }

    .forms-form .forms-field-checkbox {
      margin-top: 1rem;
    }
  }

  .hamas-page-spacing {
    margin-bottom: 0px;

    @screen lg {
      margin-bottom: 108px;
    }
  }

  .hamas-top-part-section {
    @apply relative pt-40 pb-10 overflow-hidden;
    @apply bg-cover bg-center;
  }

  .about-section-image-bg {
    background-image: url('../images/hamas-about-section2.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .in-their-own-words-image-bg {
    background-image: url('../images/in-their-words-2.png');
    background-position: top;
    background-repeat: no-repeat;
    background-size: contain;
  }

  .learn-more-page-title {
    width: 213px;
    height: 150px;

    @screen md {
      width: 313px;
      height: 213px;
    }

    @screen lg {
      width: 458px;
      height: 313px;
    }

    @screen xl {
      width: 558px;
      height: 313px;
    }
  }
}
