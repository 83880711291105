.pledge-hero-section {
  .inner-hero-container {
    display: block;
    min-height: 800px;

    @screen sm {
      min-height: 800px;
    }

    @screen md {
      min-height: 1000px;
    }

    @screen lg {
      min-height: 1100px;
    }
  }
}

.pledge-hero-gradient {
  @apply absolute bottom-0 left-0 right-0;
  height: 35rem;
  background: linear-gradient(
    180deg,
    rgba(8, 40, 79, 0) 0%,
    #08284f 60%,
    #08284f 100%
  );
}

.pledge-about-reveal-content {
  .reveal-button {
    @apply underline uppercase;
  }
}

.country-wrapper {
  @apply border border-transparent outline-none;
  padding: 2px;

  &:hover {
    @apply bg-blue-a05;
    @apply border border-blue-a5;
    @apply rounded;
  }
}

.countries-group-items {
  // BEWARE! Those values are hardcoded inside Countries.vue as well!!!
  @apply grid grid-cols-1 gap-4;

  @screen md {
    @apply grid-cols-2 gap-6;
  }

  @screen lg {
    @apply grid-cols-3;
  }

  @screen xl {
    @apply grid-cols-4;
  }

  @screen xxl {
    @apply grid-cols-5;
  }
  // END OF BEWARE
}

.selected-triangle-up {
  position: absolute;
  bottom: 0;
  visibility: hidden;
  width: 100%;
  height: 0;
  padding-left: 10%;
}

.selected-triangle-up:after {
  position: relative;
  top: 4px;
  content: '';
  display: block;
  width: 0;
  height: 0;
  margin-left: 40px;
  @apply border-b border-primary;
  border-bottom: 18px solid;
  border-left: 18px solid transparent;
  border-right: 18px solid transparent;
}
