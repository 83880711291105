.combat-antisemitism {

  .main-font {
    font-family: 'Suisse Works';
  }

  .global-title {
    font-size: 48px;
    line-height: 62px;

    @screen md {
      font-size: 72px;
      line-height: 93px;
    }
  }

  .main-title {
    font-size: 32px;
    line-height: 44px;

    @screen md {
      font-size: 48px;
      line-height: 62px;
    }
  }

  .subtitle {
    font-size: 24px;
    line-height: 32px;

    @screen md {
      font-size: 32px;
      line-height: 44px;
    }
  }

  .text-styles {
    font-weight: 400;
    font-size: 20px;
    line-height: 32px;
    color: #021934;
  }

  .map-main-title {
    font-size: 48px;
    line-height: 62px;
  }

  button:focus {
    outline: none;
  }

  .selected-group {
    @apply text-white;
    @apply font-semi-medium;
    @apply text-lg;
    @apply text-center;
    @apply capitalize;
    @apply p-4;
  }

  .selected-group:focus {
    @apply bg-white;
    @apply text-black;
    @apply font-semi-medium;
    @apply text-lg;
    @apply text-center;
    @apply capitalize;
    @apply p-4;
  }

  .raise:hover,
  .raise:focus {
    box-shadow: 0 0.5em 0.5em -0.4em var(--hover);
    transform: translateY(-0.70em);
    transition: 400ms;
  }

}

.resource-svg-icon {
  min-width: 12px;
  max-width: 12px;
}

.our-strategy {
  .strategy-active {
    background: #F2F9FF !important;
    opacity: 1 !important;
  }
}

.embed-section-online-center {
  height: 120px;

  @screen sm {
    height: 150px
  }

  @screen md {
    height: 250px;
  }

  @screen lg {
    height: 250px;
  }

  @screen xl {
    height: 300px;
  }
}

.white-section-under-embed-online-center {
  height: 120px;

  @screen sm {
    height: 150px;
  }
  @screen md {
    height: 150px;
  }
  @screen lg {
    height: 150px;
  }

  @screen xl {
    height: 200px;
  }

}

.embed-code-online-center {
  @extend .embedded-video;
}
