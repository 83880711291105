.programs-carousel {
  margin: 0 -19px;

  .swiper-container {
    padding: 14px 19px;
  }

  .swiper-button-prev {
    left: 31px;

    @screen xxl {
      left: -53px;
    }
  }

  .swiper-button-next {
    right: 31px;

    @screen xxl {
      right: -53px;
    }
  }
}

.program {
  @apply bg-primary text-white;

  position: relative;
  transform-origin: center center;
  transition: all $trns;

  &::before {
    @screen sm {
      @apply bg-primary-a9;
      content: '';
      display: block;
      position: absolute;
      z-index: 1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      transition: all $trns;
      opacity: 0;
    }
  }

  &:hover {
    @screen sm {
      z-index: 1;
      transform: scale(1.10525);
      border-radius: 2px;

      img {
        border-radius: 2px;
        opacity: 1;
      }
    }
  }

  img {
    width: 100%;
    transition: opacity $trns;

    @screen sm {
      opacity: 0.4;
    }
  }

  h4 {
    @apply font-bold;

    max-width: 280px;
    margin: 0 auto 0.75rem;

    @screen sm {
      @apply text-lg;
    }

    @screen md {
      @apply text-xl;
    }

    @screen lg {
      @apply text-lg;
    }

    @screen xl {
      @apply text-xl;

      margin-bottom: 1.5rem;
      transform: translateY(1.5rem);
      transition: all $trns;
    }
  }

  .btn {
    @screen xl {
      transform: translateY(1.5rem);
      transition: all $trns;
      opacity: 0;
    }
  }

  a {
    &::before {
      content: '';
      position: absolute;
      z-index: 10;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }
}

.programs-swiper-nav-btn {
  @screen xxl {
    &:focus {
      @apply shadow-btn-primary;
    }

    svg {
      @apply fill-primary;
    }
  }
}
