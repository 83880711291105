.leadership-bridge {
  button:focus {
    outline:none;
    box-shadow: none;
  }

  .origin-section-image {
    width: 162px;
    height: 283px;

    @screen md {
      width: 362px;
      height: 320px;
    }

    @screen lg {
      width: 362px;
      height: 483px;
    }
  }

  .wjc-nominations-link {
    @apply p-0;
    max-height: 160px;

    img {
      @apply mb-0;
      max-height: 160px;
      filter: grayscale(0);
    }

    &:hover {
      img {
        filter: grayscale(0);
      }
    }
  }
}

.bridge-map {
  @apply hidden;

  @screen lg {
    @apply block relative w-full h-full;

    background-repeat: no-repeat;
    background-position: center top;
    background-size: contain;

    .bridge-map-svg {
      margin-left: auto;
      margin-right: auto;
      width: 100%;
    }
  }
}



.bridge-map-mark {
  background-color: #295BA5;
  display: none;
  position: absolute;
  width: 17px;
  height: 17px;
  transition: background-color $trns;
  border-radius: 50%;
  cursor: pointer;

  @screen lg {
    display: block;
  }

  &.hoverable-bridge-mark {
    &:hover {
      @apply bg-white;
      
      width: 17px;
      height: 17px;
      cursor: pointer;
      box-shadow: 0 0 0px 6px rgba(41,91,165, 1);
    }
  }

  &:focus {
    outline: none;
  }

  &.active {
    width: 17px;
    height: 17px;
  }

  &:hover {
    .map-tooltip {
      cursor: pointer;
      opacity: 1;
    }
  }
}

.map-tooltip {
  @apply text-white absolute text-base;
  @apply font-600 font-suisse-intl py-2 pl-2 pr-3;

  width: max-content;
  min-height: 30px;
  background-color: #295BA5;
  text-align: center;
  z-index: 1;
  bottom: 125%; /* Position above the anchor */
  transform: translateX(5%);
  opacity: 0;
  transition: opacity 0.2s;
  
  img {
    max-width: 80px;
    max-height: 40px;
  }

  &:hover {
    cursor: pointer;
    opacity: 1;
  }

  &.us-tooltip {

  }

  &.belgium-tooltip {
    top: 130%;
    bottom: unset;
    transform: translateX(-95%);
  }

  &.astria-tooltip {
    bottom: 100%;
  }

  &.italy-tooltip {
    bottom: -325%;
  }

  &.israel-1-tooltip{
    bottom: -345%;
  }

  &.argentina-tooltip {
    bottom: -350%;
  }
}

.no-logo-country {
  left: 170%;
}