.university-main-img {
  max-width: 80px;
  @screen sm {
    max-width: 120px;
  }
}

.uni-side-img {
  width: 48px;
  height: 48px;
}

.fellow-box {
  @screen lg {
    width: 264px;
  }
}
.life-on-campus-navigation {
  @apply relative;

  .mobile-dropdown-navigation-content {
    @apply hidden absolute z-10;
    background-color: #f7f7f7;
    transform-origin: top center;
    animation: slideDown 300ms ease-in-out forwards;

    @keyframes slideDown {
      0% {
        opacity: 0;
        transform: translateY(-20px);
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .mobile-dropdown-navigation-content.show {
    @apply flex flex-col w-full;
  }
}
