.durban-hero {
  min-height: 800px;

  @media screen and (max-width: 420px) {
    min-height: 1000px;
  }

  @screen sm {
    min-height: 700px;
  }

  @screen xl {
    min-height: 800px;
  }
}
