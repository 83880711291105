.image-plus-text-item {
  @screen md {
    display: flex;
  }

  &:not(:last-child) {
    margin-bottom: 2rem;

    @screen sm {
      margin-bottom: 3rem;
    }

    @screen md {
      margin-bottom: 5rem;

      &.-jdcorps {
        margin-bottom: 4rem;
      }
    }
  }

  &:nth-child(odd) {
    .txt-holder {
      @screen md {
        margin-left: 1rem;
      }

      @screen lg {
        margin-left: 3rem;

        &.-jdcorps {
          margin-left: 2rem;
        }
      }

      @screen xl {
        margin-left: 5rem;

        &.-jdcorps {
          margin-left: 2rem;
        }
      }
    }
  }

  &:nth-child(even) {
    .txt-holder {
      @screen md {
        order: -1;
        margin-right: 1rem;
      }

      @screen lg {
        margin-right: 3rem;

        &.-jdcorps {
          margin-right: 2rem;
        }
      }

      @screen xl {
        margin-right: 5rem;

        &.-jdcorps {
          margin-right: 2rem;
        }
      }
    }
  }

  .img-holder {
    flex-shrink: 0;

    @screen md {
      width: 45%;
    }

    @screen xl {
      width: 50%;
    }

    img {
      width: 100%;
    }
  }

  .txt-holder {
    @apply text-gray-dark text-base;
    padding-top: 1rem;

    @screen md {
      flex: 1;
    }

    @screen lg {
      @apply text-lg;
    }
  }

  .summary {
    margin-bottom: 1rem;

    @screen md {
      margin-bottom: 1.5rem;
    }

    @screen lg {
      margin-bottom: 2rem;
    }
  }
}

.anchor {
  position: relative;
  top: -180px;

  @screen md {
    top: -140px;
  }

  @screen lg {
    top: -160px;
  }
}
