.resize-youtube-video {
  iframe {
    @apply w-full h-full;
  }
}

.hover-tweak-elevate-honors {
  &:hover {
    .member {
      color: #295BA5!important;
    }
  }
}

.elevate-heading-dark-color {
  color: #00213F;
}

.interviews-bg-color {
  background-color: #F2F5F8;
}

.stream-description-custom-styles {
  @apply mx-auto;
  max-width: 568px;
}