@font-face {
  font-family: 'Avenir';
  src: url('../fonts/avenir/Avenir-Book.eot');
  src: url('../fonts/avenir/Avenir-Book.woff2') format('woff2'),
    url('../fonts/avenir/Avenir-Book.woff') format('woff'),
    url('../fonts/avenir/Avenir-Book.ttf') format('truetype'),
    url('../fonts/avenir/Avenir-Book.svg#Avenir-Book') format('svg'),
    url('../fonts/avenir/Avenir-Book.eot?#iefix') format('embedded-opentype');
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/avenir/Avenir-Roman.otf');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/avenir/Avenir-Medium.eot');
  src: url('../fonts/avenir/Avenir-Medium.woff2') format('woff2'),
    url('../fonts/avenir/Avenir-Medium.woff') format('woff'),
    url('../fonts/avenir/Avenir-Medium.ttf') format('truetype'),
    url('../fonts/avenir/Avenir-Medium.svg#Avenir-Medium') format('svg'),
    url('../fonts/avenir/Avenir-Medium.eot?#iefix') format('embedded-opentype');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/avenir/Avenir-Heavy.eot');
  src: url('../fonts/avenir/Avenir-Heavy.woff2') format('woff2'),
    url('../fonts/avenir/Avenir-Heavy.woff') format('woff'),
    url('../fonts/avenir/Avenir-Heavy.ttf') format('truetype'),
    url('../fonts/avenir/Avenir-Heavy.svg#Avenir-Heavy') format('svg'),
    url('../fonts/avenir/Avenir-Heavy.eot?#iefix') format('embedded-opentype');
  font-weight: 800;
  font-style: normal;
}

@font-face {
  font-family: 'Avenir';
  src: url('../fonts/avenir/Avenir-Black.otf');
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: 'AtlasProAAA';
  src: url('../fonts/atlasPro/AtlasProAAA-Regular.eot');
  src: url('../fonts/atlasPro/AtlasProAAA-Regular.eot?#iefix')
      format('embedded-opentype'),
    url('../fonts/atlasPro/AtlasProAAA-Regular.svg#AtlasProAAA-Regular')
      format('svg'),
    url('../fonts/atlasPro/AtlasProAAA-Regular.ttf') format('truetype'),
    url('../fonts/atlasPro/AtlasProAAA-Regular.woff') format('woff'),
    url('../fonts/atlasPro/AtlasProAAA-Regular.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Suisse Works';
  src: url('../fonts/suisse-works/SuisseWorks-Medium.otf');
  src: url('../fonts/suisse-works/SuisseWorks-Medium.ttf') format('truetype');
  font-weight: 450;
  font-style: normal;
}

@font-face {
  font-family: 'Suisse Works';
  src: url('../fonts/suisse-works/SuisseWorks-Bold.otf');
  src: url('../fonts/suisse-works/SuisseWorks-Bold.ttf') format('truetype');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Suisse Works';
  src: url('../fonts/suisse-works/SuisseWorks-Regular.otf');
  src: url('../fonts/suisse-works/SuisseWorks-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/suisse-intl/SuisseIntl-Regular-WebM.woff2') format('woff2');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/suisse-intl/SuisseIntl-Medium-WebM.woff2') format('woff2');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/suisse-intl/SuisseIntl-SemiBold-WebM.woff2')
    format('woff2');
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: 'Suisse Intl';
  src: url('../fonts/suisse-intl/SuisseIntl-Bold-WebM.woff2') format('woff2');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'Fondamento';
  src: url('../fonts/fondamento/Fondamento-Regular.ttf') format('truetype');
  font-weight: 400;
  font-style: normal;
}
