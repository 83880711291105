.footer {
  @apply bg-dark text-white;

  position: relative;
  z-index: 1;
  padding: 2rem 0;

  @screen md {
    padding: 4rem 0;
  }

  .newsletter-form {
    @apply border-light rounded-7 bg-light;

    display: flex;
    height: 54px;
    transition: border-color $trns, background-color $trns, box-shadow $trns;
    border-width: 3px;

    @screen md {
      width: 100%;
      max-width: 360px;
    }

    &:focus-within {
      @apply border-white bg-white shadow-input-white;
    }

    .newsletter-email {
      @media (max-width: 575px) {
        @apply text-sm;

        height: 100%;
        margin-bottom: 0;
        padding-right: .5rem;

        &:focus {
          @apply bg-white shadow-none;
        }
      }
    }

    .btn {
      @media (max-width: 575px) {
        @apply rounded-xl text-sm;
        padding-right: 10px;
        padding-left: 10px;
      }
    }
  }
}

.footer-logo-col {
  width: 195px;
  margin: 0 auto 2rem;

  @screen md {
    @apply flex-shrink-0;

    margin-right: 2rem;
    margin-bottom: 0;
    margin-left: 0;
    padding-top: 2.75rem;
  }

  @screen lg {
    margin-right: 6rem;
  }
}

.footer-menu {
  @screen md {
    display: flex;
    flex-wrap: wrap;
  }

  > li {
    flex: 1 1 auto;
    margin-bottom: 2rem;
    text-align: center;

    @screen md {
      margin-bottom: 3rem;
      text-align: left;
    }

    @screen lg {
      flex: 0 1 auto;
    }
  }
}

.footer-main-link {
  @apply text-white text-sm font-bold;

  display: inline-block;
  text-transform: uppercase;

  @screen md {
    margin-bottom: .625rem;
  }

  &:hover {
    text-decoration: underline;
  }
}

.footer-inner-links {
  @apply mr-gutter;
  display: none;

  @screen md {
    display: block;
  }

  @screen lg {
    width: 175px;
  }

  @screen xl {
    width: 165px;
  }

  &.lg\:wider {
    @screen lg {
      width: 260px;
    }
  }

  &.xl\:wider {
    @screen xl {
      width: 260px;
    }
  }

  a {
    @apply text-white text-xs font-medium leading-loose;

    opacity: .7;
    text-transform: uppercase;

    &:hover {
      text-decoration: underline;
    }
  }
}

.footer-socials {
  display: flex;
  justify-content: center;

  a {
    transition: opacity $trns;
    opacity: .8;

    &:hover {
      opacity: 1;
    }

    &:not(:last-child) {
      margin-right: 2rem;
    }
  }
}

.footer-search-form {
  @apply bg-primary;

  display: flex;
  align-items: center;
  height: 54px;
  padding: 0 1rem;
  transition: box-shadow $trns;
  border-radius: 27px;

  &:focus-within {
    @apply shadow-input-white;
  }

  img {
    opacity: .7;
  }

  input {
    @apply bg-transparent text-white;

    width: 100%;
    height: 100%;
    margin-left: 1rem;

    &:focus {
      @apply border-transparent outline-none;
    }

    &::placeholder {
      @apply text-white-a5;

      opacity: 1;
    }
  }
}
