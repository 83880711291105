.table-facts {
  li > a {
    @apply w-full;
    @apply text-base text-left text-primary text-opacity-70;
    @apply px-6 py-3;
    @apply flex justify-between items-center;

    &:hover {
      @apply text-primary text-opacity-100 no-underline;
    }
  }
  li {
    @apply border-l-4 border-transparent outline-none;

    svg {
      @apply opacity-0;
    }
  }

  li.active {
    @apply border-l-4 border-blue outline-none bg-light;

    a {
      @apply text-primary text-opacity-100 no-underline;
    }

    svg {
      @apply opacity-100;
    }
  }

  &.irgc-facts {
    li.active {
      @apply border-l-4;
      border-color: #58000b;
      background-color: #fbf7f7;

      span {
        @apply no-underline font-bold;
      }
    }
  }

  &.arrow-hover-active-state {
    svg {
      @apply hidden pl-0 pr-2;
      min-width: 16px;
    }

    a:not(:last-of-type) {
      @apply text-base font-medium text-opacity-100 pl-3 py-2;

      &:hover {
        @apply text-primary text-opacity-100 no-underline font-bold;

        svg {
          @apply opacity-100 block;
        }
      }
    }

    //Special class for communities table of content
    .affiliate {
      @apply pl-0 pb-0 ml-0 pb-0;
    }

    .fact {
      @apply pb-0 pr-0;
    }

    .active,
    li.active {
      @apply border-none border-transparent;

      a {
        @apply text-primary text-opacity-100 no-underline font-bold;
      }

      svg {
        @apply opacity-100 block;
      }
    }

    .table-facts-aside-nav {
      top: 5rem;
    }
  }
}

.table-facts-aside-nav {
  @apply sticky;

  top: 1rem;
}

.table-facts-shadow {
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.15);
}

.community-learn-more-section {
  @apply sticky flex flex-col;
  @apply items-center justify-center bg-cover bg-center mt-2;

  top: 44rem;
  box-shadow: 0px 2px 4px 1px rgba(0, 0, 0, 0.15);
}

.table-facts-share-facts-link {
  @apply transition-all duration-200 ease-in-out;

  @media screen and (max-width: 450px) {
    @apply w-full;
  }

  @screen md {
    width: 60%;
  }
  margin: auto;

  @screen lg {
    @apply w-full;
  }
}

.table-facts-share-image-side {
  overflow: hidden;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;

  @screen lg {
    border-top-right-radius: 0px;
    border-bottom-left-radius: 12px;
  }
}
.table-facts-share-text-side {
  overflow: hidden;
  border-bottom-left-radius: 12px;
  border-bottom-right-radius: 12px;

  @screen lg {
    border-bottom-left-radius: 0px;
    border-top-right-radius: 12px;
  }
}
