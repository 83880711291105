


















































.btn {
  @apply relative;

  &:hover {
    .atc-dropdown {
      @apply flex;
    }
  }
}

.atc-dropdown {
  @apply absolute z-20 left-0;
  @apply hidden;
  @apply rounded;
  @apply w-full;
  @apply flex-col justify-start;
  @apply bg-white;
  @apply shadow-md;

  a {
    @apply block;
    @apply px-8 py-6;

    &:hover {
      @apply bg-white-hover;
    }
  }
}
