.mena-layout {
  .tooltip-button {
    color: #ed8f0a !important;
  }

  .image-block {
    @apply mt-6;
  }
}

.mena-page-title {
  color: #ed8f0a;

  @screen xl {
    font-size: 70px;
  }
  @screen xxl {
    font-size: 80px;
  }
}

.mena-list-title {
  color: #ed8f0a;
  @apply text-xl font-medium font-fondamento;

  @screen xl {
    @apply text-10;
  }
}

.mena-header.header {
  @apply bg-transparent shadow-none z-50;
}

.mena-bio-video-wrapper {
  @screen xl {
    width: 340px;

    > iframe {
      width: 100%;
    }
  }
}

.mena-bio-image {
  width: 320px;

  @screen sm {
    width: 340px;
    img {
      width: 340px;
    }
  }

  @screen lg {
    width: 280px;
    img {
      width: 280px;
    }
  }

  @screen xl {
    width: 340px;
    img {
      width: 340px;
    }
  }
}

.country-jews-image {
  width: 244px;
  height: 324px;
}

.image-book-effect-bio {
  right: 2rem;
  height: 340px;
  width: 200px;
  background: #f3a148;
  z-index: 0;
  top: -0.4rem;
  transform: rotate(-3.4deg);

  @screen sm {
    right: -1rem;
    height: 440px;
    width: 340px;
    background: #f3a148;
    z-index: 0;
    top: -0.4rem;
    left: 1.5rem;
    transform: rotate(-3.4deg);
  }

  @screen lg {
    right: -1rem;
    width: 260px;
    height: 360px;
    left: auto;
  }

  @screen xl {
    height: 440px;
    width: 340px;
  }
}

.article-content.mena-bio-content {
  .image-block-image {
    width: 100%;
  }

  .image-block-caption {
    text-align: left;
  }
}

.country-name {
  @apply text-white;
  @apply absolute;
  @apply font-fondamento;
  @apply font-semi-medium;
  @apply text-xl;
  bottom: 16px;
  left: 24px;
}

.image-book-effect {
  height: 320px;
  width: 234px;
  background: #f3a148;
  z-index: 0;
  top: -0.4rem;
  left: 1.5rem;
  transform: rotate(-3.4deg);

  @screen sm {
    right: 0rem;
  }

  @screen lg {
    right: 1.5rem;
  }

  @screen xl {
    right: 0rem;
  }
}

h2 {
  @apply text-xl font-semi-medium mt-8;

  @screen sm {
    @apply text-7 mt-10;
  }

  @screen md {
    @apply text-2xl;
  }
  @screen lg {
    @apply text-10 mt-12;
  }
}

.mena-h2-typography {
  h2 {
    @apply font-suisse-works font-semi-medium text-2xl;

    line-height: 40px;
    color: #ed8f0a !important;
  }
}