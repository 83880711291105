.about-section {
  @screen md {
    display: flex;
    flex-wrap: wrap;
  }

  &::after {
    @apply bg-primary;

    content: "";
    display: block;
    order: 2;
    width: 100%;
    height: 1px;
    margin: 3rem auto;
    transition: width $trns-line;

    @screen lg {
      margin: 4rem auto;
    }
  }

  &.aos-init {
    &::after {
      width: 1%; // needed so it has dimensions for the dom so the nav scroll can work
    }
  }

  &.aos-animate {
    &::after {
      width: 100%;
    }
  }

  &:last-child {
    &::after {
      display: none;
    }
  }

  &:nth-child(odd) {
    .about-section-col {
      &:first-child {
        @screen md {
          order: 1;
          padding-left: .75rem;
        }

        @screen lg {
          padding-left: 2.75rem;
        }
      }

      &:last-child {
        @screen md {
          padding-right: .75rem;
        }

        @screen lg {
          padding-right: 2.75rem;
        }
      }
    }
  }

  &:nth-child(even) {
    .about-section-col {
      &:first-child {
        @screen md {
          padding-right: .75rem;
        }

        @screen lg {
          padding-right: 2.75rem;
        }
      }

      &:last-child {
        @screen md {
          padding-left: .75rem;
        }

        @screen lg {
          padding-left: 2.75rem;
        }
      }
    }
  }

  p {
    @apply text-sm font-medium leading-relaxed;

    margin-top: .75rem;
    margin-bottom: 1rem;

    @screen lg {
      @apply text-lg;

      margin-top: 1rem;
      margin-bottom: 1.5rem;
    }
  }
}

.about-section-intro {
  @screen md {
    column-gap: 5rem;
    column-count: 2;
  }
}

.about-section-col {
  @screen md {
    width: 50%;
  }

  &:first-child {
    margin-bottom: 1rem;

    @screen md {
      margin-bottom: 0;
    }
  }

  img {
    width: 100%;
  }

  h3,
  h4 {
    @apply font-bold;
  }

  h3 {
    @apply text-xl;
    text-transform: uppercase;

    @screen lg {
      @apply text-10;
    }
  }

  h4 {
    @apply text-lg;

    @screen lg {
      @apply text-7;
    }
  }
}
