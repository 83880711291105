// JDcorps header styles goes in here
.fb-frame {
  > iframe {
    width: 100%;
    height: 350px;

    @screen md {
      width: 350px;
      height: 400px;
    }

    @screen lg {
      width: 470px;
      height: 500px;
    }
  }
}
