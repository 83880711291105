.btn {
  @apply rounded-7
    bg-transparent
    text-primary text-lg font-medium leading-none tracking-wide;

  display: inline-block;
  height: 48px;
  padding: 2px 28px 0;
  transition: color $trns, background-color $trns, box-shadow $trns;
  border: 0;
  text-align: center;
  text-transform: uppercase;
  vertical-align: middle;
  user-select: none;

  &:hover {
    text-decoration: none;
  }

  &:focus {
    @apply shadow-btn;
    outline: none;
  }

  &:not(:disabled) {
    cursor: pointer;
  }

  &:not(button) {
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }

  &[disabled] {
    cursor: not-allowed;
    opacity: 0.6;
  }
}

.btn-primary {
  @apply bg-primary text-white;

  &:hover {
    @apply bg-primary-hover;
  }

  &:focus {
    @apply shadow-btn-primary;
  }
}

.btn-outline-primary {
  @apply border border-solid border-primary bg-transparent text-primary;

  &:hover {
    @apply bg-primary text-white;
  }

  &:focus {
    @apply shadow-btn-primary;
  }

  &.light {
    border: 1px solid #295BA5;
    color: #295BA5;
    @apply text-sm font-600;

    svg {
      fill: #295BA5;
    }

    &:hover {
      @apply text-white;
      background: #295BA5;

      svg {
        fill: white;
      }
    }
  }
}

.btn-secondary {
  @apply bg-secondary text-primary;

  &:hover {
    @apply bg-secondary-hover;
  }

  &:focus {
    @apply shadow-btn-secondary;
  }
}

.btn-tertiary {
  @apply bg-blue text-white;

  &:hover {
    @apply bg-blue-hover;
  }

  &:focus {
    @apply shadow-btn-tertiary;
  }
}

.btn-white {
  @apply bg-white text-primary;

  &:hover {
    @apply bg-white-hover;
  }

  &:focus {
    @apply shadow-input-white;
  }
}

.btn-outline-white {
  @apply border border-solid border-white bg-transparent text-white;

  &:hover {
    @apply bg-white text-primary;
  }

  &:focus {
    @apply shadow-input-white;
  }
}

.btn-link {
  @apply rounded-none text-base leading-normal;

  position: relative;
  height: auto;
  padding: 0;

  &.btn-sm,
  &.btn-lg {
    @apply rounded-none;
    height: auto;
    padding: 0;
  }

  @screen md {
    @apply text-lg;
  }

  &:hover::after {
    right: 100%;
  }

  &::after {
    content: '';
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    height: 1px;
    transition: right $trns;
    background-color: currentColor;
  }

  &.-invert-animation {
    &:hover::after,
    &.active::after {
      right: 0;
    }

    &::after {
      right: 100%;
    }
  }
}

.btn-sm {
  @apply rounded-lg text-xs;
  height: 24px;
  padding-right: 12px;
  padding-left: 12px;
}

.btn-md {
  @apply rounded-7 text-xs;

  height: 24px;
  padding-right: 12px;
  padding-left: 12px;

  @screen md {
    @apply rounded-7 text-base;

    height: 32px;
    padding-right: 24px;
    padding-left: 24px;
  }

  @screen xl {
    @apply text-lg;
    padding-right: 30px;
    padding-left: 30px;
  }
}

.btn-lg {
  @apply rounded-8 text-lg;
  height: 64px;
  padding-right: 53px;
  padding-left: 53px;

  @screen md {
    @apply text-xl;
  }
}

@media screen and (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}

.btn-facebook {
  background-color: #517db1;
  @apply text-white px-10 font-medium text-lg;
  height: 54px;

  @screen md {
    font-size: 20px;
  }

  &:hover,
  &:active {
    background-color: #38577b;
  }
}
