.other-ways-to-donate {
  .inner-hero-img {
    position: absolute;
    bottom: 0;
    opacity: 1;
  }

  .inner-hero-container {
    min-height: 290px;
    padding-bottom: 0;
  }

  .other-ways-to-donate-subtitle {
    @apply text-white text-lg text-left tracking-wide;
    @apply font-semi-medium whitespace-pre-line;

    line-height: 32px;
  }

  .other-ways-to-donate-title {
    @apply text-white text-opacity-80 text-xl;
    @apply  text-left;

    @screen sm {
      @apply text-2xl;
    }

    @screen lg {
      @apply text-3xl;
    }
  }
}

.other-ways-to-donate-item {
  position: relative;
  top: 0;
  transition: top ease 0.2s;

  &:hover {
    top: -1rem;
  }
}

.other-ways-to-donate-picture-size {
  @screen lg {
    width: 358px;
  }
}

.other-ways-to-donate-size-between-column{
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
}

.other-ways-to-donate-picture-width {
   height: 268px;
}
