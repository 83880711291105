body {
  @apply bg-white font-primary text-primary text-base font-normal leading-normal;

  @screen sm {
    @apply text-lg;
  }
}

// the numbers below calculates the height of top banner xs:64, sm:50, md:100
.app-wrapper-countdown {
  padding-top: 64px;

  @screen sm {
    padding-top: 50px;
  }

  @screen md {
    padding-top: 100px;
  }

  .header {
    top: 64px;

    @screen sm {
      top: 50px;
    }

    @screen md {
      top: 100px;
    }
  }

  .navigation {
    top: 195px;
    height: calc(100% - 195px);

    @screen sm {
      top: 146px;
      height: calc(100% - 146px);
    }

    @screen md {
      top: 222px;
      height: calc(100% - 222px);
    }

    @screen lg {
      top: auto;
      height: auto;
    }
  }

  .inner-hero-img {
    top: 64px;

    @screen sm {
      top: 50px;
    }

    @screen md {
      top: 100px;
    }
  }

  .issues-section {
    @screen lg {
      min-height: calc(100vh - 100px);
    }
  }

  .page-not-found {
    min-height: calc(100vh - 64px);

    @screen sm {
      min-height: calc(100vh - 50px);
    }

    @screen md {
      min-height: calc(100vh - 100px);
    }
  }
}

img {
  vertical-align: top;
}

a,
button {
  -webkit-tap-highlight-color: theme('colors.black-a0');
}

.container {
  @apply px-gutter-1/2;

  @screen md {
    @apply px-gutter;
  }

  @screen xxl {
    max-width: 1200px;
  }
}

.container-half {
  @apply mx-auto px-gutter-1/2;
  width: 100%;

  @screen sm {
    max-width: theme('screens.sm');
  }

  @screen md {
    @apply px-gutter;
    max-width: theme('screens.md');
  }

  @screen lg {
    @apply mx-0;
    max-width: 496px;
  }

  @screen xl {
    max-width: 600px;
  }

  &.-left {
    @screen lg {
      align-self: flex-end;
      padding-right: 0;
    }
  }

  &.-right {
    @screen lg {
      padding-left: 0;
    }
  }
}

.triangle-bg {
  position: relative;
  overflow: hidden;

  &::before,
  &::after {
    content: '';
    position: absolute;
    padding-top: 65%;
    background-repeat: no-repeat;
    background-size: 100% 100%;
  }

  &.-square {
    &::before,
    &::after {
      @screen md {
        padding-top: 100%;
      }
    }
  }

  > .container {
    position: relative;
    z-index: 1;
  }
}

.triangle-bg-top-right {
  &::before {
    top: 0;
    right: 0;
    left: 50%;
    background-image: linear-gradient(
      to bottom left,
      theme('colors.gray-300') 50%,
      theme('colors.transparent') 50%
    );
  }

  &.-square {
    &::before {
      @screen md {
        left: 0;
      }
    }
  }

  &.-blue {
    &::before {
      background-image: linear-gradient(
        to bottom left,
        theme('colors.blue-a6') 50%,
        theme('colors.transparent') 50%
      );
    }
  }
}

.triangle-bg-top-left {
  &::before {
    top: 0;
    right: 50%;
    left: 0;
    background-image: linear-gradient(
      to bottom right,
      theme('colors.gray-300') 50%,
      theme('colors.transparent') 50%
    );
  }

  &.-square {
    &::before {
      @screen md {
        right: 0;
      }
    }
  }

  &.-top-left-alpha {
    &::before {
      background-image: linear-gradient(
        to bottom right,
        theme('colors.gray-300-a1') 50%,
        theme('colors.transparent') 50%
      );
    }
  }
}

.triangle-bg-bottom-left {
  &::after {
    right: 50%;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(
      to top right,
      theme('colors.gray-300') 50%,
      theme('colors.transparent') 50%
    );
  }

  &.-square {
    &::after {
      @screen md {
        right: 0;
      }
    }
  }
}

.triangle-bg-bottom-right {
  &::after {
    right: 0;
    bottom: 0;
    left: 50%;
    background-image: linear-gradient(
      to top left,
      theme('colors.gray-300') 50%,
      theme('colors.transparent') 50%
    );
  }

  &.-square {
    &::after {
      @screen md {
        left: 0;
      }
    }
  }

  &.-bottom-right-alpha {
    &::after {
      background-image: linear-gradient(
        to top left,
        theme('colors.gray-300-a1') 50%,
        theme('colors.transparent') 50%
      );
    }
  }
}

.section-title {
  @apply text-2xl;
  text-align: center;

  @screen md {
    @apply text-3xl;
  }

  @screen lg {
    @apply text-4xl;
  }
}

.line-around {
  display: flex;

  &.aos-init {
    span {
      &:first-child,
      &:last-child {
        &::before {
          width: auto;
        }
      }

      &:first-child::before {
        right: 0;
        left: 100%;
      }

      &:last-child::before {
        right: 100%;
        left: 0;
      }
    }
  }

  &.aos-animate {
    span {
      &:first-child::before {
        left: 0;
      }

      &:last-child::before {
        right: 0;
      }
    }
  }

  span {
    &:first-child,
    &:last-child {
      display: block;
      position: relative;
      flex: 1;

      &::before {
        content: '';
        display: block;
        position: absolute;
        top: 50%;
        width: 100%;
        height: 1px;
        transition: right $trns-line, left $trns-line;
        background-color: currentColor;
      }
    }

    &:first-child {
      margin-right: 1rem;

      @screen sm {
        margin-right: 1.25rem;
      }
    }

    &:last-child {
      margin-left: 1rem;

      @screen sm {
        margin-left: 1.25rem;
      }
    }
  }
}

.close-icon {
  display: inline-block;
  position: relative;
  width: 16px;
  height: 16px;
  transition: opacity $trns;
  outline: 0;
  opacity: 1;

  @screen md {
    width: 20px;
    height: 20px;
  }

  &:hover {
    @apply opacity-icon;
  }

  &:focus {
    outline: none;
  }

  &::before,
  &::after {
    @apply bg-white;

    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 22px;
    height: 1px;
    margin-top: -0.5px;
    margin-left: -11px;

    @screen md {
      width: 27px;
      margin-left: -13.5px;
    }
  }

  &::before {
    transform: rotate(45deg);
  }

  &::after {
    transform: rotate(-45deg);
  }
}

.icon-hover {
  display: inline-block;
  transition: opacity $trns;
  opacity: 1;

  &:hover {
    @apply opacity-icon;
  }

  &.reverse {
    @apply opacity-icon;

    &:hover {
      opacity: 1;
    }
  }
}

.padding-hack {
  position: relative;
  overflow: hidden;

  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 100%;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

[v-cloak] {
  display: none !important;
}

.page-not-found {
  display: flex;
  position: relative;
  // align-items: center;
  // justify-content: center;
  // min-height: 100vh;
  padding-top: 100px;
  padding-bottom: 100px;
  // text-align: center;

  // &::before {
  //   @apply bg-white;

  //   content: '';
  //   display: block;
  //   position: absolute;
  //   top: 50%;
  //   left: 50%;
  //   width: 120%;
  //   max-width: 650px;
  //   height: 1px;
  //   transform: rotate(-45deg) translateX(-50%);
  //   transform-origin: left center;
  //   opacity: 0.5;
  // }
}

.page-content-wrapper {
  @apply bg-white;

  position: relative;
  z-index: 1;

  &.no-header {
    padding-top: 56px;

    @screen sm {
      padding-top: 72px;
    }

    @screen md {
      padding-top: 90px;
    }

    @screen lg {
      padding-top: 72px;
    }

    @screen xl {
      padding-top: 90px;
    }
  }
}
