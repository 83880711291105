.speakers-bureau {
  @apply z-10;
  .content {
    p + p {
      @apply mt-8;
    }
  }

  .stat {
    @apply rounded-full;
    @apply bg-primary;
    @apply flex flex-col justify-center items-center;
    border: 2px solid #fae000;
  }

  .stat-number {
    font-weight: 300;
    line-height: 1;
  }

  .stat-label {
    @apply uppercase;
    font-weight: 500;
  }

  .stats-wrap {
    padding-top: 36rem;

    @screen lg {
      padding-top: 0;
    }
  }

  .stat-sm {
    position: absolute;
    width: 5.5rem;
    height: 5.5rem;
    left: -3.9rem;
    top: -1.5rem;
    z-index: -1;

    .stat-number {
      font-size: 1.875rem;
    }
    .stat-label {
      font-size: 0.5625rem;
    }
  }

  .stat-md {
    width: 10.375rem;
    height: 10.375rem;

    .stat-number {
      font-size: 3.75rem;
    }
    .stat-label {
      font-size: 0.875rem;
    }
  }

  .stat-lg {
    width: 22.625rem;
    height: 22.625rem;

    .stat-number {
      font-size: 8.125rem;
    }
    .stat-label {
      font-size: 1.5rem;
    }
  }

  .stat-globe-wrap {
    @apply absolute right-0 bottom-0 h-auto;
    @apply hidden;
    width: 30%;
    max-width: 24rem;

    @screen lg {
      @apply block;
    }

    @screen xxl {
      max-width: 36rem;
    }
  }

  .stat-lg-wrap {
    @apply absolute;
    z-index: 9;

    bottom: 46%;
    right: 22%;

    @screen xl {
      right: 30%;
    }

    @screen xxl {
      right: 42%;
      bottom: 52%;
    }
  }

  .stat-md-wrap {
    @apply absolute;
    right: -2.5rem;
    top: -6.5rem;
    z-index: -1;

    @screen lg {
      right: -4.25rem;
      top: -4.75rem;
    }
  }

  .stats-globe {
    @apply relative z-10 max-w-none w-full;
  }
}

.banner-star {
  @apply absolute bg-gray-darker z-50;
  right: 45%;
  bottom: -10px;

  @screen sm {
    right: 50%;
  }

  @screen lg {
    right: 45%;
  }
}
