html {
  scroll-behavior: smooth;
}

.expectations-grid {
  @apply flex flex-wrap justify-center items-center;

  @screen sm {
    @apply justify-between;
  }

  @screen md {
    @apply justify-center;
  }

  > div {
    height: 320px;
    width: 320px;

    margin-bottom: 30px;

    @screen sm {
      height: 300px;
      width: 250px;
      margin-right: 10px;
    }

    @screen md {
      margin-right: 30px;
      height: 320px;
      width: 320px;
    }

    @screen lg {
      height: 350px;
      width: 350px;
    }
  }
}

.sessions-grid {
  min-height: 200px;
  position: relative;

  @screen md {
    min-height: 325px;
  }
}

.sessions-grid::after {
  content: '';
  background-image: url('../../assets/images/youth_assembly/arrow.svg');
  width: 30px;
  height: 30px;
  right: -30px;
  bottom: 45%;
  position: absolute;
}

.sessions-grid:last-child::after {
  display: none;
}
