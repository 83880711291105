.un_antisemitism {
  .separator {
    border-top: 1px solid #ccd3dc;
    height: 0;
  }

  .stream-section {
    @apply relative;
    top: -2rem;

    @screen md {
      top: -4rem;
    }

    @screen lg {
      top: -7rem;
    }
  }

  .stream-section-content {
    @apply mx-auto mt-12 mb-6;
    @apply text-lg text-gray-dark leading-relaxed;

    @screen md {
      @apply w-2/3;
    }

    figure {
      @extend .embedded-video;
    }
  }

  .speakers {
    .swiper-wrapper {
      @apply items-stretch;
    }
    .swiper-slide {
      @apply h-auto;
    }
    .swiper-nav-btn {
      top: 30%;
    }
  }

  .speaker-bio-details {
    background: #071e3b;
    @apply pt-16 pb-24 mb-8;
    @apply text-white;
    @apply font-medium;
  }

  .speakers-section {
    background: #f4f4f4;
  }
}
