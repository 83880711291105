











































































.elevate-representative-filter-selector {
  @apply flex items-center;
  @apply relative;
  @apply rounded-sm;
  @apply pl-4 py-3;
  @apply text-lg font-medium text-blue-0C2B46;
  background: white;
  @apply font-suisse-intl;

  height: 3.375rem;
  min-width: 16.5rem;

  &:focus,
  &:active {
    outline: none;
  }

  img {
    @apply px-4;
    @apply ml-auto;
  }

  ul {
    @apply absolute;
    @apply w-full text-blue-0C2B46 bg-light;

    top: 3.375rem;
    left: 0;

    button {
      @apply w-full;
      @apply text-lg text-left capitalize;
      @apply px-6 py-3;
      @apply font-suisse-intl text-blue-0C2B46;

      &:hover {
       @apply bg-gray-light;
      }
    }
  }

  .image-rotate {
    @apply transform rotate-180;
  }
}
