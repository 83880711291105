.anniversary-gradient {
  @apply absolute bottom-0 left-0 right-0;
  height: 12rem;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.8) 100%
  );
}

.anniversary-footer {
  background: #edecec;

  p {
    color: #4a5169;
    @apply text-opacity-70;
  }

  .footer-border {
    @apply border-t;
    border-color: #4a5169;
  }
}

.anniversary-main {
  @apply bg-light;

  .theme-content {
    color: #4a5169;
  }

  .up-next-section {
    color: #283965;

    div {
      background-color: #f1f1f1;
      border-top: 4px solid #283965;

      @screen sm {
        width: fit-content;
      }
    }

    h6 {
      @apply text-opacity-70;
      color: #283965;
    }
  }
}

/* The sidebar menu */
.anniversary-sidebar-nav {
  @apply bg-light;
  height: 100%;
  width: 0;
  position: absolute;
  z-index: 1;
  top: 64px;
  left: 0;
  overflow-x: hidden;
  transition: 0.5s;

  .sidebar-link {
    @apply text-sm font-suisse-works w-full no-underline;
    padding: 8px 16px 8px 24px;
    color: #1d2544;
    transition: 0.3s;
  }
}

.sidebar-link h5 {
  @apply font-bold text-sm;
}

.sidebar-link:hover {
  background-color: #28528e;
  color: white;
}

.active-theme {
  background-color: #28528e;
  color: white !important;

  svg {
    visibility: visible;
  }
}

/* Animation when the page content goes on right */
.anniversary-main,
.anniversary-footer {
  transition: margin-left 0.5s;
}

/* Animation for on hamburger button */
.anniversary-side-navigation {
  &.open {
    .toggle-mobile-nav span {
      &:nth-child(1) {
        top: 10px;
        left: 50%;
        width: 0%;
      }

      &:nth-child(2) {
        transform: rotate(45deg);
      }

      &:nth-child(3) {
        transform: rotate(-45deg);
      }

      &:nth-child(4) {
        top: 10px;
        left: 50%;
        width: 0%;
      }
    }
  }
}
