.global-review-gradient {
  @apply absolute bottom-0 left-0 right-0;
  height: 15rem;
  background: linear-gradient(0deg, #02152d 0%, rgba(29, 37, 68, 0) 100%);
}

.global-review-resource {
  background-color: rgba(255, 255, 255, 0.05);
  @apply py-4 px-3;

  &:hover {
    background-color: rgba(255, 255, 255, 0.15);

    h4 {
      @apply text-white text-opacity-100;
    }
    .download-resource-icons {
      span {
        &:first-child {
          transform: scale(0.8);
          opacity: 0;
        }

        &:last-child {
          transform: translateY(0);
          transition-delay: 0.2s;
          opacity: 1;
        }
      }
    }
  }
}

.download-resource-icons {
  @apply relative flex justify-center;
  transition: background-color 200ms ease-in-out;

  span {
    transition: all ease-in-out 200ms;

    &:last-child {
      @apply absolute top-0 right-0 bottom-0 left-0;
      @apply flex items-center justify-center;

      transform: translateY(-20%);
      opacity: 0;
      transition-delay: 0;
    }
  }
}
