





























































































































































































































































































































































































































































$calendarBackground: lighten(white, 40%);
$selectedColor: #c5dfff;
$hoverColor: lighten(steelblue, 40%);
$arrowHoverColor: steelblue;
$weekColor: #b8b9ba;
$dateBorderRadius: 0;

.other-month {
  color: gray;
  opacity: 0;
}

.arrow {
  cursor: pointer;
  width: 1.5em;
}

.arrow:hover {
  color: $arrowHoverColor;
  font-weight: 400;
}

.year-selector {
  @apply flex ml-auto;

  width: fit-content;
}

.year,
.month {
  height: 1.2em;
}

.left {
  float: left;
}

.right {
  float: right;
}

.center {
  text-align: center;
  clear: both;
}

table {
  clear: both;
}

table .week td {
  @apply font-primary;
  color: $weekColor;
}

table td {
  width: 32px;
  height: 32px;
  vertical-align: middle;
  text-align: center;
}

tbody td {
  cursor: pointer;
  border-radius: $dateBorderRadius;
}

tbody td.selected {
  @apply rounded-full;
  background: $selectedColor !important;
}

tbody td.selected:hover {
  @apply rounded-full;
  background: $selectedColor;
}

tbody td.hover {
  @apply rounded-full;
  background: lighten($selectedColor, 10%);
}

tbody td:hover {
  @apply rounded-full;

  background-color: $hoverColor;
}

.calendar {
  @apply pt-4;

  z-index: 1;
  background-color: $calendarBackground;
  border-radius: 5px;
  padding: 0.5em 0.2em;
  position: relative;
}

.calendar:before {
  content: '';
  width: 0;
  height: 0;
  border: solid transparent 8px;
  border-bottom: solid $calendarBackground 8px;
}

[v-cloak] {
  display: none;
}

.month-select-element {
  position: relative;
  z-index: 1000000;

  &:hover {
    cursor: pointer;
  }
}

.input-container {
  max-width: 270px;

  @screen lg {
    max-width: 290px;
  }
}

.main-container {
  @apply absolute;
}

.dropdown-option {
  &:hover {
    @apply underline;
    cursor: pointer;
  }
}
