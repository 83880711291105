.irgc-page-map-container {
  @apply bg-black text-white;

  min-height: 100px;

  @screen lg {
    min-height: 800px;
  }

  @screen xl {
    min-height: 900px;
  }

  @screen xxl {
    min-height: 950px;
  }
}

.irgc-map {
  padding-top: 11rem;
  width: 100%;
  height: 300px;
  background-repeat: no-repeat;
  background-position: center;
  background-image: none;

  @screen md {
    height: 400px;
  }

  .container-half.-right {
    @screen lg {
      @apply pl-gutter;
    }

    @screen xl {
      padding-left: 2.5rem;
    }
  }

  .btn {
    width: 100%;

    @screen sm {
      width: auto;
    }
  }
}

.irgc-page-map-office-info {
  @apply text-sm;

  position: relative;
  z-index: 10;
  width: 100%;
  margin-bottom: 2rem;
  text-shadow: 0 2px 4px theme('colors.black-a7');

  @screen sm {
    @apply px-gutter-1/2;
    width: 50%;
  }

  @screen md {
    @apply px-gutter text-lg;
    margin-bottom: 3rem;
  }

  @screen lg {
    width: 100%;
    margin-top: auto;
    text-shadow: none;
  }

  @screen xl {
    padding-left: 2.5rem;
  }

  &.active {
    display: block;
  }

  a {
    @apply text-white;

    &:hover {
      text-decoration: underline;
    }
  }
}

.islamic-revolutionary-page-map {
  @apply hidden;
  max-width: 1800px;

  @screen lg {
    @apply block relative w-full mx-auto;

    background: black;

    .igrc-map-svg {
      @apply mx-auto;
      width: 100%;
    }
  }
}

.igrc-page-mark {
  @apply bg-white;

  display: none;
  position: absolute;
  width: 12px;
  height: 12px;
  transition: background-color $trns;
  border-radius: 50%;
  cursor: pointer;
  box-shadow: 0 0 0px 6px rgba(255, 255, 255, 0.5);

  @screen lg {
    display: block;
  }

  &.hoverable-igrc-mark {
    &:hover {
      width: 17px;
      height: 17px;
    }
  }

  &:focus {
    outline: none;
  }

  &.active {
    width: 17px;
    height: 17px;
  }
}
