.search-trigger {
  @apply outline-none;
  @apply relative;

  flex-shrink: 0;
  width: 24px;
  height: 24px;
  z-index: -1;

  &:hover,
  &:focus {
    @apply opacity-icon outline-none;
  }

  img {
    display: inline-block;
    width: 16px;
    height: 16px;
    vertical-align: middle;

    @screen md {
      width: 24px;
      height: 24px;
    }

    @screen xl {
      width: 18px;
      height: 18px;
    }
  }
}

.search-trigger-arrow {
  @apply absolute;
  margin-top: 2px;
}

.tooltip {
  display: block !important;
  z-index: 10000;

  .tooltip-arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 5px;
    border-color: black;
    z-index: 1;
  }

  &[x-placement^='bottom'] {
    margin-top: 5px;

    .tooltip-arrow {
      border-width: 0 13px 14px 13px;
      border-left-color: transparent !important;
      border-right-color: transparent !important;
      border-top-color: transparent !important;
      top: -5px;
      left: calc(50% - 5px);
      margin-top: 0;
      margin-bottom: 0;
    }
  }

  &.popover {
    $color: #f9f9f9;

    .popover-arrow {
      border-color: $color;
    }
  }

  &[aria-hidden='true'] {
    visibility: hidden;
    opacity: 0;
    transition: opacity 0.15s, visibility 0.15s;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    opacity: 1;
    transition: opacity 0.15s;
  }
}

// Used in the footer and on a video page
.search-form {
  display: flex;
  align-items: center;
  width: 100%;

  @screen sm {
    position: absolute;
    z-index: 10;
    top: 50%;
    right: 0;
    left: 100%;
    width: auto;
    height: 32px;
    margin-top: -16px;
    overflow: hidden;
    transition: left $trns-dur-search $trns-easing;
  }

  &.open {
    left: 0;
    position: fixed;
    top: 63px;
    background: white;
    padding: 35px;
  }

  input {
    @apply bg-transparent text-black;

    flex: 1;
    height: 100%;
    padding: 0 1rem;

    &:focus {
      @apply border-transparent outline-none;
    }

    &::placeholder {
      @apply text-black;
      opacity: 1;
    }
  }
}