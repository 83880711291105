.israel-101-description-size {
  font-size: 32px;
  line-height: 40px;
}

.button-outline {
  font-size: 14px;
  margin-left: 149px;
}

