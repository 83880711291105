.unoct-stream-section {
  position: relative;
  bottom: -1rem;
}

.unoct-banner {
  svg {
    width: 100%;
  }

  @screen md {
    svg {
      width: auto;
    }
  }
}

.unoct-sponsors-grid {
  @apply grid items-center gap-10;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: minmax(100px, auto);

  @screen md {
    @apply w-2/3 m-auto gap-16;
  }

  div {
    &:first-child {
      grid-column: 1/ 3;
      grid-row: 1;
    }
  }
}
