.home-issue-item {
  @apply text-white;

  display: flex;
  position: relative;
  flex-direction: column;
  height: 100%;

  &:hover {
    h5,
    .summary,
    a {
      opacity: 1;
    }
  }

  h5 {
    margin-bottom: 0.5rem;
    transition: opacity $trns;
    opacity: 0.7;
    text-transform: uppercase;

    @screen sm {
      min-height: 50px;
    }
  }

  .summary {
    @apply text-sm;

    margin-bottom: 0.25rem;
    transition: opacity $trns;
    opacity: 0.4;

    @screen sm {
      min-height: 60px;
    }

    @screen lg {
      min-height: 40px;
    }
  }

  a {
    @apply text-sm font-bold;

    display: flex;
    align-items: center;
    transition: opacity $trns;
    text-transform: uppercase;
    opacity: 0.4;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }

    span {
      margin-right: 0.75rem;
    }
  }
}
