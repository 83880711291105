.conspiracy-myths-site {
  @apply relative;

  .dropdown-navigation {
    @apply hidden;

    @screen md {
      @apply block;
    }
  }

  .mobile-dropdown-navigation {
    @apply block;
    @screen md {
      @apply hidden;
    }
  }

  .mobile-dropdown-navigation-content {
    @apply hidden absolute z-10;
    background-color: #f18f6d;
    transform-origin: top center;
    animation: slideDown 300ms ease-in-out forwards;

    @keyframes slideDown {
      0% {
        opacity: 0;
        transform: translateY(-60px);
      }

      100% {
        opacity: 1;
        transform: translateY(0);
      }
    }
  }

  .mobile-dropdown-navigation-content.show {
    @apply flex flex-col w-full;
  }
}

.conspiracy-footer-socials {
  display: flex;
  justify-content: center;

  a {
    transition: opacity $trns;
    opacity: 0.5;
    flex-shrink: 0;

    &:hover {
      opacity: 1;
    }

    margin-top: 20px;

    //above md screen
    @screen md {
      margin-top: 0px;
    }

    &:not(:first-child) {
      margin-left: 20px;
    }
  }
}

//learn more section
.learn-more-section {
  padding: 40px 15px;
  @screen sm {
    padding: 60px 30px 80px;
  }
  background-color: #f1ebeb;
}

.centered-heading {
  margin-top: 0px;
  margin-bottom: 24px;
  @screen sm {
    margin-bottom: 60px;
  }
  line-height: 56px;
  text-align: center;
  font-weight: bold;
  color: #333;
}

.link-block {
  width: 100%;
  text-decoration: none;
}

.link-block img {
  width: 100%;
  display: inline-block;
  max-width: 100%;
  margin-top: 0px;
  margin-bottom: 16px;
}

.w-inline-block {
  max-width: 100%;
  display: inline-block;
}

.image-16 {
  width: 100%;
}

.div-block {
  margin-top: 12px;
  padding-top: 0px;
  padding-left: 0px;
  margin-bottom: 38px;
  @screen md {
    margin-bottom: 8px;
  }
}

.text-block-6 {
  font-size: 24px;
  line-height: 32px;
  font-weight: 700;
}

.footer-wrapper {
  background-color: #f1ebeb;
  padding: 40px 30px;
}

.footer-container {
  width: 100%;
  max-width: 728px;
  margin-right: auto;
  margin-left: auto;
  @screen md {
    max-width: 1140px;
  }
}

.conspiracy-footer-logo {
  opacity: 0.2;
  flex-shrink: 0;
  display: inline-block;
  margin-bottom: 16px;

  @screen md {
    margin-bottom: 0px;
  }
}

.conspiracy-introduction {
  p {
    opacity: 80%;
    color: #333333;
    @apply text-base leading-relaxed;

    @screen md {
      font-size: 20px;
      @apply leading-loose;
    }
  }

  .embedded-video {
    @screen lg {
      padding-bottom: 90%;
    }

    @screen xl {
      padding-bottom: 75%;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    @apply font-semi-bold text-black;
  }
}

.btn-conspiracy-myths {
  padding: 15px 20px;
  background-color: #f8c0ad;
  transition: background-color 200ms ease;
  @apply font-suisse-works text-black normal-case font-medium text-base rounded-none;

  &:hover {
    background-color: #f18f6d;
  }
}

.listed-myth {
  .listed-myth-description {
    @apply hidden;

    @screen lg {
      @apply block;
    }
  }

  .listed-myth-description-reversed {
    @screen lg {
      @apply hidden;
    }
  }
}

.listed-myth.-reverse {
  .listed-myth-description {
    @apply hidden;
  }

  .listed-myth-description-reversed {
    @apply block;
  }
}

.myths-hero-logo {
  display: block;
  flex-shrink: 0;
  transition: opacity $trns;
  opacity: 1;

  &:hover {
    @apply opacity-icon;
  }
}

.iframe-style {
  .iframe-block {
    margin-bottom: 40px;
  }
}

.anti-zionism-body {
  @apply font-suisse-works mt-12;

  p {
    @apply text-base leading-relaxed text-black;

    @screen md {
      font-size: 20px;
      @apply leading-loose;
    }
  }

  h1,
  h2,
  h3,
  h4,
  h5 {
    @apply font-bold;
    color: #333333;
  }
}
