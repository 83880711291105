.next-gen-impact {
  .number {
    color: #2559a8;
    font-size: 80px;

    @media (min-width: 1200px) {
      font-size: 80px;
    }
  }

  .show-more-member-link {
    @apply p-gutter-1/2;

    display: block;
    transform: translateY(0);
    transition: transform $trns, box-shadow $trns;

    &:hover {
      @apply shadow-lg-hover;
      transform: translateY(-1rem);
    }

    img {
      transition: filter $trns;
      margin-bottom: 0.5rem;
    }
  }
}

.blogpost-button-position {
  @screen xl {
    top: 90%;
  }
  top: 85%;
  width: 64px;
  height: 64px;
}

.next-inc-grid-icon {
  width: 260px;
  height: 260px;


  @screen md {
    width: 320px;
    height: 320px;
    }

    @screen lg {
      width: 340px;
      height: 340px;
    }
}

.next-gen-tag {
  @apply flex px-4 py-1 text-lg font-bold rounded-xl;

  width: fit-content;
  color: #F9F9F4;
  border-radius: 40px;
  background: #1D4678;
}