




































































































































































.item {
  @apply flex flex-col items-center;
  padding: 0.5rem;
}

.digit-group {
  display: flex;
}

.digit-box {
  border-radius: 3.2px;
  background: #fff;
  box-shadow: 0px 2px 3px 0px rgba(0, 0, 0, 0.5);
  color: #08284f;
  font-family: "Suisse Int'l", sans-serif;
  font-size: 25.6px;
  font-style: normal;
  font-weight: 600;
  line-height: 30.4px; /* 118.75% */
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;

  &:not(:last-child) {
    margin-right: 0.25rem;
  }
}

.format {
  margin-top: 5px;
  color: white;
  text-align: center;
  font-size: 6.4px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
  opacity: 0.6;
}

.label {
  color: var(--Cream, #f9f9f4);
  text-align: center;
  font-family: "Suisse Int'l", sans-serif;
  font-size: 6.4px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  text-transform: uppercase;
}
