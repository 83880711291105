.ec-zagreb-bg-image {
  background-size: 45%;
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 100px;
}

.ec-zagreb-bg-color {
  background-color: #1c2b59;
}

.ec-zagreb {
  .intro-section {
    max-width: 560px;
  }
}

.ec-zagreb-slide {
  width: 166px;
  height: 93px;

  button {
    margin-bottom: 5px;
    width: 166px;
    height: 93px;
    outline: none;

    &:active {
      @apply border-white border-8;
      outline: none;
    }
  }
}

.ec-zagreb-video {
  @apply w-full h-full relative;

  iframe {
    width: 100%;
    height: 100vh;
    max-width: 1150px;
    max-height: 574px;
  }
}

.disabled_swiper_button_ec_zagreb {
  @apply opacity-50;
}

.custom-size-h3 {
  font-size: 40px !important;
  margin-left: -8px;
  border-left: solid 8px #295ba5;
}

.mini-swiper-img {
  width: 340px;
  @screen sm {
    width: 362px;
  }
}

.modal-image-mini-swiper {
  .mini-swiper-img {
    width: unset !important;
    height: unset !important;
  }
}

.align-buttons-ec-zagreb-page {
  .swiper-button-prev {
    left: 12px;
  }

  .swiper-button-next {
    right: 12px;
  }
}

.ec-zagreb-content {
  @apply font-suisse-works;

  &.zagreb-content-header {
    b {
      font-weight: 700;
      color: #1c2b59;
    }
  }

  &.zagreb-content-indent {
    b {
      font-weight: 700;
      color: #1c2b59;
    }
  }

  .ec-zagreb-content-lower {
    p {
      @apply opacity-80;
    }
  }

  .btn {
    @apply text-xl font-primary uppercase font-medium;

    background-color: #1c2b59;
    color: white;
  }
}
