.lawfare-report-btn {
  @apply uppercase flex w-full ;
  @apply font-semi-bold justify-around text-white bg-red-900;
  @apply py-8 px-12 items-center mt-8  mb-10 text-xl;
  @apply no-underline  cursor-pointer font-suisse-intl;


  &:hover {
    @apply underline;
  }
}

.lawfare-body {
  @apply text-sm  font-semi-medium;
  @apply text-blue-0C2B46 font-suisse-intl pretty-content;

  :first-child {
    @apply mt-0;
  }

  @screen lg {
    @apply text-lg;
  }

  &.-form {
    input[type='submit'] {
      @apply w-full bg-red-900;
    }

    .form-label {
      @apply text-lg font-suisse-intl font-medium capitalize;
    }

    .forms-field-description {
      @apply text-lg font-semi-medium font-suisse-intl;

      color: #061D37;
    }

    input[type='file'] {
      &::-webkit-file-upload-button {
        visibility: hidden;
      }

      &::before {
        @apply inline-block outline-none whitespace-nowrap cursor-pointer;
        @apply font-medium text-sm;

        content: 'Choose file';
        background: #E3E8F3;
        border-radius: 2px 0px 0px 2px;
        padding: 10px 16px;
        -webkit-user-select: none;
        color: #0F5DA9;
        background: rgba(15, 93, 169, 0.2);

      }

      &::before {
        background: rgba(15, 93, 169, 0.2);
      }
    }
  }
}

.lawfare-services {
  @apply font-semi-medium text-blue-0C2B46;
  @apply font-suisse-intl pretty-content py-10 leading-8;

  :first-child {
    @apply mt-0;
  }

  h2 {
    @apply text-xl font-600;
  }

  p {
    @apply my-8;
  }
}



.lawfare-title {
  @apply text-white text-2xl font-600 mb-2 font-suisse-intl;

  @screen xl {
    @apply text-4xxl;
  }
}

.lawfare-subtitle {
  @apply text-white text-2xl font-semi-medium mb-10 font-suisse-intl;

  @screen lg {
    font-size: 40px;
  }
}

.lawfare-form-text-transform {
  .form-label:first-letter {
    text-transform: uppercase;
  }

  .form-label {
    text-transform: lowercase!important
  }
}
