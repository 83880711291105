.secca-hero {
  .assembly-logo {
    max-height: 100px;

    @screen lg {
      min-height: 170px;
    }
  }

  img {
    object-position: 30% 30%;

    @screen sm {
      object-position: center;
    }
  }
}
