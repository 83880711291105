.facts-categories-wrapper {
  margin-bottom: 2rem;
  background: linear-gradient(
    to bottom,
    theme("colors.primary") 0%,
    theme("colors.primary") 58px,
    theme("colors.white") 58px,
    theme("colors.white") 100%
  );

  @screen sm {
    background: linear-gradient(
    to bottom,
    theme("colors.primary") 0%,
    theme("colors.primary") 70px,
    theme("colors.white") 70px,
    theme("colors.white") 100%
  );
  }

  @screen md {
    background: linear-gradient(
      to bottom,
      theme("colors.primary") 0%,
      theme("colors.primary") 80px,
      theme("colors.white") 80px,
      theme("colors.white") 100%
    );
  }

  ul {
    @apply -mx-gutter-1/2;
    overflow-x: scroll;

    @screen sm {
      margin: 0;
    }
  }

  li {
    padding: 0 .5rem;

    @screen sm {
      padding: 0;
    }

    &:not(:last-child) {
      @screen xl {
        margin-right: 7.75rem;
      }
    }
  }
}

.facts-category-link {
  @apply text-dark-a7 font-bold;

  display: block;
  width: 100px;
  transition: color $trns;
  font-size: .625rem;
  text-align: center;
  text-transform: uppercase;

  @screen sm {
    @apply text-xs;
    width: 120px;
  }

  @screen md {
    width: 140px;
  }

  &:focus {
    text-decoration: none;
  }

  &:hover,
  &.active {
    @apply text-blue;

    svg {
      .facts-icon-stroke {
        stroke: theme("colors.blue");
      }

      .facts-icon-fill {
        fill: theme("colors.blue");
      }
    }
  }

  svg {
    display: block;
    max-width: 100%;
    height: auto;
  }

  span {
    @apply bg-white;

    display: block;
    padding: .5rem 1rem 0;
  }

  .facts-icon-bg {
    transition: fill $trns;
  }

  .facts-icon-stroke {
    transition: stroke $trns;
  }

  .facts-icon-fill {
    transition: fill $trns;
  }

  .facts-icon-opacity {
    transition: opacity $trns;
  }

  .facts-icon-stroke.facts-icon-fill {
    transition: fill $trns, stroke $trns;
  }
}

.fact-box {
  @apply rounded shadow-md;

  display: flex;
  flex-direction: column;
  height: 100%;
  transition: transform $trns;

  &:hover {
    transform: translateY(-10px);

    .overlay {
      opacity: 1;
    }

    img {
      opacity: .2;
    }
  }

  .image {
    @apply rounded-t bg-dark;

    position: relative;
    overflow: hidden;

    &::before {
      padding-top: 83.333333333333333%;
    }
  }

  img {
    width: 100%;
    transition: opacity $trns;
    opacity: 1;
  }

  .overlay {
    @apply text-white;

    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: opacity $trns;
    opacity: 0;
  }

  .btn {
    height: 32px;

    &:hover {
      svg {
        @apply fill-primary;
      }

      .triangle::after {
        border-color: transparent transparent transparent theme("colors.primary");
      }
    }

    svg {
      @apply fill-white;
      transition: fill $trns;
    }
  }

  .triangle {
    width: 12px;
    height: 12px;

    &::after {
      border-width: 5px 0 5px 8.7px;
      border-color: transparent transparent transparent theme("colors.white");
    }
  }

  .annotation {
    @apply rounded-b bg-white text-primary text-sm font-medium;

    flex: 1;
    padding: 1.5rem 1.25rem;

    a {
      text-decoration: underline;
    }
  }
}

.fact-modal {
  @apply pt-16 pb-12 px-gutter-1/2 bg-white-a96;

  position: fixed;
  z-index: 1050;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow: auto;

  @screen md {
    @apply px-gutter;
  }

  .close-icon {
    position: absolute;
    z-index: 1;
    top: 16px;
    right: 16px;
    width: 32px;
    height: 32px;

    @screen lg {
      top: 32px;
      right: 32px;
    }

    &::before,
    &::after {
      @apply bg-primary;

      width: 38px;
      margin-left: -19px;
    }
  }
}

.fact-modal-content {
  width: 100%;
  max-width: 750px;
  margin: 0 auto;

  .media {
    margin-bottom: 1.5rem;
    box-shadow: 0 1px 2px theme("colors.black-a2");

    &.video {
      position: relative;
      height: 0;
      padding-bottom: 56.25%;
      overflow: hidden;
    }
  }

  img {
    width: 100%;
  }

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }

  .annotation {
    @apply text-primary text-base font-bold;

    a {
      text-decoration: underline;
    }
  }
}

.fact-share-btn {
  @apply rounded-sm;

  display: inline-flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  height: 36px;
  transition: opacity $trns;

  @screen sm {
    max-width: 160px;
  }

  &:not(:last-child) {
    margin-right: 1rem;
  }

  &:hover {
    opacity: .6;
  }

  &.facebook {
    @apply bg-facebook;
  }

  &.twitter {
    @apply bg-twitter;
  }

  &.mail {
    @apply bg-mail;
  }
}
