.we-remember-journeys {

  .hero-logo-title {
    color: #F9F871;
    font-family: Avenir Next Condensed;
  }

  .school-participation-subtitle {
    color: #212530B2;
  }

  .project-activities-width {
    width: 264px;
  }

  .about-section-width {
    width: 63%;
  }

  .circle {
    border-radius: 50%;
    text-align: center;

    width: 44px;
    height: 44px;
    line-height: 45px;

    @screen lg {
      width: 64px;
      height: 64px;
      line-height: 65px;
    }
  }

  .form-styles {
    @apply text-sm font-semi-medium;
    @apply text-black font-suisse-intl pretty-content;

    @screen lg {
      @apply text-lg;
    }

    &.-form {
      input[type='submit'] {
        background-color: #F9F871;
        @apply w-full;
        @apply text-black;
      }

      .form-label {
        @apply text-lg font-suisse-intl font-medium capitalize text-black;
      }

      .forms-field-description {
        @apply text-lg font-medium font-suisse-intl;
        color: black;
      }

      input[type='file'] {
        &::-webkit-file-upload-button {
          visibility: hidden;
        }

        &::before {
          @apply inline-block outline-none whitespace-nowrap cursor-pointer;
          @apply font-medium text-sm;

          content: 'Choose file';
          background: #E3E8F3;
          border-radius: 2px 0px 0px 2px;
          padding: 10px 16px;
          color: #0F5DA9;
          background: rgba(15, 93, 169, 0.2);
        }

        &::before {
          background: rgba(15, 93, 169, 0.2);
        }
      }

      .forms-form, .forms-success-message {
        padding-top: 1rem !important;
      }
    }
  }
}

.journey-dot-bg {
  @screen lg {
    background-image: url('../images/we_remember_journeys/dots-short.svg');
    background-repeat: repeat-y;
    background-position-x: 130%;
  }

  @screen xl {
    background-position-x: 100%;
  }

}

.journeys-download-documents {
  .journeys-document-text {
    background-color: white;
    opacity: 0.8;
  }

  .journeys-document-footer {
    background-color: rgba(255,255,255, 0.8);
  }

  .download-documents-button {
    background-color: #0F5DA9;
    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 24px; /* 150% */
    text-transform: capitalize;
    border-radius: none;
  }

  &:hover {
    background-color: white;
    opacity: 1;
    transition: all ease-in-out 200ms;

    .journeys-document-text {
      background-color: white;
      opacity: 1;
    }

    .journeys-document-footer {
      background-color: white;
      opacity: 1;
    }

    .download-documents-button  {
      color: white;
      background-color: #212530;
      transition: all;
    }
  }
}

