.universities {
  h3,
  h4,
  h5 {
    line-height: 40px;
    font-weight: 600;
    color: #08284f;
  }
  h4,
  h5 {
    margin-bottom: 8px;
  }
  p {
    margin-bottom: 32px;
    @apply font-suisse-works;
  }
}
