.accordion-item {
  @apply border-b border-gray-light;

  &.active {
    .accordion-item-title {
      &::before {
        transform: rotate(180deg);
      }
    }

    .accordion-item-content {
      max-height: 500px;
      padding-top: 1rem;
      padding-bottom: 1rem;
      overflow-y: auto;
    }
  }
}

.accordion-item-title {
  @apply text-base;

  display: flex;
  align-items: center;
  padding: .75rem 0;
  cursor: pointer;

  @screen md {
    @apply text-xl;
    padding: 1rem 1.5rem;
  }

  @screen lg {
    @apply text-2xl;
    padding: 2rem 3rem;
  }

  &::before {
    content: "";
    display: inline-block;
    flex-shrink: 0;
    width: 24px;
    height: 24px;
    margin-right: 1rem;
    transition: transform $trns-dur $trns-easing;
    background-image: url("../images/icons/arrow-collapse.svg");
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;

    @screen md {
      margin-right: 1.75rem;
    }
  }

  > div::first-letter {
    text-transform: uppercase;
  }
}

.accordion-item-content {
  @apply text-base;

  width: 100%;
  max-height: 0;
  overflow: hidden;
  transition: max-height $trns-dur $trns-easing, padding $trns-dur $trns-easing;

  @screen md {
    @apply text-lg;
    padding-left: 5rem;
  }

  @screen lg {
    padding-left: 6.5rem;
  }
}
