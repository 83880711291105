.related-news-swiper-nav-btn {
  top: 0;
  margin-top: calc(33.332758620689655% - 12px);

  @screen sm {
    top: 87px; // 50% of the image
    margin-top: -12px;
  }

  @screen md {
    top: 115px;
  }

  @screen lg {
    top: 97px;
  }

  @screen xl {
    top: 120px;
  }

  @screen xxl {
    &:focus {
      @apply shadow-btn-primary;
    }

    svg {
      @apply fill-primary;
    }
  }
}

.related-news-box {
  display: block;

  .date {
    @apply text-primary text-sm;

    display: block;
    margin-top: 1.5rem;
    margin-bottom: .75rem;
  }

  .title {
    @apply text-black-a7;
  }
}
