.article-content.kristallnacht {
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply text-white;
  }
}

.kristallnacht-widget {
  aspect-ratio: 362/371;
  @apply mt-4 col-span-12;

  @media (min-width: 370px) {
    @apply col-span-10 col-start-2;
  }

  @media (min-width: 480px) {
    @apply col-span-8 col-start-3;
  }

  @screen md {
    @apply mt-0 col-span-6 col-start-4;
  }

  @screen lg {
    @apply col-span-4 col-start-9;
  }
}

.widget-button {
  @apply mt-12 h-auto py-2 px-4;
  background-color: #011429;
  @screen sm {
    @apply px-6;
  }
  @screen lg {
    @apply px-4;
  }
  @screen xl {
    @apply px-6;
  }

  &:hover {
    @apply bg-black;
  }
}

.kristallnacht-hero-video {
  .embedded-video {
    @apply rounded;
    @screen md {
      padding-bottom: 65%;
    }
  }
}
