.elevate-content {
  @apply leading-relaxed;

  a {
    text-decoration: underline;
  }

  p {
    margin-bottom: 1.5rem;

    &:first-child {
      margin-top: 0;
    }
  }

  ul,
  ol {
    margin: 1.5rem 0;
    padding-left: 1.75rem;

    li + li {
      margin-top: 0.5rem;
    }
  }

  ul {
    list-style: circle;
  }

  ol {
    list-style: decimal;
  }

  blockquote {
    margin: 2.5rem 0;
    border-left: 1px solid;
    padding-left: 2rem;
    font-style: italic;
  }
}
