@import '../../../../app/javascript/admin/styles/hex/variables';

$color: $yellow;

.deep-link {
  &.highlight {
    position: relative;
    transition: outline, background 200ms;
    outline: 1px dashed $color !important;

    outline-offset: -1px;

    &.normal-offset {
      outline-offset: 0;
    }

    .deep-link-edit-btn {
      display: flex;
    }
  }

  .deep-link-overlay {
    display: block;
    position: absolute;
    z-index: 10;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    opacity: 0;
    background-color: rgba($color: $color, $alpha: 0.1);
    pointer-events: none;
    transition: opacity 200ms;

    // Resets
    padding: 0;
    margin: 0;

    .label {
      color: $black;
      float: right;
      font-size: 1rem;
      padding: 0.25rem 0.5rem;
      border-bottom-left-radius: $border-radius;
      background-color: $color;
      pointer-events: none;

      // Resets
      margin: 0;
    }
  }

  .deep-link-edit-btn {
    display: none;
    position: absolute;
    z-index: 11;
    top: 0;
    left: 0;
    align-items: center;
    padding: 0.5rem;
    color: $black;
    font-size: 0.875rem;
    background-color: $color;
    border-bottom-right-radius: $border-radius-sm;
    transition: transform 200ms;
    transform-origin: top left;
    width: 32px;
    height: 32px;

    &:hover {
      transform: scale(1.2);

      & + .deep-link-overlay {
        opacity: 1;
      }
    }
  }
}
