.dropdown {
  position: relative;

  &.open {
    .dropdown-trigger::after {
      transform: rotate(180deg);
    }
  }

  .trigger {
    width: 100%;
    height: 100%;
  }
}

.dropdown-trigger {
  display: inline-block;
  position: relative;
  width: 100%;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    right: 0;
    width: 0;
    height: 0;
    margin-top: -2.5px;
    transform: rotate(0);
    transform-origin: center;
    transition: all $trns;
    border-top: 5px solid theme("colors.white");
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
  }
}

.dropdown-menu {
  display: block;
  z-index: 1000;

  &[aria-hidden='true'] {
    visibility: hidden;
    transition: opacity $trns, visibility $trns;
    opacity: 0;
  }

  &[aria-hidden='false'] {
    visibility: visible;
    transition: opacity $trns;
    opacity: 1;
  }
}

.dropdown-menu-1 {
  @apply rounded bg-white;

  min-width: 150px;

  .popover-arrow {
    @apply border-white;

    position: absolute;
    z-index: 1;
    width: 0;
    height: 0;
    border-style: solid;
  }

  &[x-placement^="top"] {
    margin-bottom: 7px;

    .tooltip-arrow {
      right: 12px;
      bottom: -7px;
      margin-top: 0;
      margin-bottom: 0;
      border-width: 7px 7px 0;
      border-right-color: transparent;
      border-bottom-color: transparent;
      border-left-color: transparent;
    }
  }

  &[x-placement^="bottom"] {
    margin-top: 7px;

    .tooltip-arrow {
      top: -7px;
      right: 12px;
      margin-top: 0;
      margin-bottom: 0;
      border-width: 0 7px 7px;
      border-top-color: transparent;
      border-right-color: transparent;
      border-left-color: transparent;
    }
  }

  li {
    &.active {
      a {
        @apply bg-blue-a2;
      }
    }

    &:first-child {
      a {
        @apply rounded-t;
      }
    }

    &:last-child {
      a {
        @apply rounded-b;
      }
    }
  }

  a {
    @apply text-primary text-xs;

    display: block;
    padding: .5rem 1.5rem;
    transition: background-color $trns;

    &:hover {
      @apply
        bg-blue-a2;
    }
  }
}

.dropdown-menu-2 {
  @apply rounded-b-sm bg-white;

  top: 100% !important; // always bottom placement
  min-width: 100%;
  max-height: 400px;
  padding: 6px 0;
  overflow-y: auto;
  transform: translate3d(0, 0, 0) !important; // always bottom placement

  .popover-arrow {
    display: none;
  }

  a {
    @apply bg-transparent text-primary;

    display: block;
    padding: .75rem 1.25rem .75rem 1.5rem;
    transition: background-color $trns;

    &.affiliated {
      @apply text-blue;
    }

    &:hover {
      @apply bg-blue-a2;
    }
  }
}

.dropdown-select {
  &.open {
    .dropdown-trigger {
      @apply rounded-b-none border-primary bg-light;
    }
  }

  .dropdown-trigger {
    padding-right: 2rem;
    padding-left: 1rem;

    &:focus {
      @apply bg-light;
    }

    &::after {
      right: 1rem;
      border-width: 7px 7px 0;
      border-color: theme("colors.gray-dark") transparent transparent;
    }
  }
}

.dropdown-select-menu {
  @apply border-primary rounded-b-sm bg-light;

  top: 100% !important; // always bottom placement
  width: 100%;
  transform: translate3d(0, 0, 0) !important; // always bottom placement
  border-width: 0 1px 1px;

  .popover-arrow {
    display: none;
  }

  li {
    &.active {
      a {
        @apply bg-blue-a2 font-medium;
      }
    }

    &:last-child {
      a {
        @apply rounded-b-sm;
      }
    }
  }

  a {
    @apply text-gray-dark;

    display: block;
    padding: .5rem 1.5rem;
    transition: background-color $trns;

    &:hover {
      @apply bg-blue-a2;
    }
  }
}
