.form-group {
  margin-bottom: 1.5rem;

  &.-error,
  &.has-error {
    .form-control {
      @apply border-error;
    }

    .forms-field-multichoice > input {
      outline: 2px solid #ff8080;
    }

    &::after {
      content: attr(data-error);
      color: #ff0000;
      @apply text-sm;
    }
  }
}

.form-label {
  @apply text-primary text-sm font-bold;

  display: inline-block;
  margin-bottom: 0.5rem;
  text-transform: uppercase;

  &.-required,
  &.required {
    &::after {
      @apply text-error;
      content: ' *';
    }
  }
}

.form-control {
  @apply border border-light rounded-sm bg-light text-gray-dark font-medium;

  display: block;
  width: 100%;
  padding: 1rem;
  transition: border-color $trns, background-color $trns;

  &:not(textarea) {
    height: 46px;
    padding-top: 4px; // vertical align
    padding-bottom: 0;
  }

  @screen md {
    &:not(textarea) {
      height: 54px;
    }
  }

  &:focus {
    @apply border-primary bg-white;
    outline: none;
  }

  &::placeholder {
    @apply text-gray;
    opacity: 1;
  }
}

.invalid-feedback,
.error {
  @apply text-error text-sm font-medium;
  margin-top: 0.75rem;
}

.custom-select {
  padding: 4px 2rem 0 1rem;
  background-image: url("data:image/svg+xml,%3Csvg width='14' height='7' viewBox='0 0 14 7' xmlns='http://www.w3.org/2000/svg' fill='%234A4A4A'%3E%3Cpath d='M0 .07h14l-7 7z'/%3E%3C/svg%3E");
  background-position: right 1rem center;
  background-repeat: no-repeat;
  appearance: none;
}

.form-collection-block {
  .custom-control:not(:last-child) {
    margin-bottom: 0.5rem;
  }
}

.custom-control {
  display: block;
  position: relative;
}

.custom-control-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.custom-control label,
.custom-control-label {
  @apply text-gray-dark text-sm font-medium;

  display: inline-block;
  position: relative;
  padding-left: 2rem;
  line-height: 18px;
  cursor: pointer;
  user-select: none;

  &::before,
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: -1px;
    left: 0;
    width: 18px;
    height: 18px;
    transition: opacity $trns, box-shadow $trns;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain;
    pointer-events: none;
    user-select: none;
  }

  &::after {
    z-index: 1;
    opacity: 0;
  }
}

.custom-control.checkbox label,
.custom-control-label-checkbox {
  &::before {
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' fill='%231758A8'%3E%3Cpath d='M16 0a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h14zm0 2H2v14h14V2z'/%3E%3C/svg%3E");
  }

  &::after {
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' fill='%231758A8'%3E%3Cpath d='M16 0a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h14zM7 14l9-9-1.41-1.42L7 11.17 3.41 7.59 2 9l5 5z'/%3E%3C/svg%3E");
  }
}

.custom-control.radio label,
.custom-control-label-radio {
  &::before {
    @apply rounded-full;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' fill='%231758A8'%3E%3Cpath d='M9 0c4.968 0 9 4.032 9 9s-4.032 9-9 9-9-4.032-9-9 4.032-9 9-9zm0 16.2c3.978 0 7.2-3.222 7.2-7.2S12.978 1.8 9 1.8A7.198 7.198 0 0 0 1.8 9c0 3.978 3.222 7.2 7.2 7.2z'/%3E%3C/svg%3E");
  }

  &::after {
    @apply rounded-full;
    background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' fill='%231758A8'%3E%3Cpath d='M9 0c4.968 0 9 4.032 9 9s-4.032 9-9 9-9-4.032-9-9 4.032-9 9-9zm0 4.5c2.484 0 4.5 2.016 4.5 4.5s-2.016 4.5-4.5 4.5A4.502 4.502 0 0 1 4.5 9c0-2.484 2.016-4.5 4.5-4.5zm0 11.7c3.978 0 7.2-3.222 7.2-7.2S12.978 1.8 9 1.8A7.198 7.198 0 0 0 1.8 9c0 3.978 3.222 7.2 7.2 7.2z'/%3E%3C/svg%3E");
  }
}

.custom-control-input:focus ~ label::before,
.custom-control-input:focus ~ label::after,
.custom-control-input:focus ~ .custom-control-label::before,
.custom-control-input:focus ~ .custom-control-label::after {
  @apply shadow-btn;
}

.custom-control-input:checked ~ label::after,
.custom-control-input:checked ~ .custom-control-label::after {
  opacity: 1;
}

.form {
  width: 100%;
  padding: 3rem 0;

  @screen sm {
    max-width: 460px;
    margin: 0 auto;
  }

  .btn[type='submit'] {
    display: block;
    width: 100%;

    @screen sm {
      width: auto;
      margin-left: auto;
    }
  }
}

fieldset {
  &.date {
    .form-label {
      width: 100%;
    }

    .form-control {
      display: inline-block;
      width: auto;
    }
  }
}

.forms-form,
.forms-success-message {
  @apply px-6 py-8;
  @apply rounded;
  @apply my-6;

  background-repeat: no-repeat;
  background-position: center top;
  background-size: 100% auto;
}

.forms-form {
  .forms-field-hint {
    @apply text-primary-a7 text-sm pt-1;
    @apply mb-0;
  }

  .forms-field-description {
    @apply mb-8;

    * {
      @apply text-base;
    }

    p {
      @apply mb-2;
    }

    ul,
    ol {
      @apply mb-6;
    }
  }

  .forms-field-radio,
  .forms-field-multichoice,
  .forms-field-checkbox {
    @apply flex items-center;
    @apply text-gray-dark font-medium;
    @apply mb-3;

    & > input {
      @apply mr-1;
    }
  }

  .forms-field-checkbox.-required {
    &::after {
      display: contents;
      @apply text-error;
      content: ' *';
    }
  }

  input[type='file'] {
    @apply block;
  }

  input[type='submit'] {
    @apply mt-6;
  }
}

.forms-success-message {
  @apply text-primary text-xl font-medium;
}
