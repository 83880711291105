.podcasts-title-container {
  min-height: 190px;

  @screen lg {
    min-height: 290px;
  }
}

.podcasts-grid {
  @screen lg {
    margin-top: 145px;
  }

  .col-span-9 {
    @apply pt-38;

    @screen lg {
      @apply pt-0;
    }
  }

  .col-span-3 {
    @apply pb-5;

    @screen lg {
      @apply pb-0;
    }
  }
}

.podcast-main-img  {
  max-height: 264px;
  max-width: 264px;
}

.podcasts-list {
  h1 {
    font-size: 40px;
  }
}

.podcast-item {
  padding: 24px 80px 40px 24px;
  background-color: #F8F8FA;

  .podcast-image {
    img {
      min-height: 118px;
      min-width: 118px;
    }
  }

  .podcast-text {
    color: #85858A;
  }
  
  .podcast-time {
    color: #919194;
  }
}

.podcast-component,
.player-row {
  background-color: #F8F8FA;
  @screen md {
    max-height: 180px;
  }
}

.podcast-share-item {
  @apply border rounded-lg p-3 mt-3; 

  border-color: #A6A6A6;
}

.podcast-dark-text {
  color: #0D0D0D!important;
}

.podcast-bottom-border {
  @apply border-b pb-1;

  &:hover {
    @apply border-none;
  }

  border-color: #08284F;
  width: fit-content;
}

.podcast-second-dark-text {
  color: #08284F!important;
}

