.ihra-new-page {
  h1 {
    @apply text-2xl text-center;

    @screen md {
      @apply text-3xl;
    }

    @screen lg {
      font-size: 68px;
    }
  }

  h4 {
    @apply text-lg;

    @screen md {
      @apply text-xl;
    }

    @screen lg {
      font-size: 27px;
    }
  }
}
