.holocaust-memorial {
  .holocaust-hero-video {
    .embedded-video {
      @screen md {
        padding-bottom: 69%;
      }
    }
  }

  .entry-video-even,
  .entry-video-odd{
    .embedded-video {
      @screen md {
        padding-bottom: 177%;
      }
    }
  }

  .grid-entry {
    .entry-video-odd {
      @apply hidden;
    }

    .entry-summary {
      @apply col-span-12;

      @screen md {
        @apply col-span-6 col-start-7;
      }

      @screen lg {
        @apply col-span-7 col-start-6;
      }

      @screen xl {
        @apply col-span-7 col-start-6;
      }
    }
  }

  .grid-entry.-reverse-entry {
    .entry-video-even {
      @screen md {
        @apply hidden;
      }
    }

    .entry-video-odd {
      @apply hidden;

      @screen md {
        @apply block;
      }
    }

    .entry-summary {
      @screen md {
        @apply col-span-6 col-start-1;
      }
      @screen lg {
        @apply col-span-7 col-start-1;
      }
    }
  }
}

.story-page-video {
  .embedded-video {
    @screen md {
      padding-bottom: 130%;
    }
  }
}
