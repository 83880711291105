.pretty-content {
  @apply leading-relaxed;

  a {
    text-decoration: underline;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin-bottom: 0.5em;
  }

  p {
    margin-bottom: 1em;

    @screen md {
      margin-bottom: 1.5em;
    }

    @screen lg {
      margin-bottom: 2em;
    }
  }

  ul,
  ol {
    margin: 1.5rem 0;
    padding-left: 1.75rem;

    li + li {
      margin-top: 0.5rem;
    }
  }

  ul {
    list-style: circle;
  }

  ol {
    list-style: decimal;
  }

  blockquote {
    margin: 2.5rem 0;
    border-left: 1px solid;
    padding-left: 2rem;
    font-style: italic;
  }
}

.hyperlink-color {
  a {
    color: #0257D5;
    text-decoration: underline;

    &:hover {
      @apply no-underline;
    }
  }
}
