.on-campus-page {
  .on-campus--resource {
    p {
      @apply text-gray-dark text-lg leading-relaxed mb-4;

      @screen sm {
        @apply text-base;
      }

      @screen md {
        @apply text-lg;
      }
    }
  }
}
