.slider-height {
  @screen md {
    max-height: 440px;
  }

  @screen lg {
    max-height: 540px;
  }
}

.container-height {
  @screen lg {
    max-height:514px;
  }
}
