
































































































.load-more-btn {
  @apply bg-primary text-white text-lg font-600 font-suisse-intl py-3 px-28 mt-12 mb-16 justify-center mx-auto flex;

  @screen lg {
    @apply mb-32;

    width: 352px;
  }
}
