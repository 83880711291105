.fellows {
  @apply -m-4;

  img {
    @apply p-4 w-full;
  }

  @screen sm {
    @apply flex flex-wrap;

    & > div {
      @apply w-1/2;
    }
  }

  @screen md {
    & > div {
      @apply w-1/3;
    }
  }
}

.fellowship-resource {
  background-color: rgba(0, 0, 0, 0.05);
  @apply py-4 px-3;

  @screen md {
    @apply py-6;
  }

  &:hover {
    background-color: rgba(0, 0, 0, 0);
    @apply border border-primary;

    .download-resource-icons {
      span {
        &:first-child {
          transform: scale(0.8);
          opacity: 0;
        }

        &:last-child {
          transform: translateY(0);
          transition-delay: 0.2s;
          opacity: 1;
        }
      }
    }
  }
}

.fellowship-events-carousel {
  .swiper-slide {
    img {
      height: 260px;

      @screen lg {
        height: 400px;
      }
    }
  }
}

.fellowship-events {
  .swiper-button-next, .swiper-button-prev {
    top: 40%;
  }
  .swiper-nav-btn {

    @screen xxl {
      &:focus {
        @apply shadow-btn-primary;
      }

      svg {
        @apply fill-primary;
      }
    }
  }
}
