.ihra {
  .article-content {
    a {
      @apply text-primary;
    }

    blockquote {
      margin: 2.5rem 0;
      border-left: 1px solid;
      padding-left: 2rem;
      font-style: italic;

      &::before {
        display: none;
      }
    }
  }

  .reveal-button {
    @apply text-white underline capitalize text-base font-medium outline-none pt-2;

    @screen sm {
      @apply text-lg pt-4;
    }
  }
}

.ihra-inner-hero {
  min-height: 300px;

  @screen md {
    min-height: 350px;
  }

  @screen lg {
    min-height: 400px;
  }

  @screen xl {
    min-height: 450px;
  }
}

.ihra-next-pages {
  &:hover {
    .btn-link::after {
      height: 4px;
    }
  }

  .page-link:hover {
    .arrow-left {
      animation: slide 1.5s infinite;
    }

    @keyframes slide {
      0%,
      100% {
        transform: translate(0, 0);
      }
      50% {
        transform: translate(-10px, 0);
      }
    }

    .arrow-right {
      animation: slide2 1.5s infinite;
    }

    @keyframes slide2 {
      0%,
      100% {
        transform: translate(0, 0);
      }
      50% {
        transform: translate(10px, 0);
      }
    }
  }
}
