.videos-swiper-nav-btn {
  top: 0;
  margin-top: calc(32.442748091603053% - 12px);

  @screen sm {
    top: 85px; // this is equal to 50% of the image
    margin-top: -12px;
  }

  @screen md {
    top: 111px; // this is equal to 50% of the image
  }

  @screen lg {
    top: 95px; // this is equal to 50% of the image
  }

  @screen xl {
    top: 85px; // this is equal to 50% of the image
  }

  @screen xxl {
    &:focus {
      @apply shadow-btn-primary;
    }

    svg {
      @apply fill-primary;
    }
  }
}

.video-box {
  display: block;

  &:hover {
    .play-btn {
      @apply bg-white;

      &::after {
        border-left-color: theme("colors.primary");
      }
    }
  }

  .play-btn {
    position: absolute;
    bottom: .75rem;
    left: .75rem;
  }

  .title {
    @apply text-primary;
    margin-top: 1rem;
    margin-bottom: 0.5rem;
  }

  .date {
    @apply text-gray text-base;

    display: block;
  }
}

.play-btn {
  @apply border-2 border-white rounded bg-black-a2;

  width: 36px;
  height: 36px;
  transition: border-color $trns, background-color $trns;

  &::after {
    border-color: transparent transparent transparent theme("colors.white");
  }
}

.list-video-hero {
  @apply py-8 bg-primary text-white;

  position: relative;

  @screen md {
    min-height: 520px;
    padding-top: 10rem;
    padding-bottom: 5rem;
  }
}

.list-video-hero-img {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;

  @screen lg {
    width: calc(50% + 145px);
  }

  @screen xl {
    width: calc(50% + 277px);
  }

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.list-video-hero-gradient-wrapper {
  position: absolute;
  z-index: 1;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;

  .list-video-hero-gradient {
    height: 100%;
    background: linear-gradient(270deg, theme("colors.primary-a2") 0%, theme("colors.primary") 100%);

    @screen lg {
      background: linear-gradient(270deg, transparent 0%, theme("colors.primary") 100%);
    }
  }
}

.video-categories {
  a {
    @apply text-primary text-base;

    display: inline-flex;
    position: relative;
    align-items: center;
    width: 100%;
    height: 3rem;
    padding: 0 2rem 0 .75rem;
    transition: opacity $trns;
    opacity: .7;
    text-transform: uppercase;

    &:focus,
    &:hover {
      opacity: 1;
      text-decoration: none;
    }

    &.active {
      @apply font-bold;

      padding-left: 1.75rem;
      opacity: 1;

      &::before,
      &::after {
        display: block;
      }
    }

    &::before {
      content: "";
      @apply bg-blue;

      display: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      width: .25rem;
    }

    &::after {
      content: "";
      display: none;
      position: absolute;
      top: 50%;
      right: .75rem;
      width: 1.25rem;
      height: 1.25rem;
      margin-top: -.625rem;
      background-image: url("../images/icons/arrow-category.svg");
      background-repeat: no-repeat;
      background-position: center center;
    }
  }
}

.video-search-form {
  position: relative;
  margin-bottom: 2rem;

  @screen md {
    margin-bottom: 0;
  }

  svg {
    position: absolute;
    top: 50%;
    left: 1rem;
    margin-top: -.75rem;
  }

  input {
    padding-left: 3.5rem;

    &:focus {
      @apply bg-light;
    }
  }
}

.video-player {
  height: 240px;
  overflow: hidden;
  border-radius: 0 !important;
  vertical-align: top;

  @screen sm {
    height: 310px;
  }

  @screen md {
    height: 400px;
  }

  @screen lg {
    height: 520px;
  }
}

.video-iframe {
  position: relative;
  overflow: hidden;

  &::before {
    content: "";
    display: block;
    width: 100%;
    padding-top: 56.25%;
  }

  iframe {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
}
