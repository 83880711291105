.logo-size {
  min-width: 263px;
  height: 111px;
  @apply object-cover;
}

.title-font-weight {
  font-weight: 600;
}

.section-title {
  @apply text-xl;

  @screen sm {
    @apply text-2xl;
  }

  @screen lg {
    font-size: 40px;
    line-height: 52px;
  }
}

.about-content-font-weight {
  font-weight: 450;
  font-family: 'Suisse Works';
}

.map-image-size {
  width: 558px;
  height: 380px;
  @apply object-cover;
}

.about-section-size {
  height: 360px;
  @apply w-full;
  @apply object-cover;
}

.video-size-ihra-campaign {
  .embedded-video {
    @apply min-w-full;
    min-height: 288px;
  }
}

.video-size-ihra-campaign.ihra-campaign {
  @apply w-auto mx-7;
  @apply mb-8;

  align-items: flex-end;
  shape-outside: inset(calc(100% - 390px) 0 0);

  @screen lg {
    @apply float-right;
    @apply mb-0;
    margin-right: 90px;
    width: 55%;
  }
}
