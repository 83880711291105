.ab80-bg-size {
  height: 700px;
  @screen sm {
    height: 1000px;
  }
}

.ab80-header {
  margin-top: -700px;
  @screen sm {
    margin-top: -1000px;
  }
}

.ab-80-swiper-left-arrow {
  left: -60px;
}

.ab-80-swiper-right-arrow {
  right: -60px;
}
