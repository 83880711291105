.triangle {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;

  &::after {
    content: "";
    display: block;
    width: 0;
    height: 0;
    transition: border-color $trns;
    border-width: 8px 0 8px 13.9px;
    border-color: transparent transparent transparent theme("colors.primary");
  }
}
