

















































































::v-deep {
  .multiselect__tags {
    border: 2px solid #e0e8ee;
    height: 56px;
    border-radius: 0 !important;
    padding: 15px 40px 0 32px;
    @apply font-suisse-intl text-base text-blue-002B48 text-base text-opacity-100;
  }

  .multiselect__select {
    height: 54px;
  }

  .multiselect__option--highlight,
  .multiselect__option--highlight:after {
    background: #002b48;
    content: '';
  }

  .multiselect__option--highlight .option__text {
    color: #fff !important;
  }

  .multiselect__content {
    width: 100%;
    max-height: 300px;
    overflow-y: scroll;
  }

  .multiselect__element::marker {
    display: none;
  }

  .multiselect__element {
    @apply font-suisse-intl text-base text-blue-002B48 text-base;
  }

  .multiselect::before {
    content: url('../../../images/icons/search-before.svg');
    position: absolute;
    top: 17px;
    left: 7px;
  }
}
