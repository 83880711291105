.bosnia-delegation {
  .bosnia-delegation-carousel {
    .swiper-wrapper {
      max-height: 424px;
    }

    .swiper-slide {
      img {
        min-height: 260px;

        @screen lg {
          min-height: 424px;
        }
      }
    }
  }

  .bosnia-delegation-history {
    .grid-entry {
      .entry-video-odd {
        @apply hidden;
      }

      .entry-summary {
        @apply col-span-12;

        @screen md {
          @apply col-span-6 col-start-7;
        }

        @screen lg {
          @apply col-span-7 col-start-7;
        }

        @screen xl {
          @apply col-span-6 col-start-6;
        }
      }
    }

    .grid-entry.-reverse-entry {
      .entry-video-even {
        @screen md {
          @apply hidden;
        }
      }

      .entry-video-odd {
        @apply hidden;

        @screen md {
          @apply block;
        }
      }

      .entry-summary {
        @screen md {
          @apply col-span-6 col-start-1;
        }

        @screen lg {
          @apply col-span-6 col-start-1;
        }

        @screen xl {
          @apply col-span-6 col-start-2;
        }
      }
    }
  }
}