.theodor-herzl-award-hero {
  background-image: url('../images/theodor_herzl_award/logo-bg-white.svg');
  background-repeat: no-repeat;
  background-position: -444px 222px;
}

.theodor-herzl-award-recipients {
  position: relative;

  &::before {
    content: ' ';
    position: absolute;
    top: 222px;
    right: -444px;
    width: 888px;
    height: 1483px;
    background-image: url('../images/theodor_herzl_award/logo-bg-gold.svg');
    background-repeat: no-repeat;
    z-index: 0;
  }
}

.theodor-herzl-award-cta-box {
  background: linear-gradient(135deg, #213d61, #213d61 50%, #29476e 50%, #29476e);

  @screen md {
    background: linear-gradient(135deg, #213d61, #213d61 50%, #29476e 50%, #29476e);
  }

  @screen lg {
    background: linear-gradient(139.5deg, #213d61, #213d61 50%, #29476e 50%, #29476e);
  }

  @screen xl {
    background: linear-gradient(145.5deg, #213d61, #213d61 50%, #29476e 50%, #29476e)
  }
}
