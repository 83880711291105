.cookie-notice-wrapper {
  @apply bg-black text-white;

  display: flex;
  position: fixed;
  z-index: 9999;
  bottom: 0;
  left: 0;
  flex-direction: column;
  width: 100%;
  max-height: 100%;
  overflow: auto;

  @screen md {
    background-color: transparent;
  }
}

.cookie-notice {
  padding-bottom: 1rem;

  @screen md {
    @apply bg-black;

    order: 1;
    padding-bottom: 0;
  }

  .cookie-notice-text {
    @apply text-sm font-medium;

    margin-bottom: 1rem;
    padding-top: 1rem;

    p {
      display: inline;
    }

    @screen md {
      @apply py-5 text-xs;

      margin-bottom: 0;
    }
  }

  a {
    @apply text-white underline;
  }

  .btn {
    min-width: 165px;
  }
}

.cookie-notice-settings {
  padding: 1rem 0;

  @screen sm {
    display: flex;
  }

  &:not(:last-child) {
    border-bottom: 1px solid theme("colors.white-a3");
  }

  > div {
    &:first-child {
      @apply flex-shrink-0;

      width: 165px;
      margin-right: 30px;
      margin-bottom: .75rem;

      @screen sm {
        margin-bottom: 0;
      }
    }

    &:last-child {
      @apply text-xs;
      opacity: .8;
    }
  }

  .custom-control-label-checkbox {
    &::before {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E%3Cpath d='M16 0a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h14zm0 2H2v14h14V2z'/%3E%3C/svg%3E");
    }

    &::after {
      background-image: url("data:image/svg+xml,%3Csvg width='18' height='18' viewBox='0 0 18 18' xmlns='http://www.w3.org/2000/svg' fill='%23fff'%3E%3Cpath d='M16 0a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h14zM7 14l9-9-1.41-1.42L7 11.17 3.41 7.59 2 9l5 5z'/%3E%3C/svg%3E");
    }
  }

  .custom-control-label {
    @apply text-white font-bold;
  }
}
