.header-social-nav {
  display: flex;
  justify-content: center;
  margin-right: 1rem;
  padding: 0 1.5rem;
  border-right: 1px solid theme("colors.white");
  border-left: 1px solid theme("colors.white");

  @screen sm {
    margin-right: 1.5rem;
  }

  a {
    display: block;
    flex-shrink: 0;
    width: 16px;
    height: 16px;
    transition: opacity $trns;
    opacity: 1;
    font-size: 0;
    line-height: 1;

    &:not(:first-child) {
      margin-left: 0.7rem;

      @screen sm {
        margin-left: 1.5rem;
      }
    }

    &:hover {
      @apply opacity-icon;
    }

    img {
      width: 100%;
    }
  }
}
