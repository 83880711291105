.jal-video {
  @screen sm {
    @apply grid grid-cols-2 gap-8;

    &.-reverse {
      .jal-video-video {
        @apply col-start-2 row-start-1;
      }

      .jal-video-description {
        @apply col-start-1 row-start-1;
      }
    }
  }

  & + .jal-video {
    @apply mt-16;
  }

  .jal-video-description {
    h3 {
      @apply text-lg leading-casual text-primary font-medium;
      @apply mb-4;

      @screen sm {
        @apply text-xl;
      }

      @screen lg {
        @apply text-2xl;
      }
    }

    .pretty-content {
      @apply text-base leading-relaxed text-gray-dark;

      @screen lg {
        @apply text-lg;
      }
    }
  }
}

.jal-video-video {
  @apply mb-4;

  @screen sm {
    @apply mb-0;
  }

  & > img {
    @apply w-full h-full;
    object-fit: cover;
  }
}

.jal-hero-gradient {
  @apply absolute top-0 left-0 right-0;
  background: linear-gradient(180deg, #09284f 0%, rgba(7, 30, 59, 0) 100%);
  height: 25rem;
}

.jal-section {
  & > .container {
    @apply pt-8 pb-16;

    @screen sm {
      @apply pt-12 pb-20;
    }

    @screen lg {
      @apply pt-16 pb-24;
    }
  }

  h2 {
    @apply text-xl font-medium text-primary;
    @apply mb-12;

    @screen sm {
      @apply text-2xl;
    }

    @screen lg {
      @apply text-3xl;
    }
  }
}

.jal2-section1 {
  .pretty-content {
    @apply text-lg;
  }
}
