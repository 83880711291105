ul.documents {
  @apply border-t border-gray-light text-base;
  @apply pl-0;

  @screen md {
    @apply text-lg;
  }

  li {
    display: flex;
    align-items: center;
    padding: .5rem 0 .5rem 2rem;
    border-bottom: 1px solid theme("colors.gray-light");
    background: url("../images/icons/document.svg") no-repeat;
    background-position: left center;

    @screen md {
      padding: 1rem 1.5rem 1rem 4rem;
      background-position: 1rem center;
    }
  }

  div {
    flex: 1;
    margin-right: 1rem;
  }

  a {
    flex-shrink: 0;
  }
}
