.unhrc-hero {
  min-height: 600px;

  @media screen and (max-width: 400px) {
    min-height: 750px;
  }

  @screen sm {
    min-height: 500px;
  }

  @screen lg {
    max-height: 600px;
  }

  @screen xl {
    max-height: 700px;
  }
}

.session-btn {
  @apply text-left py-4 pr-3 pl-5;

  h1 {
    @apply font-bold font-primary text-base text-white;
  }

  .session-date {
    @apply font-primary font-medium text-xs; 
  }

  svg {
    @apply hidden; 
  }

  &.active {
    @apply border-l-4 border-white bg-left;
    @apply bg-white bg-opacity-20;

    svg {
      @apply block ml-auto;
    }
  }
}