.featured-issue-wrapper {
  background: linear-gradient(
    to bottom,
    theme('colors.primary') 0%,
    theme('colors.primary') 50%,
    transparent 50%,
    transparent 100%
  );
}

.issue-hero-img-bg {
  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 18.4210526%;
  }

  &::after {
    @apply bg-primary;
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.issue-hero-img-space-hack {
  &::before {
    content: '';
    display: block;
    width: 100%;
    padding-top: 18.4210526%;
  }
}

.featured-issue {
  @apply text-white;

  display: flex;
  align-items: center;
  padding: 2rem;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  text-align: center;

  @screen sm {
    min-height: 320px;
  }

  @screen lg {
    min-height: 420px;
  }

  > div {
    max-width: 594px;
    margin: 0 auto;
  }
}

.list-issue-item {
  .txt-holder {
    @screen md {
      padding-top: 0;
    }
  }

  .summary {
    @apply leading-relaxed;
  }
}

.issue-key-facts {
  ul,
  ol {
    counter-reset: fact;
  }

  li {
    display: flex;
    margin-bottom: 1rem;
    @apply text-sm font-medium;

    @screen md {
      @apply text-base;
    }

    @screen xl {
      @apply text-lg;
    }

    @screen lg {
      margin-bottom: 2rem;
    }

    &::before {
      @apply border border-white rounded-full text-sm font-medium;

      content: counter(fact);
      display: flex;
      flex-shrink: 0;
      align-items: center;
      align-self: flex-start;
      justify-content: center;
      width: 1.5rem;
      height: 1.5rem;
      margin-right: 0.5rem;
      padding-left: 1px; // horizontal center hack
      counter-increment: fact;

      @screen xl {
        margin-right: 1rem;
      }
    }
  }
}

.issue-about-section {
  h1,
  h2,
  h3,
  h4 {
    @apply text-primary font-medium capitalize;
  }

  .block-button {
    justify-content: flex-start;
    align-items: flex-start;

    .btn-outline-primary {
      color: #08284f !important;

      &:hover {
        color: white !important;
      }
    }
  }
}

.focus-area-item {
  aspect-ratio: 1.5 / 1;

  &:hover {
    .focus-area-item--read-more {
      max-height: 2rem;
      margin-top: 1rem;
    }

    .focus-area-item--gradient {
      opacity: 1;
    }
  }
}

.focus-area-item--read-more {
  max-height: 0;
  overflow: hidden;
  transition: margin 140ms, max-height 140ms;
}

.focus-area-item--gradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, #08284f 100%);
  position: absolute;
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: opacity 140ms;
}

.focus-area-hover-state {
  &:hover {
    @apply font-bold;
  }
}
